export const ADMIN_AUTHENTICATE_USER = "ADMIN_AUTHENTICATE_USER";
export const ADMIN_AUTHENTICATE_USER_SUCCESS = "ADMIN_AUTHENTICATE_USER_SUCCESS";
export const AUTHENTICATE_USER_VIA_TOKEN_SUCCESS = "AUTHENTICATE_USER_VIA_TOKEN_SUCCESS";
export const LOGIN_ADMIN_USER = "LOGIN_ADMIN_USER";
export const LOGIN_ADMIN_USER_SUCCESS = "LOGIN_ADMIN_USER_SUCCESS";

export const ADMIN_LOGOUT_USER = "ADMIN_LOGOUT_USER";
export const ADMIN_LOGOUT_USER_SUCCESS = "ADMIN_LOGOUT_USER_SUCCESS";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

export const RESEND_MAIL = "RESEND_MAIL";
export const RESEND_MAIL_SUCCESS = "RESEND_MAIL_SUCCESS";

export const SET_PASSWORD = "SET_PASSWORD";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const CLEAR_TOKEN = "CLEAR_TOKEN";

export const START_LOADER = 'START_LOADER';
export const END_LOADER = 'END_LOADER'

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";

export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const SHOW_GYDE = "SHOW_GYDE";
export const PERMANENT_SHOW_GYDE_OFF = "PERMANENT_SHOW_GYDE_OFF";
export const CONNECT_ADMIN_SOCKET = "CONNECT_ADMIN_SOCKET";
export const SOCKET_ADMIN_CONNECTION_SUCCESS =
  "SOCKET_ADMIN_CONNECTION_SUCCESS";
export const SOCKET_ADMIN_CONNECTION_FAILURE =
  "SOCKET_ADMIN_CONNECTION_FAILURE";

export const DISCONNECT_ADMIN_SOCKET = "DISCONNECT_ADMIN_SOCKET";

export const CHECK_DOMAIN_VERIFIED = "CHECK_DOMAIN_VERIFIED";
export const CHECK_DOMAIN_VERIFIED_SUCCESS = "CHECK_DOMAIN_VERIFIED_SUCCESS";

export const GET_REDIRECT = "GET_REDIRECT";
export const GET_REDIRECT_SUCCESS = "GET_REDIRECT_SUCCESS";
export const UPDATE_TOUR_FLAG = "UPDATE_TOUR_FLAG";
export const UPDATE_TOUR_FLAG_SUCCESS = "UPDATE_TOUR_FLAG_SUCCESS";

export const ON_REFRESH = "ON_REFRESH";
export const AUTHENTICATE_USER_VIA_TOKEN ="AUTHENTICATE_USER_VIA_TOKEN";
