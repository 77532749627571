import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import Toolbar from "@material-ui/core/Toolbar";
import { CustomAvatar, CustomFullScreenModal, CustomSelect } from "@zimyo/components";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { IoMdExit } from "react-icons/io";
import { RiProfileLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAxiosForuseFetch } from "../../Api/axiosPrivate";
import useConfigurableMutation from "../../hooks/useMutation";
import EmployeeProfile from "../../modules/MyTeam/EmployeeProfile";
import {
  ADMIN_AUTHENTICATE_USER_SUCCESS,
  ADMIN_LOGOUT_USER_SUCCESS,
  CLEAR_TOKEN,
} from "../../redux/auth/constant";
import { UPDATE_ADMIN_USER } from "../../redux/local_state_changes/constant";
import {
  ERROR_MESSAGE_FROM_SERVER,
  SUCCESS_MESSAGE_FROM_SERVER,
} from "../../redux/message/constant";
import {
  authenticateUser,
  childrenTabData,
  storeParentTabData,
} from "../../redux/rootAction";

import { HiMiniLanguage } from "react-icons/hi2";

import { handleErrorInImage } from "../../utitlies/HelpersFunction";
import { allowWithoutMenuURL, randomColor, treeToArray } from "../utitlies/commonFunction";
import PrimaryHeader from "./PrimaryHeader";
import Searchbar from "./searchbar";
import { setLocality } from "../../redux/language/action";
import { MenuItem, Select } from "@material-ui/core";

import { useIntl } from 'react-intl';

const Header = (props) => {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    token,
    user = {},
    menu_access_tree,
  } = useSelector((state) => state.adminUserReducer, isEqual);

  const { locale } = useSelector((state) => state.languageReducer);

  const intl = useIntl();

  const [isFullscreeModalOpen, setIsFullscreeModalOpen] = useState(false);
  const [openPopUp, setopenPopUp] = useState(false);
  const [anchorEl, setanchorEl] = useState(null);

  const logoutUserFunc = useConfigurableMutation(
    getAxiosForuseFetch,
    "logoutUser",
    {
      onSuccess: (response) => {
        dispatch({
          type: SUCCESS_MESSAGE_FROM_SERVER,
          payload: response?.data?.message ?? "Success",
        });

        dispatch({
          type: CLEAR_TOKEN,
        });
        dispatch({
          type: ADMIN_LOGOUT_USER_SUCCESS,
        });
        dispatch({
          type: UPDATE_ADMIN_USER,
          payload: { loadingPage: false, authencatedUser: false },
        });
        if (typeof history === "function") {
          history("/");
        }
      },
      onError: ({ response }) => {
        dispatch({
          type: ERROR_MESSAGE_FROM_SERVER,
          payload: response?.data?.message ?? "Something Went Wrong",
        });
      },
    }
  );

  const logout = () => {
    if (token) {
      logoutUserFunc.mutate({ token, url: "admin/user/logout", data: {} });
    }
  };

  useEffect(() => {
    let paths = location?.pathname?.split("/");
    let parentTab = [],
      childrenTab = [];
    let rootPath = paths[1];
    let SelectedItem = menu_access_tree?.find((item) => item.url === rootPath);
    parentTab =
      SelectedItem?.children !== null && SelectedItem?.children.length >= 1
        ? SelectedItem?.children
        : [];
    let nestedPath = `${paths[1]}/${paths[2]}`;
    let parentSelectedObj = parentTab?.find((item) => item.url === nestedPath);
    childrenTab =
      parentSelectedObj?.children !== null &&
        parentSelectedObj?.children.length > 0
        ? parentSelectedObj?.children
        : [];
    if (!SelectedItem && paths.length > 2) {
      SelectedItem = menu_access_tree?.find((item) => item.url === nestedPath);
      if (SelectedItem) {
        parentTab = [SelectedItem];
        childrenTab = [SelectedItem];
      }
    }
    dispatch(storeParentTabData(parentTab));
    if (allowWithoutMenuURL(location?.pathname)) {
    } else {
      dispatch(childrenTabData(childrenTab));
    }

    if (!localStorage.getItem("adminMenu")) {
      logout();
    }
  }, [dispatch, token, location]);

  useEffect(() => {
    const authenticate = () => {
      dispatch(
        authenticateUser({
          headers: { token: localStorage.getItem("token") },
        })
      );
    };

    // Call the authentication function immediately
    authenticate();

    // Set up a timer to call the authentication function periodically (e.g., every 30 minutes)
    const authenticationInterval = setInterval(authenticate, 30 * 60 * 1000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(authenticationInterval);
    };
  }, [dispatch]);


  const onChangeLocality = (value) => {
    localStorage.setItem("locale", value)
    dispatch(setLocality(value));
  }

  return (
    <>
      <Toolbar
        className="material-toolbar"
        style={{ height: "45px", width: "100%" }}
      >
        <Box
          onClick={() => {
            history(
              `/${menu_access_tree[0]?.children?.[0]?.url ||
              menu_access_tree[0]?.url
              }`
            );
          }}
          className="flex-start-center cursor-pointer logo-box"
          sx={{
            ml: "1vw",
          }}
        >
          <img
            alt="Company Logo"
            onError={handleErrorInImage}
            style={{ height: "30px", width: "auto", maxWidth: "160px" }}
            src={user.org_logo ? user.org_logo : "/img/svg/Logo.svg"}
          />
        </Box>
        <Box className="w-100 flex-end-center">

          <Searchbar placeholder={intl.formatMessage({ id: "header.beacon.searchPlaceholder", defaultMessage: "Click here for smart search..." })} />

          <IconButton
            size="small"
            onClick={(event) => {
              setanchorEl(event.currentTarget);
              setopenPopUp(!openPopUp);
            }}
            sx={{ mr: 0.8, ml: 4 }}
          >
            <CustomAvatar
              alt={user?.first_name}
              sx={{
                width: 34,
                height: 34,

                backgroundColor: randomColor(
                  `${user?.emp_name}`.slice(0, 1) || 0
                ),
              }}
              src={user.user_img}
            >
              {`${user.emp_name}`.toLocaleUpperCase().slice(0, 1)}
            </CustomAvatar>
          </IconButton>
        </Box>
      </Toolbar>
      {/* {parentTabsData.length  ? (
        <PrimaryHeader />
      ) : null} */}

      <PrimaryHeader />

      {
        <CustomFullScreenModal
          open={isFullscreeModalOpen}
          handleClose={() => {
            setIsFullscreeModalOpen(false);
          }}
          title={
            <div style={{ fontWeight: 600, fontSize: "18px" }}>My Profile</div>
          }
        >
          <EmployeeProfile
            hide_tabs={["overview", "timeline"]}
            isPeople={true}
            employee_id={user?.["employee_id"]}
            handleClose={() => {
              setIsFullscreeModalOpen(false);
            }}
          />
        </CustomFullScreenModal>
      }

      <Popover
        id={"openModal"}
        open={openPopUp}
        anchorEl={anchorEl}
        onClose={() => setopenPopUp(!openPopUp)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ mt: 0 }}
      >
        <List className="list-popover">
          <ListItem
            className="list-item flex-start-center"
            onClick={() => {
              setopenPopUp(false);
              setIsFullscreeModalOpen(true);
            }}
          >

            <ListItemIcon ListItemIcon className="list-item-icon">
              <RiProfileLine
                style={{
                  height: "1.25rem",
                  width: "1.25rem",
                }}
              />
            </ListItemIcon>

            <ListItemText
              primaryTypographyProps={{
                style: {
                  fontWeight: 500,
                  color: "inherit",
                },
              }}
              primary={intl.formatMessage({ id: "header.popOver.profile", defaultMessage: "My Profile" })}
            />
          </ListItem>

          <ListItem>

            <ListItemIcon ListItemIcon className="list-item-icon locality-icon">
              <HiMiniLanguage
                style={{
                  height: "1.25rem",
                  width: "1.25rem",
                  color: '#172B4D'
                }}
              />
            </ListItemIcon>


            <Select
              value={locale}
              onChange={(e) => onChangeLocality(e.target.value)}
              style={{ width: "100%" }}
              size="small"
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="hi">हिंदी</MenuItem>
              <MenuItem value="ar">Arabic</MenuItem>
              <MenuItem value="fr">French</MenuItem>
            </Select>
          </ListItem>

          <ListItem
            className="list-item flex-start-center"
            onClick={() => logout()}
          >

            <ListItemIcon className="list-item-icon">
              <IoMdExit
                style={{
                  height: "1.25rem",
                  width: "1.25rem",
                }}
              />
            </ListItemIcon>

            <ListItemText
              primaryTypographyProps={{
                style: {
                  fontWeight: 500,
                  color: "inherit",
                },
              }}
              primary={intl.formatMessage({ id: "header.popOver.logout", defaultMessage: "Logout" })}
            />
          </ListItem>


        </List>
      </Popover >
    </>
  );
};
export default Header;
