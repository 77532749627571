import { clearCookies } from "../../utils/Utils";
import {
  LOGIN_ADMIN_USER_SUCCESS,
  ADMIN_AUTHENTICATE_USER_SUCCESS,
  ADMIN_LOGOUT_USER_SUCCESS,
  CLEAR_TOKEN,
  GET_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS,
  SHOW_GYDE,
  PERMANENT_SHOW_GYDE_OFF,
  SOCKET_ADMIN_CONNECTION_SUCCESS,
  SOCKET_ADMIN_CONNECTION_FAILURE,
  DISCONNECT_ADMIN_SOCKET,
  CHECK_DOMAIN_VERIFIED_SUCCESS,
  GET_REDIRECT_SUCCESS,
  UPDATE_TOUR_FLAG_SUCCESS,
  START_LOADER,
  END_LOADER
} from "./constant";

const initialValue = {
  token: "",
  user: {},
  menu_access: [],
  showGuide: false,
  permanentDisableGyde: false,
  domainVerified: true,
  isTourFlagUpdated: false,
  menu_access_tree: [],
  show_otp_input : false,
  loader_parent : false, 
};

const reducer = (state = initialValue, { type, payload }) => {
  switch (type) {
    case GET_REDIRECT_SUCCESS:
      return { ...state, token: payload.token, user: payload.data.data };
    case LOGIN_ADMIN_USER_SUCCESS:
      return {
        ...state,
        token: payload?.token,
        user: payload?.auth,
        show_otp_input : payload?.show_otp_input,
      };
    case UPDATE_TOUR_FLAG_SUCCESS:
      return { ...state, isTourFlagUpdated: payload };
    case SHOW_GYDE:
      return { ...state, showGuide: payload };
    case PERMANENT_SHOW_GYDE_OFF:
      return { ...state, permanentDisableGyde: payload };
    case UPDATE_USER_DETAILS:
      return { ...state, user: { ...payload } };
    case CLEAR_TOKEN:
      return {
        ...state,
        token: "",
        user: {},
        menu_access: [],
      };
    case CHECK_DOMAIN_VERIFIED_SUCCESS:
    case ADMIN_AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case SOCKET_ADMIN_CONNECTION_SUCCESS: {
      if (!(state?.socket && state?.socket?.connected) === true) {
        if (payload) {
          return {
            ...state,
            socket: payload,
          };
        }
        return {
          ...state,
        };
      }
      return {
        ...state,
      };
    }
    case SOCKET_ADMIN_CONNECTION_FAILURE: {
      return {
        ...state,
      };
    }
    case DISCONNECT_ADMIN_SOCKET: {
      return {
        ...state,
        socket: null,
      };
    }
    case GET_USER_DETAILS_SUCCESS:
      return { ...state, user: { ...payload } };
    case ADMIN_LOGOUT_USER_SUCCESS:
      clearCookies()
      return {
        // ...state,
        token: "",
        user: {},
        menu_access: [],
        showGuide: false,
        permanentDisableGyde: false,
        domainVerified: true,
      };
    case START_LOADER:
      return {
        ...state,
        loader_parent : true,
      } 
    case END_LOADER:
      return {
      ...state,
      loader_parent : false,
    } 
    default:
      return { ...state };
  }
};
export default reducer;
