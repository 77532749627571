import React from "react";
import { Tooltip } from "@material-ui/core";

function TextReducingTooltipComponent({
  data = "",
  limit = 17,
  doNotShowTooltip = false,
  arrow = true,
  endAdornment = "",
  startAdornment = "",
  styleOverrides  = {}
}) {
  return (
    <>
      {data &&
      typeof data === "string" &&
      data.length > limit &&
      !doNotShowTooltip ? (
        <Tooltip title={data ?? ""} arrow={arrow}>
          <span style={styleOverrides} >
            {startAdornment + data.substring(0, limit) + ".." + endAdornment}
          </span>
        </Tooltip>
      ) : (
        startAdornment + data + endAdornment
      )}
    </>
  );
}

export default TextReducingTooltipComponent;
