export const GET_ORG_UNITS_LISTS = "GET_ORG_UNITS_LISTS",
  GET_ORG_UNITS_LISTS_SUCCESS = "GET_ORG_UNITS_LISTS_SUCCESS",
  ORG_UNITS_PAGE_NUMBER = "ORG_UNITS_PAGE_NUMBER",
  CREATE_ORG_UNITS = "CREATE_ORG_UNITS",
  CREATE_ORG_UNITS_SUCCESS = "CREATE_ORG_UNITS_SUCCESS",
  ORG_UNITS_RESET_SAVE_FROM_DATA_SUCCESS =
    "ORG_UNITS_RESET_SAVE_FROM_DATA_SUCCESS";

export const UPDATE_SHOW_IN_MENU = "UPDATE_SHOW_IN_MENU";

export const GET_ORG_MENU = "GET_ORG_MENU";
export const GET_ORG_MENU_SUCCESS = "GET_ORG_MENU_SUCCESS";

export const GET_MENU_IN_LIST = "GET_MENU_IN_LIST";
export const GET_MENU_IN_LIST_SUCCESS = "GET_MENU_IN_LIST_SUCCESS";

export const DOWNLOAD_BULK_CREATE_MASTER_TYPE =
  "DOWNLOAD_BULK_CREATE_MASTER_TYPE";

export const UPLOAD_BULK_CREATE_MASTER_TYPE = "UPLOAD_BULK_CREATE_MASTER_TYPE";
export const UPLOAD_BULK_CREATE_MASTER_TYPE_SUCCESS =
  "UPLOAD_BULK_CREATE_MASTER_TYPE_SUCCESS";

export const DOWNLOAD_BULK_GEOFENCING = "DOWNLOAD_BULK_GEOFENCING";
export const DOWNLOAD_BULK_GEOFENCING_SUCCESS =
  "DOWNLOAD_BULK_GEOFENCING_SUCCESS";

export const UPLOAD_BULK_GEOFENCING = "UPLOAD_BULK_GEOFENCING";
export const UPLOAD_BULK_GEOFENCING_SUCCESS = "UPLOAD_BULK_GEOFENCING_SUCCESS";

export const UPLOAD_SECTION_INFO = "UPLOAD_SECTION_INFO";
export const UPLOAD_SECTION_INFO_SUCCESS = "UPLOAD_SECTION_INFO_SUCCESS";
export const GET_ORGANISATION_STRUCTURE = "GET_ORGANISATION_STRUCTURE";
export const GET_ORGANISATION_STRUCTURE_SUCCESS =
  "GET_ORGANISATION_STRUCTURE_SUCCESS";
