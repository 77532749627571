import { useRef, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import format from "date-fns/format";

var weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
var weekfullday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
var day_suffix = {
  1: "st",
  2: "nd",
  3: "rd",
  21: "st",
  22: "nd",
  23: "rd",
  31: "st",
};



var months = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};
var months_full = {
  1: "January",
  2: "Febuary",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const date_formates = {
  "d-M-Y": "dd-M-yyyy",
  "Y-m-d": "y-MM-dd",
  "m-d-Y": "MM-dd-y",
  "Y-d-m": "y-dd-MM",
  "Y-m-d": "Y-m-d",
  "m-d-Y": "m-d-Y",
  "d M y": "dd M yy",
  "F dS Y": "F dS Y",
  "F d Y": "F d Y",
  "M d Y": "M d Y",
  "d/m/y": "d/m/y",
  "d M Y h:i:s": "d M Y h:i:s",
  "d-M-Y": "d-M-Y",
  "d-M-y": "d M y",
  "Y-d-m": "Y-d-m",
  "h:i A": "h:i A",
};

const date_formats_list = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const getOrdinalSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const getEssAndAdminUrls = () => {
  let userData = {};
  try {
    userData = JSON.parse(localStorage.getItem("user"))
  } catch (err) {

  }
  let essUrl = window.location.origin;
  let adminUrl = userData?.login_domain || process.env.REACT_APP_HRMS_BASE_URL;
  let accountLoginUrl = process.env.REACT_APP_ACCOUNT_LOGIN_BASE_URL;
  return { essUrl, adminUrl, accountLoginUrl };
}

//for ess only redirection.
export const redirectToInitialEss = (essUrl, adminUrl) => {
  const essUrlEffective = essUrl || window.location.origin;
  const adminUrlEffective = adminUrl || process.env.REACT_APP_HRMS_BASE_URL;
  console.log("redirectToInitialEss called", essUrl, adminUrlEffective)
  window.open(
    [`${adminUrlEffective}?app_callback_url=`, essUrlEffective].join(
      ""
    ),
    "_self"
  );
};

export const redirectToInitialAccountLogin = (essUrl, accountLoginUrl) => {
  const essUrlEffective = essUrl || window.location.origin;
  const accountUrlEffective = accountLoginUrl || process.env.REACT_APP_ACCOUNT_LOGIN_BASE_URL;
  console.log("redirectToInitialEss called", essUrl, accountUrlEffective)
  window.open(
    [`${accountUrlEffective}?redirect=`, essUrlEffective].join(
      ""
    ),
    "_self"
  );
};

function decodeBuffer(buffer, encoding = "base64") {
  return Buffer.from(buffer, encoding).toString();
}

function encodeBuffer(data, encoding = "base64") {
  return Buffer.from(JSON.stringify(data)).toString(encoding);
}

function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function notValidFileUploaded(file) {
  const validExtensions = ["csv"];
  const fileExtension = file?.split(".").pop();
  return validExtensions.includes(fileExtension);
}

function validateIp(email) {
  const re =
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return re.test(String(email).toLowerCase());
}
function validatePhoneNumber(phone_number) {
  // const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  const re = /^\D*(?:\d\D*){7,}$/;
  return re.test(String(phone_number).toLowerCase());
}

function validateName(name) {
  const re = /[`!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?~]/; /// SPEACIAL CHAR ARE NOT ALLOW ONLY " ' " THIS IS ALLOWED
  return re.test(String(name).toLowerCase());
}

const usePreviousValue = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const createTableData = (arr, formatObj = [], condtions = {}) => {
  const data = [];
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    let obj = {};
    formatObj.forEach((key) => {
      if (
        condtions.hasOwnProperty(key) &&
        Array.isArray(condtions[key]) &&
        !isNaN(element[key]) &&
        element[key] !== null
      ) {
        obj[key] = condtions[key][element[key]];
      } else if (
        condtions.hasOwnProperty(key) &&
        typeof condtions[key] === "object" &&
        condtions[key].type === "date"
      ) {
        if (element[key]) {
          const val = get_formated_date(element[key], condtions[key].format);
          // const val = setCurrentDateFormat(new_date, condtions[key].format);
          obj[key] = val ? val : "";
        } else {
          obj[key] = "";
        }
      } else {
        obj[key] = element[key];
      }
    });
    obj = { ...obj, ..._.omit(element, formatObj) };
    data.push({ ...obj });
  }
  return data;
};

const onKeyPressValidation = (key, type) => {
  switch (type) {
    case "number":
      return /\d/g.test(key);
    case "email":
      return /[^A-Za-z0-9_\-@\.]/g.test(key);
    default:
      return /\W/g.test(key);
  }
};

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

//basically converts an array of objects to object of objects with a key that is mentioned.
//search globally for reference
function convertArrayToObjectWithKey(array, key) {
  if (!Array.isArray(array)) {
    return {}
  }
  return array.reduce((acc, curr) => {
    acc[curr[key]] = curr;
    return acc;
  }, {});
}


const getIdsObject = (object, id) => {
  const ids = [];
  JSON.stringify(object, (key, value) => {
    if (key === `${id}`) ids.push(value);
    return value;
  });
  return ids;
};
const encrypt = (text) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  var enc_str = text.split("").map(textToChars).map(byteHex).join("");
  return btoa(enc_str);
};

const get_full_date_var = (ip) => {
  if (ip < 10) {
    ip = "0" + ip;
  }
  return ip;
};

const get_curr_date = (format, checkFormatList = true) => {
  var dt = new Date();
  dt.setMinutes(dt.getMinutes() + 330 + Number(dt.getTimezoneOffset()));
  return get_formated_date(dt / 1000, format, checkFormatList);
};

const get_formated_date = (
  timestamp,
  format,
  checkFormatList = true,
  time_zone = 330
) => {
  if (format === undefined) {
    format = "Y-m-d H:i:s";
  }

  if (checkFormatList) {
    format = date_formats_list(date_formates, format);
  }

  if (timestamp === undefined) {
    timestamp = new Date() / 1000;
  }
  var dt;

  if (typeof timestamp === "string") {
    if (timestamp.length == 10) {
      timestamp += " 00:00:00";
    }

    dt = new Date(timestamp);
  } else if (typeof timestamp === "object") {
    dt = new Date(timestamp);
  } else {
    dt = new Date(timestamp * 1000);
  }
  if (dt == "Invalid Date") {
    return null;
  }
  var Y = dt.getFullYear();
  if (Y.toString().length > 4) {
    dt = new Date(timestamp);
  }
  //dt.setMinutes(dt.getMinutes() + Number(time_zone) + Number(dt.getTimezoneOffset()));
  var dobj = {};
  dobj["Y"] = dt.getFullYear();
  dobj["y"] = dt.getYear();
  dobj["n"] = dt.getMonth() + 1;
  dobj["m"] = get_full_date_var(dobj.n);
  dobj["j"] = dt.getDate();
  dobj["d"] = get_full_date_var(dobj.j);
  // if (dobj["Y"] + "-" + dobj["m"] + "-" + dobj["d"] == "1970-01-01") {
  //   return null;
  // }
  dobj["w"] = dt.getDay();
  dobj["N"] = dobj.w;
  dobj["D"] = weekday[dobj.w];
  dobj["l"] = weekfullday[dobj.w];
  dobj["S"] = day_suffix[dobj.j] || "th";
  dobj["M"] = months[dobj.n];
  dobj["F"] = months_full[dobj.n];
  dobj["G"] = dt.getHours();
  dobj["H"] = get_full_date_var(dobj.G);
  dobj["i"] = get_full_date_var(dt.getMinutes());
  dobj["s"] = get_full_date_var(dt.getSeconds());
  dobj["g"] = dobj.G;
  dobj["t"] = get_full_date_var(new Date(dobj.Y, dobj.n, 0).getDate());
  dobj["A"] = " AM";
  dobj["a"] = " am";
  if (dobj["g"] >= 12) {
    dobj["g"] %= 12;
    dobj["A"] = " PM";
    dobj["a"] = " pm";
  }
  if (dobj["g"] == 0) {
    dobj["g"] = 12;
  }
  if (dobj.N == 0) {
    dobj.N = 7;
  }

  if (dobj.y >= 100) {
    dobj.y -= 100;
  }

  dobj["W"] =
    1 +
    Math.round(
      ((dt.getTime() - new Date(dobj.Y, 0, 4).getTime()) / 86400000 + 1) / 7
    );
  dobj["h"] = get_full_date_var(dobj.g);
  var date_str = "";
  format
    ?.toString()
    .split("")
    .forEach((fm) => {
      date_str += dobj.hasOwnProperty(fm)
        ? fm == "y" && dobj[fm].toString()?.length == 1
          ? `0${dobj[fm]}`
          : dobj[fm]
        : fm;
    });
  return date_str;
};

const exportErrData = (errStr) => {
  var linkElement = document.createElement("a");
  try {
    var uri = "data:application/csv;base64,";
    var base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    };
    var csv_data = errStr.replace(/<br>/g, "\r\n");
    linkElement.setAttribute("href", uri + base64(csv_data));
    linkElement.setAttribute("download", "upload_err_log.csv");
    var clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    linkElement.dispatchEvent(clickEvent);
  } catch (ex) {
    console.error(ex);
  }
};

const setCurrentDateFormat = (date = new Date(), format = "d-M-Y") => {
  if (`${new Date(date)}` !== "Invalid Date") {
    switch (format) {
      case "d-M-Y":
        return moment(new Date(date)).format("DD-MMM-YYYY");
      case "Y-d-m":
        return moment(new Date(date)).format("YYYY-DD-MM");
      case "m-d-Y":
        return moment(new Date(date)).format("MM-DD-YYYY");
      case "d M y":
        return moment(new Date(date)).format("DD MMM YYYY");
      case "Y-m-d":
        return moment(new Date(date)).format("YYYY-MM-DD");
      default:
        return moment(new Date(date)).format("YYYY-MM-DD");
    }
  }
  return "";
};
const setCurrentDateFormatWithDateFn = (
  date = new Date(),
  format = "d-M-Y"
) => {
  if (`${new Date(date)}` !== "Invalid Date") {
    switch (format) {
      case "d-M-Y":
        return format(new Date(date), "dd MMM yyyy");
      case "Y-d-m":
        return format(new Date(date), "yyyy dd MM");
      case "m-d-Y":
        return format(new Date(date), "MM dd yyyy");
      case "d M y":
        return format(new Date(date), "dd MMM yyyy");
      case "Y-m-d":
        return format(new Date(date), "yyyy MM dd");
      default:
        return format(new Date(date), "yyyy MM dd");
    }
  }
  return "";
};
const exractObject = (obj, data = {}, keyName = "") => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && Array.isArray(obj[key])) {
      data[keyName ? `${keyName}_${key}` : key] = obj[key];
    } else if (typeof obj[key] === "object" && !_.isEmpty(obj[key])) {
      data = {
        ...data,
        ...exractObject(obj[key], data, keyName ? `${keyName}_${key}` : key),
      };
    } else {
      data[keyName ? `${keyName}_${key}` : key] = obj[key];
    }
  });
  return { ...data };
};

const exractObjectArr = (arr, formatObj = [], condtions = {}) => {
  const data = [];
  for (let index = 0; index < arr.length; index++) {
    const element = exractObject(arr[index], {});
    let obj = {};
    formatObj.forEach((key) => {
      if (
        condtions.hasOwnProperty(key) &&
        Array.isArray(condtions[key]) &&
        !isNaN(element[key]) &&
        element[key] !== null
      ) {
        obj[key] = condtions[key][element[key]];
      } else if (
        condtions.hasOwnProperty(key) &&
        typeof condtions[key] === "object" &&
        condtions[key].type === "date"
      ) {
        if (element[key]) {
          obj[key] = get_formated_date(element[key], condtions[key].format);
        }
      } else {
        obj[key] = element[key];
      }
      if (element["TAT_UNIT"] === "") {
        element["TAT"] = element.TAT + " -";
      }
      element["TAT"] = element.TAT + " " + element.TAT_UNIT;
    });
    obj = { ...obj, ..._.omit(element, formatObj) };
    data.push({ ...obj });
  }
  return data;
};
const exractUpdateArray = (arr, formatObj = [], condtions = {}) => {
  const data = [];
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    let obj = {};
    formatObj.forEach((key) => {
      if (
        condtions.hasOwnProperty(key) &&
        Array.isArray(condtions[key]) &&
        !isNaN(element[key]) &&
        element[key] !== null
      ) {
        obj[key] = condtions[key][element[key]];
      } else if (
        condtions.hasOwnProperty(key) &&
        typeof condtions[key] === "object" &&
        condtions[key].type === "date"
      ) {
        if (element[key]) {
          obj[key] = get_formated_date(element[key], condtions[key].format);
        }
      } else {
        obj[key] = element[key];
      }
    });
    obj = { ...obj, ..._.omit(element, formatObj) };
    data.push({ ...obj });
  }
  return data;
};

const formatObj = (arr, formatObj = []) => {
  const data = [];
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    let obj = {};
    formatObj.forEach((key) => {
      obj[key] = element[key];
    });
    obj = { ...obj, ..._.omit(element, formatObj) };

    data.push({ ...obj });
  }
  return data;
};
const treeToArray = (treeData, field) => {
  var result = [];
  if (!field) field = "children";
  for (var key in treeData) {
    var obj = typeof treeData[key] == "object" ? treeData[key] : {};
    var clone = JSON.parse(JSON.stringify(obj));
    delete clone[field];
    result.push(clone);
    //
    if (obj[field]) {
      var tmp = treeToArray(obj[field], field);
      result = result.concat(tmp);
    }
  }
  return result;
};

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const updateForm = (data, format) => {
  const new_date = new Date(moment(data, "YYYY-MM-DD HH:mm:ss"));
  return get_formated_date(new_date, format);
};
function hashCode(str) {
  if (typeof str !== 'string') {
    return 0;
  }
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
const randomColor = (seed) => {
  const colorArray = [
    "#0061FE",
    "#ECB22E",
    "#12805C",
    "#D7373F",
    "#895ECC",
    "#FF9C55",
    "#FF7F50",
    "#6495ED",
    "#FFD700",
    "#2E8B57",
    "#9932CC",
    "#8A2BE2",
    "#20B2AA",
    "#FF69B4",
    "#32CD32",
    "#00CED1",
    "#FF4500",
    "#4682B4",
    "#D2691E",
    "#00FFFF",
    "#FF6347",
    "#7B68EE",
    "#8B4513",
    "#1E90FF",
    "#FFA07A",
    "#40E0D0",
    "#8B008B",
    "#00FA9A",
    "#FF8C00",
    "#9370DB",
    "#ADFF2F",
    "#00BFFF",
    "#FF1493",
    "#48D1CC",
    "#B0C4DE",
  ];
  if (seed) {
    // If seed is provided, use it to generate a predictable random color
    const seededRandom = Math.abs(hashCode(seed)) % colorArray.length;
    return colorArray[seededRandom];
  } else {
    colorArray.sort();
    return colorArray[randomIntFromInterval(0, colorArray.length - 1)];
  }
};

const titleCase = (toTransform) => {
  return `${toTransform}`
    .toLocaleLowerCase()
    .replace(/\b([a-z])/g, function (_, initial) {
      return initial.toUpperCase();
    });
};

const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
const mergeTwoArrayOfObjectUniquely = (a, b, prop) => {
  var reduced = a.filter(
    (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
  );
  return reduced.concat(b);
};
const checkStringIsJSON = (data) => {
  let ans;
  try {
    ans = JSON.parse(data) ? true : false;
  } catch (e) {
    ans = false;
  }
  return ans;
};

const converBase64toBlob = (content, contentType) => {
  contentType = contentType || "";
  var sliceSize = 512;
  var byteCharacters = window.atob(content);
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, {
    type: contentType,
  }); //statement which creates the blob
  return blob;
};

export function jsonToFormData(json) {
  const formData = new FormData();

  const convertObjectToFormData = (prefix, obj) => {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const propName = prefix ? `${prefix}[${key}]` : key;
        const value = obj[key];

        if (value instanceof Date) {
          formData.append(propName, value.toISOString());
        } else if (typeof value === 'object' && !(value instanceof File)) {
          convertObjectToFormData(propName, value);
        } else {
          formData.append(propName, value);
        }
      }
    }
  };

  convertObjectToFormData('', json);

  return formData;
}

export function convertToArrayOfArrays(originalArray, lengths) {
  const result = [];
  let currentIndex = 0;

  lengths.forEach((length) => {
    result.push(originalArray.slice(currentIndex, currentIndex + length));
    currentIndex += length;
  });

  return result;
}

const bytesToMegaBytes = (bytes) => Math.round(bytes / (1024 * 1024));

/**
 * Performs size and extension check on file object.
 * @param {FileObject} file Uploaded file object
 * @param {Number} allowedSize Allowed file size in bytes.
 * @param {Array<String>} allowedExt Allowed file extensions for validation.
 * @returns
 */
const fileValidation = (file, allowedSize, allowedExt) => {
  const [_, fileExt] = file.type.split("/");
  const fileSize = file.size;
  const response = { isValid: true };
  if (fileSize > allowedSize) {
    response.isValid = false;
    response.message = `File shall not be greater than ${bytesToMegaBytes(
      allowedSize
    )}MB.`;
  } else if (!allowedExt.includes(fileExt)) {
    response.isValid = false;
    response.message = `Only ${allowedExt.join(",")} files are allowed.`;
  }

  return response;
};

const encodeQueryData = (data) => {
  // encoding query strings
  const ret = [];
  for (let d in data) {
    if (data[d] || data[d] == 0) {
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    }
  }
  return ret.join("&");
};

const allowWithoutMenuURL = (url) => {
  let urlList = ["engage/survey/result", "engage/survey/summary"];
  let found = false;
  urlList.forEach((item) => {
    if (url.includes(item)) {
      found = true;
    }
  });
  return found;
};

const getRole = () => {
  try {
    let role = "";
    let data = localStorage.getItem("adminUserDetails");
    if (data) {
      let roleData = JSON.parse(data);
      const roleIds = roleData?.data?.auth?.role_ids ?? [];
      if (roleIds.length > 0) {
        role = roleIds.includes(2) || roleIds.includes(4) ? "ADMIN" : "";
      }
      console.log(roleIds?.includes(2), 'roleIds')
    }
    console.log(role, 'role');
    return role;
  } catch (err) {
    console.log("error in getting role from local Storage", err);
  }
};

export {
  allowWithoutMenuURL,
  encodeQueryData,
  randomIntFromInterval,
  validateEmail,
  validateIp,
  usePreviousValue,
  validatePhoneNumber,
  onKeyPressValidation,
  convertArrayToObject,
  convertArrayToObjectWithKey,
  getIdsObject,
  encrypt,
  createTableData,
  get_curr_date,
  get_formated_date,
  get_full_date_var,
  formatObj,
  exractUpdateArray,
  exractObject,
  treeToArray,
  randomColor,
  updateForm,
  setCurrentDateFormat,
  exractObjectArr,
  exportErrData,
  titleCase,
  getAge,
  mergeTwoArrayOfObjectUniquely,
  notValidFileUploaded,
  checkStringIsJSON,
  setCurrentDateFormatWithDateFn,
  converBase64toBlob,
  validateName,
  decodeBuffer,
  encodeBuffer,
  fileValidation,
  getRole,
};
