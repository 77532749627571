import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// Source MUI - docs. Can be used with mui's tabs component
//Is being used at other places in app but they are just copy pasting it multiple times.

function TabPanel(props) {
  const { children, value, index, unMountPanelsOnTabChange, ...other } = props;
  const panelToBeShown = (index) => value === index;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={
        {
          display : (!panelToBeShown(index) && !unMountPanelsOnTabChange)  ? 'none' : 'initial'
        }
      }
    >
      {( unMountPanelsOnTabChange ? panelToBeShown(index) : true ) && (
        <Box sx={{ p: 1 }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
