  import { styled } from "@mui/material";
  import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
  import MuiToggleButton from "@mui/material/ToggleButton";
  import "./CustomToggleButton.css";

  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    "&.Mui-selected": {
      backgroundColor: "#fff",
      borderColor: "#4caf50", // Change the border color on selection
      transition: "background-color 0.3s ease-in-out, border-color 0.3s ease-in-out", // Add transitions for both background color and border color
    },

  }));

  const CustomToggleButton = ({
    toggleData,
    tabValue,
    handleChangetab,
    onTabClick = () => {},
  }) => {
    return (
      <ToggleButtonGroup
        color="primary"
        value={tabValue}
        exclusive
        onChange={handleChangetab}
        aria-label="Platform"
        className="toggle-button-group"
        // style={{flexWrap:"wrap"}}
      >
        {Array.isArray(toggleData) &&
          toggleData?.map((el) => {
            return (
              <ToggleButton
                value={el?.value}
                disabled={tabValue === el?.value}
                key={el?.value}
                onClick={
                  typeof onTabClick === "function"
                    ? () => onTabClick(el)
                    : () => {}
                }
              >
                {el?.label}
              </ToggleButton>
            );
          })}
      </ToggleButtonGroup>
    );
  };

  export default CustomToggleButton;
