export const FRENCH = {
    fr: {
        language: "French",
        header: {
            beacon: {
                searchPlaceholder: "Cliquez ici pour une recherche intelligente..."
            },

            popOver: {
                profile: "Mon profil",
                logout: "Se déconnecter"
            }
        },

        inputs: {
            search: "Recherche...",

            placeholder: {
                searchByEmployee: "Rechercher par employé"
            },

            labels: {
                appraisalPercentage: "Pourcentage d'évaluation",
                applicableDate: "Date d'application",
                incentiveAmount: "Montant de l'incitation",
                normalizedRating: "Note normalisée",
                year: "Année",
                cycle: "Cycle",
                periodicity: "Périodicité"
            }
        },

        modals: {
            titles: {
                appraisalPercentage: "Pourcentage d'évaluation",
                incentiveAmount: "Montant de l'incitation",
                initiateIncentive: "Initier l'incitation",
                normalizedRating: "Note normalisée",
            }
        },

        buttons: {
            updateReviewers: "Mettre à jour les évaluateurs",
            viewDetails: "Voir les détails",
            initiate: "Initier",
            createStructure: "Créer une structure",
            appreciate: "Apprécier",
            scheduleOneToOne: "Planifier un tête-à-tête",
            giveFeedback: "Donner un retour d'information",
            bulkUploadGrade: "Téléchargement en masse des notes",
            cancel: "Annuler",
            update: "Mettre à jour",
            submit: "Soumettre",
            createNewObjective: "Créer objectif"
        },

        select: {

            labels: {
                selectEvaluationYear: "Sélectionnez l'année d'évaluation",
                selectEvaluationCycle: "Sélectionnez le cycle d'évaluation",
                selectCycleQuarter: "Sélectionnez le trimestre du cycle",
                chooseObjectiveCycle: "Choisir le cycle d'objectif",
                choosePeriod: "Choisir la période",
            },

            placeholder: {
                approvalStatus: "Statut d'approbation"
            },

            options: {
                all: "Tous",
                approved: "Approuvé",
                pendingApproval: "En attente d'approbation",
                pendingApprovalOnRM: "En attente d'approbation sur RM",
                rejected: "Rejeté",
                thisMonth: "Ce Mois-ci",
                lastMonth: "Le Mois Dernier",
                thisQuarter: "Ce Trimestre",
                lastQuarter: "Le Trimestre Dernier",
                thisYear: "Cette Année",
                lastYear: "L'Année Dernière",
                upcoming: "À venir",
                completed: "Terminé",
                overdue: "En retard"
            }

        },

        bulkUpload: {
            titleOne: "1. Télécharger le modèle",
            subTitleOne: "Utilisez ce modèle avec des colonnes prédéfinies, et remplissez vos données.",
            downloadTemplate: "Télécharger le modèle",
            titleTwo: "2. Importer la feuille Excel",
            subTitleTwo: "Une fois que vous avez rempli la feuille Excel, importez-la.",
            dragAndDrop: "Glissez et déposez votre fichier ici ou",
            chooseFile: "Choisir un fichier"
        },

        table: {
            staticHeaders: {
                employeeCode: "Code Employé",
                employeeName: "Nom de l'employé",
                employee: "Employee",
                evalCycle: "Cycle d'évaluation",
                cycle: "Cycle",
                startDate: "Date de début",
                endDate: "Date de fin",
                cycleYear: "Année de cycle",
                cycleQuarter: "Trimestre du cycle",
                structureName: "Nom de la structure",
                action: "Action",
                employees: "Employés",
                finalRating: "Évaluation finale",
                ratingYCoordinate: "Coordonnée Y de l'évaluation",
                designation: "Désignation",
                department: "Département",
                location: "Emplacement",
                employeeEmail: "E-mail de l'employé",
                employeeCount: "Nombre d'employés",
                evaluationYear: "Année d'évaluation",
                createdBy: "Créé par",
                cycleName: "Nom du cycle",
                structureOwner: "Propriétaire de la structure",
                status: "Statut",
                keyResult: "Résultat clé",
                objectivePeriodicity: "Périodicité de l'objectif",
                startTarget: "Début de la cible",
                endTarget: "Fin de la cible",
                checkinValue: "Valeur du point de contrôle",
                checkinRemarks: "Remarques de point de contrôle",
                objective: "Objectif",
                periodicity: "Périodicité",
                appraisalYear: "Année d'évaluation",
                reportingTo: "Rapport à",
                preNormalizedRating: "Évaluation avant normalisation",
                grade: "Note",
                appraisalPercentage: "Pourcentage d'évaluation",
                previousCtc: "Salaire précédent",
                increasedCtc: "Salaire augmenté",
                incentiveAmount: "Montant de l'incitation",
                applicableDate: "Date d'application",
                previousDesignation: "Ancien poste",
                newDesignation: "Nouveau poste",
                objectiveWeightage: "Poids de l'objectif",
                employeeAcceptanceStatus: "Statut d'acceptation des employés",
                employeeApprovalRemarks: "Remarques d'approbation des employés"
            },
            dynamicHeaders: {
                EMPLOYEE_CODE: "Code Employé",
                EMPLOYEE_NAME: "Nom Employé",
                CYCLE_NAME: "Nom du cycle",
                STRUCTURE_NAME: "Nom de la structure",
                H1_TOTAL_WTG: "Poids total H1",
                H1_ACHEIVED_WTG: "Poids atteint H1",
                H1_ACHEIVED_WTG_PERCENTAGE: "Pourcentage de poids atteint H1",
                H1_PERFORMANCE_RATING: "Évaluation des performances H1",
                H1_COMPETENCY_RATING: "Évaluation de la compétence H1",
                H1_POTENTIAL_RATING: "Évaluation du potentiel H1",
                H1_WEIGHTED_PERFORMANCE_RATING: "Évaluation pondérée des performances H1",
                H1_WEIGHTED_COMPETENCY_RATING: "Évaluation pondérée de la compétence H1",
                H1_WEIGHTED_POTENTIAL_RATING: "Évaluation pondérée du potentiel H1",
                H1_FINAL_RATING: "Évaluation finale H1"
            },
            actions: {
                compute: "Calculer",
                view: "Voir",
                initiate: "Initier"
            }
        },

        login: {
            title: "Connexion",
            img1Text: "Meilleure plateforme d'expérience employé",
            img1SubText: "Ne vous limitez pas. Beaucoup de gens se limitent à ce qu'ils pensent pouvoir faire. Vous pouvez aller aussi loin que votre esprit vous permet. Vous atteignez ce que vous croyez.",
            img2Text: "Enfin un endroit où tout se rassemble",
            img2SubText: "Des employés comme vous peuvent même rendre les lundis joyeux. Merci pour votre travail acharné et votre super attitude.",
            img3Text: "Concentrez-vous sur le travail qui compte",
            img3SubText: "Les opportunités d'affaires sont comme des bus, il y en a toujours un autre qui arrive.",
            notRegistered: "Pas encore enregistré?",
            signUp: "S'inscrire",
            forgotPassword: "Mot de passe oublié?",
            loginBtnText: "Connexion",
            loginWithOkta: "Se connecter avec Okta",
            loginWithSso: "Se connecter avec SSO",
            emailLabel: "E-mail ou numéro de portable",
            passwordLabel: "Mot de passe"
        },

        tabs: {
            pending: "En attente",
            completed: "Terminé",
            expired: "Expiré",
            received: "Reçu",
            given: "Donné",
            approved: "Approuvé",
            rejected: "Rejeté",
        },

        popConfirm: {
            areYouSure: "Êtes-vous sûr ?",
            approveDesignation: "Voulez-vous approuver la nouvelle désignation",
            rejectDesignation: "Voulez-vous rejeter la nouvelle désignation",
            action: {
                cancel: "Annuler",
                approve: "Approuver",
                reject: "Rejeter"
            }
        },

        navbar: {
            "Admin Dashboard": "Tableau de bord administrateur",
            "Dashboard": "de bord administrateur",
            "Review": "Révision",
            "Quick Actions": "Actions rapides",
            "Analytics": "Analytique",
            "Pipeline": "Pipeline",
            "Rating Comparison": "Comparaison des notes",
            "Bell Curve": "Courbe en cloche",
            "Objective": "Objectif",
            "Feedback": "Retour d'information",
            "My Team": "Mon équipe",
            "Self": "Soi-même",
            "Team": "Équipe",
            "KRA-KPI Approval": "Approbation des KRA-KPI",
            "KRA/KPI Approval": "Approbation des KRA/KPI",
            "Target Setting": "Définition des objectifs",
            "Peer Assessment": "Évaluation par les pairs",
            "Approval": "Approbation",
            "Organization": "Organisation",
            "KRA-KPI Creation": "Création de KRA-KPI",
            "KRA-KPI Mapping": "Cartographie de KRA-KPI",
            "Result": "Résultat",
            "Request": "Demande",
            "Check-In": "Vérification",
            "Key-Result": "Résultat clé",
            "Appreciation": "Appréciation",
            "One to One": "Un à un",
            "Continuous Feedback": "Retour continu",
            "Compensation": "Compensation",
            "All": "Tout",
            "Normalization": "Normalisation",
            "Appraisal": "Évaluation",
            "Incentive": "Incitation",
            "Promotion": "Promotion",
            "Reports": "Rapports",
            "OKR": "OKR",
            "Settings": "Paramètres",
            "Global": "Global",
            "Modules": "Modules",
            "Time Format": "Format de l'heure",
            "People": "Personnes",
            "Untagged": "Non étiqueté",
            "Terminology": "Terminologie",
            "Year": "Année",
            "Cycle": "Cycle",
            "KRA-KPI Configuration": "Configuration des KRA-KPI",
            "Rating Scale": "Échelle de notation",
            "Parameters": "Paramètres",
            "Mandatory Parameters": "Paramètres obligatoires",
            "Employee Visibility": "Visibilité de l'employé",
            "9-Box": "9-Box",
            "Set-up": "Installation",
            "Workflow": "Flux de travail",
            "Templates": "Modèles",
            "Theme": "Thème",
            "Normalization Rating": "Évaluation de normalisation",
            "Library": "Bibliothèque",
            "KRAS": "KRAS",
            "KPIS": "KPIS",
            "Potential": "Potentiel",
            "Competency": "Compétence",
            "Key Result": "Résultat clé",
            "Appreciation Badges": "Insignes d'appréciation",
            "Skills": "Compétences",
            "Imports": "Imports",
            "Bulk Goals Setting": "Paramétrage des objectifs en masse",
            "Bulk Goals Achievement": "Réalisation des objectifs en masse",
            "Quick Performance Evaluation": "Évaluation rapide des performances",
            "Bulk Objective": "Objectif en masse",
            "Bulk Objective Key Results": "Résultats clés des objectifs en masse",
            "Bulk Structure": "Structure en masse",
            "Email": "Email",
            "Email Configuration": "Configuration de l'email",
            "Email Library": "Bibliothèque d'email",
            "Notification": "Notification",
            "Self Assessment": "Auto-évaluations"
        },

        reports: {
            review: {
                appraiserMatrix: {
                    name: "Matrice d'évaluateur d'employé",
                    desc: "Fournit un aperçu des évaluateurs de performance ou des évaluateurs associés à chaque employé à différentes étapes du cycle de révision, présentant un instantané complet des évaluateurs assignés tout au long du processus de révision."
                },
                stageStatus: {
                    name: "Rapport sur l'état de la phase de l'employé",
                    desc: "Fournit un résumé complet de l'état de la révision à chaque étape du cycle de révision, offrant des informations sur la progression des évaluations des employés tout au long des différentes étapes du processus de révision."
                },
                combinedPerformenceAverage: {
                    name: "Rapport moyen de performance combinée",
                    desc: "Fournit une moyenne des notes de performance reçues à différentes étapes du cycle de révision, offrant une vue d'ensemble de leur performance par rapport à différents paramètres de performance tout au long de la période d'évaluation."
                },
                competencyAverage: {
                    name: "Rapport moyen de compétence %COMPETENCY%",
                    desc: "Fournit une moyenne des notes de compétence reçues à différentes étapes du cycle de révision, offrant une vue d'ensemble de leur performance dans différentes compétences tout au long de la période d'évaluation."
                },
                kraAverage: {
                    name: "Rapport moyen %KRA_LABEL%/%KPI_LABEL%",
                    desc: "Le rapport résume les notes KRA/KPI d'un employé tout au long du cycle de révision, offrant un aperçu de leur performance par rapport aux objectifs fixés tout au long de la période d'évaluation."
                },
                targetAchievement: {
                    name: "Rapport de réalisation de l'objectif",
                    desc: "Le 'Rapport de réalisation de l'objectif' détaille dans quelle mesure les objectifs fixés ont été atteints au cours d'une période d'évaluation, en décrivant les progrès et le succès dans l'atteinte des objectifs souhaités."
                },
                potentialAverage: {
                    name: "Rapport moyen de potentiel %POTENTIALS%",
                    desc: "Fournit une moyenne des notes de potentiel reçues à différentes étapes du cycle de révision, offrant une vue d'ensemble de leur performance dans différents potentiels tout au long de la période d'évaluation."
                },
                cycleRatings: {
                    name: "Rapport des notes de cycle",
                    desc: "Offre un résumé complet des évaluations effectuées pour un employé tout au long d'un cycle d'évaluation, fournissant une vue d'ensemble de leur performance et des commentaires reçus pendant cette période."
                },
                targetVsAchievementOverview: {
                    name: "Aperçu de l'objectif par rapport à la réalisation",
                    desc: "Ce rapport fournit une vue détaillée des réalisations pour l'objectif défini par rapport à chaque paramètre de performance."
                },
                targetBasedPerformence: {
                    name: "Rapports de performance basés sur les objectifs",
                    desc: "Ce rapport fournit une vue détaillée des réalisations pour l'objectif défini par rapport à chaque paramètre de performance."
                },
                nineBox: {
                    name: "Rapport détaillé de la matrice 9Box",
                    desc: "Ce rapport fournit une vue détaillée de la matrice 9Box pour l'objectif défini par rapport à chaque paramètre de performance."
                },
                employeeReview: {
                    name: "Notations finales",
                    desc: "Ce rapport fournit les scores de notation de chaque évaluateur."
                },
                employeeAppraisal: {
                    name: "Rapport d'évaluation des employés",
                    desc: "Ce rapport fournit un aperçu détaillé de l'évaluation des employés."
                },
                employeePromotion: {
                    name: "Rapport de promotion des employés",
                    desc: "Ce rapport fournit un aperçu détaillé de la promotion des employés"
                },
                employeeUntag: {
                    name: "Rapport de détagage des employés",
                    desc: "Ce rapport fournit un aperçu détaillé des employés non identifiés."
                },
                finalAverageRating: {
                    name: "Note moyenne finale",
                    desc: "Ce rapport fournit un aperçu détaillé de la note moyenne finale"
                },
                finalRatingAchievements: {
                    name: "Réalisations de la note finale",
                    desc: "Ce rapport fournit un aperçu détaillé des réalisations en matière de notation finale."
                },
                potentialRatings: {
                    name: "Rapports de notation potentiels",
                    desc: "Ce rapport fournit un aperçu détaillé des rapports de notation potentiels."
                }
            },
            okr: {
                objectiveCompletion: {
                    name: "Rapport d'achèvement de l' %OBJECTIVE% par employé",
                    desc: "Fournit un aperçu de l'achèvement de l' %OBJECTIVE% par employé"
                },
                objectiveKeyResult: {
                    name: "%OBJECTIVE% %KEY_RESULT_LABEL%",
                    desc: "Fournit un aperçu de %OBJECTIVE% %KEY_RESULT_LABEL%"
                },
                checkInApproval: {
                    name: "État d'approbation de Check-ins",
                    desc: "Fournit un aperçu des check-ins et de leurs états d'approbation"
                },
                objectiveLog: {
                    name: "Rapports de journal d'objectif",
                    desc: "Fournit un aperçu des journaux (check-ins, création, édition, suppression) d'objectifs/résultats clés"
                },
                competencyRating: {
                    name: "Rapport de notation des compétences",
                    desc: "Ce rapport fournit un aperçu détaillé de la notation des compétences."
                },
                employeeStructureKraKpi: {
                    name: "Rapport de structure des employés %KRA_LABEL% %KPI_LABEL%",
                    desc: "Ce rapport fournit un aperçu détaillé de la structure des employés %KRA_LABEL% %KPI_LABEL%."
                }
            },
            continuousFeedback: {
                oneToOne: {
                    name: "Rapport de rétroaction continue",
                    desc: "Fournit un aperçu de la rétroaction continue par employé"
                },
                feedback: {
                    name: "Rapport de réunion individuelle",
                    desc: "Fournit un aperçu de la réunion individuelle avec l'employé"
                },
                appreciation: {
                    name: "Rapport d'appréciation de l'employé",
                    desc: "Fournit un aperçu du rapport d'appréciation de l'employé"
                }
            },
        },


        filters: {
            default: {
                multiSelect: "Sélectionnez Filtres...",
                singleSelect: "Sélectionnez Filtre..."
            },
            cycle: "Cycle",
            department: "Département",
            designation: "Désignation",
            location: "Emplacement",
            entity: "Entité",
            more: "Plus",
            status: "Statut",
            evalCycle: "Cycle d'évaluation",
            employmentType: "Type d'emploi",
            businessUnit: "Unité opérationnelle",
            costCenter: "Centre de coût",
            shift: "Shift",
            structure: "Structure",
            periodicity: "Périodicité",
            cycleYear: "Année du cycle",
            joiningYear: "Année d'adhésion",
            joiningMonth: "Mois d'adhésion",
            cycleUntaggedEmployees: "Employés non étiquetés du cycle",
            cycleTaggedEmployees: "Employés étiquetés du cycle",
            employeeStatus: "Statut de l'employé",
            mappingStatus: "Statut de la cartographie",
            reset: "Réinitialiser",
            approvalStatus: "Statut d'approbation",
            evalYear: "Année d'évaluation",
            objectiveCycle: "Cycle d'objectifs",
            month: "Mois"
        },

        dashBoard: {
            review: {
                quickActions: {
                    pendingPeopleInformation: "Informations en attente des personnes",
                    needAttention: "Besoin d'attention (Expiré)",
                    kraKpiApproval: "Approbation des KRA/KPI",
                    targetSetting: "Définition des objectifs",
                    selfAssesments: "Auto-évaluations",
                    peerAssesments: "Évaluations par les pairs",
                    reviews: "Révisions",
                    approvals: "Approbations"
                },

                analytics: {
                    reviewDistributionByStage: "Distribution des avis par étape",
                    organizationAchievement: "Réalisations de l'organisation",
                    bestPerformers: "Meilleurs performeurs",
                    worstPerformers: "Pires performeurs",
                    departmentWiseRatingCount: "Nombre de notations par département",
                    designationWiseRatingCount: "Nombre de notations par désignation",
                    nineBoxMatrix: "Matrice 9-BOX",
                    performanceAssessment: "Évaluation des performances",
                    potentialAssessment: "Évaluation du potentiel"
                },
                pipeLines: {
                    kraKpiMapping: "Mapping %KRA%/%KPIS%",
                    kraKpiApproval: "Approbation %KRA%/%KPIS%",
                    targetSetting: "Réglage des objectifs",
                    selfAssessment: "Auto-évaluation",
                    peerAssessment: "Évaluation par les pairs",
                    review: "Révision",
                    approve: "Approuver",
                    review1: "Révision (niveau 1)",
                    review2: "Révision (niveau 2)",
                    approve1: "Approuver (niveau 1)",
                    approve2: "Approuver (niveau 2)",
                    completed: "Complété"
                },
                ratingComparision: {
                    reviewRatingComparison: "Comparaison des évaluations"
                },
                bellCurve: {
                    bellCurveBasedOnFinalRatings: "Courbe de Gauss - Basée sur les notes finales",
                    bellCurveEmployeesRatingWise: "Courbe de Gauss - Employés (par note)",
                    tabs: {
                        ideal: "Idéal",
                        actual: "Réel"
                    }

                }
            }
        },

        objective: {
            organizationOKR: "Objectifs et résultats clés de l'organisation",
            teamOKR: "Objectifs et résultats clés de l'équipe",
            myOKR: "Mes objectifs et résultats clés"
        },

        compensation: {
            initiateEmployeeCompensation: "Démarrer la compensation des employés",
            popOver: {
                moveToNormalization: "Passer à la normalisation",
                moveToAppraisal: "Passer à l'évaluation",
                moveToIncentive: "Passer aux incitations",
                moveToIncentiveAndAppraisal: "Passer aux incitations et à l'évaluation",
                updateGrade: "Mettre à jour le grade",
                moveToAppraisal: "Passer à l'évaluation"
            },

            bulkUpload: {
                normalizationGradeLabel: "Téléchargement en masse des notes normalisées"
            },
        },

        empty: {

            noRecordsFound: "Aucun enregistrement trouvé",

            filters: {
                multiSelect: {
                    cycle: "Veuillez sélectionner des cycles pour les enregistrements"
                },
                single: {
                    cycle: "Veuillez sélectionner un cycle pertinent"
                }
            }
        }
    },
}