
import { ENGLISH } from './en';
import { ARABIC } from './ar';
import { HINDI } from './hi';
import { FRENCH } from './fr';


export const rootDictionary = {
    ...ENGLISH,
    ...ARABIC,
    ...HINDI,
    ...FRENCH
}   