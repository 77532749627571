import { Box, Typography } from "@material-ui/core";
// import noDataSvg from './noData.svg'
const noDataSvg = require('./noData.svg').default;


const NoDataComponent = ({message = 'No records found' , imgStyles = {} }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
          margin: 'auto',
          padding: '20px'
      }}
    >
      <img src={noDataSvg} style={imgStyles} alt="No Data" />
      <Typography
        style={{
          fontFamily: "Roboto",
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 2,
          letterSpacing: 0,
          textAlign: "center",
        }}
      >
        {message}
      </Typography>
    </Box>
  );
}

export default NoDataComponent;
