export const reactSelectStyles = (width = "120px", height = "1.4375em") => ({
  indicatorSeparator: () => ({ display: "none" }),
  menu: (base) => ({
    ...base,
    zIndex: 9999,
    width,
  }),

  control: (styles) => ({
    ...styles,
    // border: "1px solid #A5A3A9",
    height,
    width,
  }),
  singleValue: (styles) => ({
    ...styles,
  }),
  option: (styles) => ({
    ...styles,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data?.color || "#4C70FF",
    backgroundColor: data?.bg || "#E6ECFF",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data?.color || "#615E69",
    backgroundColor: data?.bg || "#E6ECFF",
  }),
  input: (styles) => ({
    ...styles,
    padding: "0px !important",
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
});


export const REVIEW_CARD_SECTIONS = {
  PERFORMANCE: "performance",
  COMPETANCY: "compentancy",
  POTENTIAL: "potential",
  ACHIEVEMENT: "achievement",
  PROMOTION: "promotion",
  OVERALL_REMARKS: "overall_remarks",
  RECONSIDERATION_REMARKS: "reconsideration_remark",
  EMPLOYEE_CTC: "employee_ctc",
  RECOMMEND_SALARY_APPRAISAL: "recommend_salary_appraisal",
  FEEDBACK_FORM: "feedback_forms",
  TARGET_APPROVAL_REASONS: "target_approval_reasons"
}

export const ERROR_IN_FIELDS = 'ERROR IN FIELDS'

export const HIDE_FOR_MODULE_TYPE = [1, 6, 11, 12]

export const FEEDBCK_QUESTIONS_DUUMY_DATA = [
  {
    "QUES_ID": 8278,
    "ORG_ID": 16,
    "QUES_TYPE": 1,
    "SID": 2852,
    "QUES_NAME": "MULTIPLE CHOICE",
    "QUES_REQUIRED": null,
    "CREATED_BY": 40,
    "CREATED_ON": "2024-06-11 12:03:15",
    "DELETE_STATUS": 0,
    "QUES_LABEL": null,
    "MOTIVATION_SCORE_QUES": 0,
    "MOTIVATION_SCORE_VALUES": null,
    "QUES_OPTION": "73097_OP 1||73098_OP 2"
  },
  {
    "QUES_ID": 8279,
    "ORG_ID": 16,
    "QUES_TYPE": 3,
    "SID": 2852,
    "QUES_NAME": "SINGLE CHOICE",
    "QUES_REQUIRED": null,
    "CREATED_BY": 40,
    "CREATED_ON": "2024-06-11 12:03:15",
    "DELETE_STATUS": 0,
    "QUES_LABEL": null,
    "MOTIVATION_SCORE_QUES": 0,
    "MOTIVATION_SCORE_VALUES": null,
    "QUES_OPTION": "73099_OP 1||73100_OP 2"
  },
  {
    "QUES_ID": 8280,
    "ORG_ID": 16,
    "QUES_TYPE": 18,
    "SID": 2852,
    "QUES_NAME": "TEXT AREA",
    "QUES_REQUIRED": null,
    "CREATED_BY": 40,
    "CREATED_ON": "2024-06-11 12:03:15",
    "DELETE_STATUS": 0,
    "QUES_LABEL": null,
    "MOTIVATION_SCORE_QUES": 0,
    "MOTIVATION_SCORE_VALUES": null,
    "QUES_OPTION": null
  },
  {
    "QUES_ID": 8281,
    "ORG_ID": 16,
    "QUES_TYPE": 19,
    "SID": 2852,
    "QUES_NAME": "RATE (1-5)",
    "QUES_REQUIRED": null,
    "CREATED_BY": 40,
    "CREATED_ON": "2024-06-11 12:03:15",
    "DELETE_STATUS": 0,
    "QUES_LABEL": null,
    "MOTIVATION_SCORE_QUES": 0,
    "MOTIVATION_SCORE_VALUES": null,
    "QUES_OPTION": null
  },
  {
    "QUES_ID": 8282,
    "ORG_ID": 16,
    "QUES_TYPE": 20,
    "SID": 2852,
    "QUES_NAME": "EMOJIS",
    "QUES_REQUIRED": null,
    "CREATED_BY": 40,
    "CREATED_ON": "2024-06-11 12:03:15",
    "DELETE_STATUS": 0,
    "QUES_LABEL": null,
    "MOTIVATION_SCORE_QUES": 0,
    "MOTIVATION_SCORE_VALUES": null,
    "QUES_OPTION": null
  },
  {
    "QUES_ID": 8283,
    "ORG_ID": 16,
    "QUES_TYPE": 21,
    "SID": 2852,
    "QUES_NAME": "AGREE/DISAGREE",
    "QUES_REQUIRED": null,
    "CREATED_BY": 40,
    "CREATED_ON": "2024-06-11 12:03:15",
    "DELETE_STATUS": 0,
    "QUES_LABEL": null,
    "MOTIVATION_SCORE_QUES": 0,
    "MOTIVATION_SCORE_VALUES": null,
    "QUES_OPTION": null
  },
  {
    "QUES_ID": 8284,
    "ORG_ID": 16,
    "QUES_TYPE": 22,
    "SID": 2852,
    "QUES_NAME": "NPS",
    "QUES_REQUIRED": null,
    "CREATED_BY": 40,
    "CREATED_ON": "2024-06-11 12:03:15",
    "DELETE_STATUS": 0,
    "QUES_LABEL": null,
    "MOTIVATION_SCORE_QUES": 0,
    "MOTIVATION_SCORE_VALUES": null,
    "QUES_OPTION": null
  },
  {
    "QUES_ID": 8285,
    "ORG_ID": 16,
    "QUES_TYPE": 23,
    "SID": 2852,
    "QUES_NAME": "RATE (1-10)",
    "QUES_REQUIRED": null,
    "CREATED_BY": 40,
    "CREATED_ON": "2024-06-11 12:03:15",
    "DELETE_STATUS": 0,
    "QUES_LABEL": null,
    "MOTIVATION_SCORE_QUES": 0,
    "MOTIVATION_SCORE_VALUES": null,
    "QUES_OPTION": null
  }
]

export const FEEDBACK_FORM_ANSWERS = "8278_73097||8279_73100||8280_TESTING||8281_4||8282_2||8283_3||8284_4||8285_7"
