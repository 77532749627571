import { FcBusinessman } from 'react-icons/fc'
import { AiOutlineFundProjectionScreen } from 'react-icons/ai'

export const CHART_TYPES = {
    LINE: 'line',
    MULTI_LINE: 'multi-line',
    COLUMN: 'column',
    STACKED_COLUMN: 'stacked-column',
    GROUPED_COLUMN: 'grouped-column',
    BAR: 'bar',
    STACKED_BAR: 'stacked-bar',
    GROUPED_BAR: 'grouped-bar',
    DOUGHNUT: 'doughnut',
    PIE: 'pie',
    AREA: 'area',
    STACKED_AREA: 'stacked-area',
    FUNNEL: 'funnel',
    HEAT_MAP: 'heat-map',
    CARD: 'card',
    SUB_CARD: 'card-sub-row',
    MULTI_CARD: 'multi-card',
    LIST: 'list',
    TABLE: 'table',
}

export const CONFIGURE_CHART_LIST_ICON_MAP = {
    CLIENT_NAME: <FcBusinessman />,
    PROJECT_NAME: <AiOutlineFundProjectionScreen />

}

export const COLOR_PALETTE = [
    {
        label: "Default",
        value: [
            '#F1A993',
            '#77D0D7',
            '#BAAAE5',
            '#F1A993',
        ]
    },
    {
        label: "Orange",
        value: [
            '#AD6958',
            '#DB937F',
            '#F6C4B0',
            '#AD6958',
        ]
    },
    {
        label: "Aqua",
        value: [
            '#3C888E',
            '#5AB4BB',
            '#87E1E8',
            '#3C888E',
        ]
    },
    {
        label: "Purple",
        value: [
            '#8173AB',
            '#AC9DD8',
            '#DAC9FB',
            '#8173AB',
        ]
    },
    {
        label: "Yellow",
        value: [
            '#E1A84B',
            '#F5C97C',
            '#F9DDAA',
            '#E1A84B',
        ]
    },
    {
        label: "Pink",
        value: [
            '#AB6581',
            '#D88FAC',
            '#F4BAD7',
            '#AB6581',
        ]
    },
    {
        label: "Blue",
        value: [
            '#4C7FB4',
            '#79A9E1',
            '#A6D5FA',
            '#4C7FB4',
        ]
    },
    {
        label: "Green",
        value: [
            '#638751',
            '#8DB178',
            '#B8DEA1',
            '#638751',
        ]
    }
]