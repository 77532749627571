/* eslint-disable react-hooks/exhaustive-deps */
import { Box, styled } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { current_step } from "../../../CONSTANTS.js";
import CustomFilter from "../../../CustomFilter/CustomFilter.js";
import CustomTablePagination from "../../../components/CustomTablePagination/CustomTablePagination.js";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { useFetch } from "../../../hooks/useFetch";
import useFilterParsedValue from "../../../hooks/useFilterParsedValue";
import { Base64 } from "../../../utils/general_pms.js";
import { get_formated_date } from "../../../utitlies/commonFunction.js";
import { fetchSearchFieldOptions } from "../../apiResponse";
import { fetchReviewList } from "../../reviews/selfassessment.component/selfassessmentTable/allSelfAssessments/AllSelfAssessmentTableComponent.jsx";
import { PERFORMANCE_TABS_ALL } from "../constants";
import "../myTeam.styles.scss";

const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: "#fff",
  },
}));

const filterMappingForNameKeys = {
  "Evaluation Cycle": "cycle_id",
  Structure: "str_id",
  employee_id: "employee_id",
  "Evaluation Year": "cycle_year",
};

const Performance = ({
  isPeople = false,
  employee_id,
  filterType,
  handleClose = () => {},
}) => {
  const { user = {}, token } = useSelector(
    (state) => state.adminUserReducer
    // isEqual
  );

  const initData = {
    approval_status: "1",
    expired: "0",
    completed: "1",
    status: 1,
    page_type: "all-self-assessments",
    APPROVAL_BY: user["user_id"],
    employee_id: user["employee_id"],
    for_employee: employee_id,
    org_id: user["org_id"],
    IS_ADMIN: "1",
    draw: 0,
  };

  const [menuSelected, setMenuSelect] = useState(PERFORMANCE_TABS_ALL.REVIEW);
  const [filter, setFilter] = useState({
    // employee_id: employee_id,
  });
  const navigate = useNavigate();

  const filterParsedValue = useFilterParsedValue(
    filter,
    filterMappingForNameKeys
  );

  const [tablePagination, setTablePagination] = useState({
    perPage: 20,
    page: 0,
  });

  const columns = useMemo(
    () => [
      {
        header: "Employee Name",
        accessorKey: "EMPLOYEE_NAME",
        id: "EMPLOYEE_NAME",
      },
      {
        header: "Cycle",
        accessorKey: "CYCLE_NAME",
        id: "CYCLE_NAME",
      },
      {
        header: "Start Date",
        accessorKey: "EMPLOYEE_APPROVAL_START_DATE",
        id: "START_DATE",
        cell: (data, value) => {
          let startData = value?.EMPLOYEE_APPROVAL_START_DATE
            ? get_formated_date(
                value?.EMPLOYEE_APPROVAL_START_DATE,
                user.ng_date_str
              )
            : "";
          return <>{startData ? <>{startData}</> : <div>N/A</div>}</>;
        },
      },
      {
        header: "End Date",
        accessorKey: "EMPLOYEE_APPROVAL_END_DATE",
        id: "END_DATE",
        cell: (data, value) => {
          let endData = value?.EMPLOYEE_APPROVAL_END_DATE
            ? get_formated_date(
                value?.EMPLOYEE_APPROVAL_END_DATE,
                user.ng_date_str
              )
            : "";
          return <>{endData ? <>{endData}</> : <div>N/A</div>}</>;
        },
      },
      {
        header: "Cycle Year",
        accessorKey: "CYCLE_YEAR",
        id: "CYCLE_YEAR",
      },
      {
        header: "Cycle Quarter",
        accessorKey: "CYCLE_QUARTER",
        id: "CYCLE_QUARTER",
      },
      {
        header: "Current Stage",
        accessorKey: "current_step",
        id: "current_step",
        cell: (data, value) => {
          return current_step?.[data] || "NA";
        },
      },
      {
        header: "Structure Name",
        accessorKey: "STRUCTURE_NAME",
        id: "STRUCTURE_NAME",
      },

      {
        header: "Action",
        accessorKey: "STRUCTURE_NAME",
        id: "STRUCTURE_NAME",
        cell: (data, value) => {
          let startProcess = 0;
          const ids = Base64.encode(
            value["ACTION_USER_ID"] +
              "-" +
              value["EMPLOYEE_ID"] +
              "-" +
              value["CYCLE_ID"] +
              "-" +
              value["STR_ID"] +
              "-" +
              value["CYCLE_QUARTER"] +
              "-" +
              value["modules"] +
              "-" +
              startProcess +
              "-" +
              "pending_on_me" +
              "-" +
              1 +
              "-" +
              6
          );

          return (
            <button
              style={{
                backgroundColor: "rgba(51, 90, 255, 0.05)",
                color: "#4f44e0",
                fontWeight: "600",
                borderRadius: "3px",
                fontSize: "12px",
                height: "auto",
                cursor: "pointer",
                border: "1px solid",
                padding: "4px",
              }}
              onClick={() => {
                handleClose();
                navigate(`${process.env.REACT_APP_SUBDIR_PATH}review/${ids}`);
              }}
            >
              View Details
            </button>
          );
        },
      },
    ],
    [user]
  );
  const { data: dataFromApi, isFetching } = useFetch(
    fetchReviewList,
    {
      isAdmin: 1,
      token,
      params: {
        ...initData,
        ...filterParsedValue,
        page: String(tablePagination?.page),
        limit: String(tablePagination?.perPage),
      },
    },
    "fetchReviewHistory",
    {
      cacheTime: 0,
      staleTime: 0,
      // enabled: PERFORMANCE_TABS_ALL.REVIEW && filterType === 3,
    }
  );

  const [customOptionsMapping, setCustomOptionsMapping] = useState({});

  const { data: getSearchFieldValue = {} } = useFetch(
    fetchSearchFieldOptions,
    { token },
    "fetchSearchFieldOptions",
    {
      cacheTime: 50 * 60 * 1000, // 50 minutes
      staleTime: 50 * 60 * 1000,
    }
  );

  useEffect(() => {
    if (!isEmpty(getSearchFieldValue)) {
      setCustomOptionsMapping({ options: { ...getSearchFieldValue } });
    }
  }, [getSearchFieldValue]);

  return (
    <div>
      <div className="continus___feedback">
        <Box
          sx={{
            mb: 0.8,
          }}
        >
          <CustomFilter
            showMainSearch={true}
            filter={filter}
            setFilter={setFilter}
            customOptionsMapping={customOptionsMapping}
            formFieldValue={getSearchFieldValue}
            filterKey={"filterPeopleList"}
            enableURLCaching={false}
          />
        </Box>
        <Box sx={{ height: "calc(100vh - 400px)", overflow: "auto" }}>
          {menuSelected === PERFORMANCE_TABS_ALL.REVIEW && (
            <>
              <CustomTablePagination
                columns={columns ?? []}
                data={dataFromApi?.result ?? []}
                wrapByTooltipDefault
                loading={isFetching}
                pagination={{
                  show: true,
                  totalDataCount: dataFromApi?.total ?? 0,
                  perPageOptions: [20, 30, 50, 100],
                  perPage: tablePagination?.perPage,
                  showFirstButton: true,
                  showLastButton: true,
                  page: tablePagination?.page,
                  handlePageChange: (value) => {
                    setTablePagination({ ...tablePagination, page: value });
                  },
                  handlePerPageChange: (value) => {
                    setTablePagination({
                      ...tablePagination,
                      perPage: value,
                    });
                  },
                }}
              />
            </>
          )}

          {menuSelected === PERFORMANCE_TABS_ALL.PIP && (
            <Box marginTop={"10px"}>
              <NoDataFound height="30%" width="30%" />
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

export default Performance;
