// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { QueryClient, QueryClientProvider } from "react-query";
// import { store } from './store'
// import { Provider } from "react-redux";
// import "./style/accountStyle.scss";
// import '@zimyo/design-system/lib/global.css'
// // Create a client
// const queryClient = new QueryClient();

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <Provider store={store}>
//     <QueryClientProvider client={queryClient}>
//       <App />
//     </QueryClientProvider>
//   </Provider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import Account from "./Account";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import AccountTheme from "./AccountTheme";
import "./style/accountStyle.scss";
import "./style/appNewStyles.scss";
import "./style/appStyles.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "@zimyo/engage/style.css"
import App from "./App";

const queryClient = new QueryClient();

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ThemeProvider theme={AccountTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
