import React from "react";
import "./NoDataFound.css";
import animationData from "./noData.json";
import Lottie from "react-lottie";
import NoDataComponent from "../NoDataComponent/NoDataComponent";

const NoDataFound = (props) =>  <NoDataComponent {...props} />

export default NoDataFound;


// const NoDataFound = ({ message, width = "100%", height = "100%" }) => {
//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData,
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice",
//     },
//   };

//   return (
//     <div>
//       <Lottie options={defaultOptions} height={height} width={width} />
//     </div>
//   );
// };

// export default NoDataFound;
