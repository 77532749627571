import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StickyTableCell = styled(TableCell)(
  ({
    theme,
    stickyColumnBodyStyles,
    stickyColumnHeadStyles,
  }) => ({
    [`&.${tableCellClasses.head}`]: {
      left: 0,
      position: "sticky",
      zIndex: 5,
      ...stickyColumnHeadStyles,
    },
    [`&.${tableCellClasses.body}`]: {
      left: 0,
      position: "sticky",
      ...stickyColumnBodyStyles,
    },
    [`&.${tableCellClasses.root}`]: {
      border: "none",
    },
  })
);

export default StickyTableCell;
