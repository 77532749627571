import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { debounce } from "lodash";
import { BsSearch } from "react-icons/bs";
import { useIntl } from "react-intl";

const DebouncedSearchComponent = forwardRef(
  (
    { placeholder = "", defaultValue = "", onSearch = () => { }, delay = 1000, width = '210px', styleOverrides = {} },
    ref
  ) => {
    const [searchTerm, setSearchTerm] = useState(defaultValue);
    const hasInputChanged = useRef(false);
    const inputRef = useRef();
    const intl = useIntl();

    // Debounce the search function
    const debouncedSearch = debounce((value) => {
      onSearch(value);
    }, delay); // Adjust the delay as needed

    useImperativeHandle(ref, () => ({
      clearInput() {
        setSearchTerm("");

        // inputRef.current.value = "";
        // 
      },
    }));

    useEffect(() => {
      if (hasInputChanged.current) debouncedSearch(searchTerm);
      return () => {
        debouncedSearch.cancel();
      };
    }, [searchTerm]);

    const handleInputChange = (event) => {
      if (!hasInputChanged.current) hasInputChanged.current = true;
      setSearchTerm(event.target.value);
    };

    return (
      <div
        className="flex-start-center search-component m-0"
        style={{
          background: "#ffffff",
          border: "1.5px solid #f5f5f5",
          borderRadius: "4px",
          width: width,
          ...styleOverrides,
        }}
      >
        <BsSearch style={{ width: 14, height: 14 }} />
        <input
          type="text"
          placeholder={placeholder || intl.formatMessage({ id: 'inputs.search', defaultMessage: "Search..." })}
          name="searchText"
          autoComplete="off"
          value={searchTerm}
          ref={inputRef}
          onChange={handleInputChange}
          style={{ margin: "0 !important" }}
          className="input-search"
        />
      </div>
    );
  }
);
export default DebouncedSearchComponent;
