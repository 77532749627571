import moment from "moment";
import Calendermonth from "./CalendarYear";
import FinancialCalendar from "./FinancialCalendar";
import TextReducingTooltipComponent from "../components/TextReducingTooltipComponent";

export function getFormattedCyclePeriodicities(
  startDate,
  cycleType,
  OKR_PERFORMANCE_YEAR,
  CycleYear,
  CycleID
) {
  let firstYear = '';
  let lastYear = '';
  let selectedAppraisalCycle = CycleYear?.filter(year => Number(year.ID) === Number(CycleID))[0];
  // console.log(selectedAppraisalCycle,"selectedAppraisalCycle")
  let cyclename = selectedAppraisalCycle?.CYCLE_NAME;
  if (selectedAppraisalCycle?.CYCLE_NAME?.split('').includes("-")) {
    firstYear = selectedAppraisalCycle?.CYCLE_NAME?.split("-")[0];
    lastYear = selectedAppraisalCycle?.CYCLE_NAME?.split("-")[1];
    cyclename = selectedAppraisalCycle?.CYCLE_NAME + " " + "(FY1)"
  }

  var check = moment(startDate, "YYYY/MM/DD");
  var month = check.format("MMM");
  var month_digit = check.format("MM");
  var day = check.format("DD");
  var year = Number(check.format("YYYY"));
  let data = [];
  const Calender =
    selectedAppraisalCycle?.CYCLE_NAME?.split('').includes("-")
      ? FinancialCalendar
      : Calendermonth

  const copyArray = JSON.parse(JSON.stringify([...Calender[cycleType]]));
  copyArray.forEach((value, index) => {
    if (cycleType === 5) {
      value["cycle"] = cyclename;
    }
    value["lastYear"] = value["isCycleYearChange"] === "both" ? year + 1 : year;
    value["start_date"] =
      value["lastYear"] + "-" + value["startMonth"] + "-" + value["startDay"];
    value["end_date"] =
      value["isCycleYearChange"] === "last"
        ? year + 1
        : value["lastYear"] + "-" + value["lastMonth"] + "-" + value["lastDay"];
    if (value.hasOwnProperty("Feb")) {
      const totalDays = moment(year + "-02", "YYYY-MM").daysInMonth();
      if (totalDays === 28) {
        value["Feb"] = totalDays;
        let FYear =
          value["isCycleYearChange"] === "last" ? year + 1 : value["lastYear"];
        let FDay = value["lastMonth"] == "02" ? totalDays : value["lastDay"];
        value["end_date"] = FYear + "-" + value["lastMonth"] + "-" + FDay;
      }
    }
    if (value.hasOwnProperty(month)) {
      value["totalCount"] = value["totalCount"] - (day - 1);
      value["startDay"] = day;
      value["startMonth"] = month_digit;
      value["start_date"] = value["lastYear"] + "-" + month_digit + "-" + day;
      data = copyArray.slice(index);
    }
  });
  return data;
}

export function CountDays(startDate, endDate) {
  let date1 = new Date(startDate);
  let date2 = new Date(endDate);

  let Difference_In_Time = date2.getTime() - date1.getTime();

  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Difference_In_Days + 1;
}

export function ConfigurationCycle(org_settings) {
  let settingList = org_settings;
  let settings = settingList;
  let current_year1 = Number(moment().year()) + 1;
  let current_year = moment().year();
  let previous_year1 = Number(moment().year()) - 1;
  let previous_year2 = Number(moment().year()) - 2
  if (settings?.["OKR_PERFORMANCE_YEAR"] == 1) {
    current_year1 = moment().year() + 1;
    current_year = moment().year();
    previous_year1 = Number(moment().year()) - 1;
    previous_year2 = Number(moment().year()) - 2
  } else {
    let financial_year1, financial_year2, financial_year3, financial_year4;

    financial_year1 =
      (Number(moment().year()) + 1) + "-" + (Number(moment().year()) + 2);

    financial_year2 =
      Number(moment().year()) + "-" + (Number(moment().year()) + 1);

    financial_year3 =
      (Number(moment().year()) - 1) + "-" + Number(moment().year());

    financial_year4 =
      (Number(moment().year()) - 2) + "-" + (Number(moment().year()) - 1);
    current_year1 = financial_year1;
    current_year = financial_year2;
    previous_year1 = financial_year3;
    previous_year2 = financial_year4

    // let explode = current_year.split("-");
    // previous_year = explode[0] - 1 + "-" + explode[0];
  }
  return { current_year, current_year1, previous_year1, previous_year2 };

}

// function for review Appraisal Year
export function ReviewConfigurationCycle(org_setting) {
  let settingList = org_setting;
  let settings = settingList;
  let current_year1 = Number(moment().year()) + 1;
  let current_year = moment().year();
  let previous_year1 = Number(moment().year()) - 1;
  let previous_year2 = Number(moment().year()) - 2
  let previous_year3 = Number(moment().year()) - 3;
  let previous_year4 = Number(moment().year()) - 4
  if (settings?.["OKR_PERFORMANCE_YEAR"] == 1) {
    current_year1 = moment().year() + 1;
    current_year = moment().year();
    previous_year1 = Number(moment().year()) - 1;
    previous_year2 = Number(moment().year()) - 2;
    previous_year3 = Number(moment().year()) - 3;
    previous_year4 = Number(moment().year()) - 4
  } else {
    let financial_year1, financial_year2, financial_year3, financial_year4, financial_year5, financial_year6;

    financial_year1 =
      (Number(moment().year()) + 1) + "-" + (Number(moment().year()) + 2);

    financial_year2 =
      Number(moment().year()) + "-" + (Number(moment().year()) + 1);

    financial_year3 =
      (Number(moment().year()) - 1) + "-" + Number(moment().year());

    financial_year4 =
      (Number(moment().year()) - 2) + "-" + (Number(moment().year()) - 1);
    financial_year5 =
      (Number(moment().year()) - 3) + "-" + (Number(moment().year()) - 2);

    financial_year6 =
      (Number(moment().year()) - 4) + "-" + (Number(moment().year()) - 3);
    current_year1 = financial_year1;
    current_year = financial_year2;
    previous_year1 = financial_year3;
    previous_year2 = financial_year4
    previous_year3 = financial_year5;
    previous_year4 = financial_year6

    // let explode = current_year.split("-");
    // previous_year = explode[0] - 1 + "-" + explode[0];
  }
  return { current_year, current_year1, previous_year1, previous_year2, previous_year3, previous_year4 };
}

export function limitStringChar(text, count, insertDots) {
  return (
    // text?.slice(0, count) + (text?.length > count && insertDots ? "..." : "")
    <TextReducingTooltipComponent data={text} limit={count}
      styleOverrides={{
        border: 'initial',
        color: 'initial',
        padding: '0px'
      }}
    />
  );
}

// funtion will return today date in DD-MM-YYYY format
export default function getTodayDate(formate) {
  return new Date();
}


export function getModuleName(cycleType) {
  if (cycleType === 2) return "M";
  else if (cycleType === 3) return "Q";
  else if (cycleType === 4) return "H";
  else return "Y"
}

export const flattenMessages = ((nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {}
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value })
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
})


export function getDecimalValue(value, size) {
  if (value && !isNaN(value)) {
    return value.toFixed(size);
  }
  return "N/A";
}

export const clearCookies = () => {
  const locale = localStorage.getItem("locale") || "en";
  localStorage.clear();
  console.log(">>>>>",locale)
  localStorage.setItem("locale", locale);
}