import axios from "axios";
import config from "./configFile.json";

const CreateConnection = async (values) => {
  const {
    method = "get",
    data = {},
    headers = {},
    nextUrl = "",
    demoUrl = false,
    responseType = "json",
    params = {},
  } = values;
  try {
    const result = await axios({
      method,
      headers: headers.hasOwnProperty("Content-Type")
        ? {
            ...headers,
            "Access-Control-Allow-Origin": `${process.env.REACT_APP_SERVER_URL}`,
          }
        : {
            ...headers,
            "content-type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": `${process.env.REACT_APP_SERVER_URL}`,
          },
      url: `${process.env.REACT_APP_SERVER_URL}${
        demoUrl ? `apiv1/${nextUrl}` : nextUrl
      }`,
      data,
      params,
      responseType,
      timeout: 10000 * 10,
    });
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export default CreateConnection;