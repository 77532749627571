import {
  ADMIN_AUTHENTICATE_USER,
  LOGIN_ADMIN_USER,
  ADMIN_LOGOUT_USER,
  SET_PASSWORD,
  RESEND_MAIL,
  RESET_PASSWORD,
  REGISTER_USER,
  FORGOT_PASSWORD,
  GET_USER_DETAILS,
  UPDATE_USER_DETAILS,
  SHOW_GYDE,
  PERMANENT_SHOW_GYDE_OFF,
  CONNECT_ADMIN_SOCKET,
  DISCONNECT_ADMIN_SOCKET,
  CHECK_DOMAIN_VERIFIED,
  GET_REDIRECT,
  UPDATE_TOUR_FLAG,
  ON_REFRESH,
} from "./constant";

export const authenticateUser = (payload) => ({
  type: ADMIN_AUTHENTICATE_USER,
  payload,
});

export const loginUser = (payload) => ({ type: LOGIN_ADMIN_USER, payload });
export const logoutUser = (payload) => ({ type: ADMIN_LOGOUT_USER, payload });
export const registerUser = (payload) => ({ type: REGISTER_USER, payload });
export const setPassword = (payload) => ({ type: SET_PASSWORD, payload });
export const resetPassword = (payload) => ({ type: RESET_PASSWORD, payload });
export const resendMail = (payload) => ({ type: RESEND_MAIL, payload });
export const forgotPassword = (payload) => ({ type: FORGOT_PASSWORD, payload });
export const getUserDetails = (payload) => ({
  type: GET_USER_DETAILS,
  payload,
});
export const connectSocket = (payload) => ({
  type: CONNECT_ADMIN_SOCKET,
  payload,
});

export const disconnectSocket = (payload) => ({
  type: DISCONNECT_ADMIN_SOCKET,
  payload,
});
export const updateUserDetails = (payload) => ({
  type: UPDATE_USER_DETAILS,
  payload,
});
export const showGydeFun = (payload) => ({
  type: SHOW_GYDE,
  payload,
});

export const permanentOffGyde = (payload) => ({
  type: PERMANENT_SHOW_GYDE_OFF,
  payload,
});

export const checkDomainVerified = (payload) => ({
  type: CHECK_DOMAIN_VERIFIED,
  payload,
});

export const redirection = (payload) => ({
  type: GET_REDIRECT,
  payload,
});
export const updateTourFlag = (payload) => ({
  type: UPDATE_TOUR_FLAG,
  payload,
});

export const onRefresh = (payload) => ({
  type: ON_REFRESH,
  payload,
});

