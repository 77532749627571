import { Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import NestedMenuItem from "material-ui-nested-menu-item";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import { getConfigLabels } from "../../utitlies/HelpersFunction";
import CustomPopover from "../CustomPopover/CustomPopover";
import { Link } from "react-router-dom";
import { convertArrayToObjectWithKey } from "../utitlies/commonFunction";
import groupBy from "lodash/groupBy";
import { useIntl } from 'react-intl';

const defaultConfigLabelMenuSlugs = {
  ORG_KRAS: "",
  ORG_KPIS: "",
  ORG_COMPETENCIES: "",
  ORG_POTENTIAL: "",
};

export const NestedMenu = ({ open,
  setOpen = () => { },
  anchorEl,
  nestedArr = [],
  secondaryColor = "",
  lightBackground,
  popoverEnter = () => { },
  popoverLeave = () => { },
  menu_access_mapping_parent_id = {}, }) => {

  const intl = useIntl();

  const [menuPosition, setMenuPosition] = useState(null);
  const { menu_access = [], user = {} } = useSelector(
    (state) => state.adminUserReducer,
    isEqual
  );
  const history = useNavigate();
  const [nestedMenu, setNestedMenu] = useState({});
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [popoverMenu, setPopoverMenu] = useState([]);



  useEffect(() => {
    let arr = [],
      brr = {};
    for (let i = 0; i < nestedArr.length; i++) {
      arr = [];
      for (let j = 0; j < menu_access.length; j++) {
        if (nestedArr[i].id === menu_access[j].parent_id) {
          arr.push(menu_access[j]);
        }
      }
      let key = nestedArr[i].id;
      if (arr.length) {
        brr[key] = arr;
      }
    }
    setNestedMenu(brr);
  }, [nestedArr]);

  useEffect(() => {
    if (!isEmpty(anchorEl)) {
      setMenuPosition({
        left: 100,
      });
    }
  }, [anchorEl]);

  const handleItemClick = (event, item, val) => {
    setOpen(false);

    // Define a function to run the desired code
    const runAfterOpen = () => {
      let childrenmenu = menu_access_mapping_parent_id?.[item?.id];
      if (item?.url && item?.url?.length) {
        if (val === "child") {
          childrenmenu?.length && history(`/${childrenmenu[0].url}`);
        } else {
          history(`/${item.url}`);
        }
      } else {
        if (childrenmenu && childrenmenu.length) {
          history(`/${childrenmenu[0].url}`);
        } else {
          history("/");
        }
      }
    };

    // DO NOT REMOVE THIS> IT WILL CAUSE LAG ON MENU
    // Use setTimeout to run the function after a brief delay (0 milliseconds)
    setTimeout(runAfterOpen, 10);
  };

  const getItemName = (item) => {
    if (
      item?.menu_slug &&
      Object.keys(defaultConfigLabelMenuSlugs).includes(item?.menu_slug)
    ) {
      let {
        KRA_LABEL,
        KPI_LABEL,
        REVIEW,
        OBJECTIVE,
        COMPETENCY,
        POTENTIALS,
        KEY_RESULT_LABEL,
      } = getConfigLabels();

      const configLabelMenuSlugs = {
        ORG_KRAS: KRA_LABEL,
        ORG_KPIS: KPI_LABEL,
        ORG_COMPETENCIES: COMPETENCY,
        ORG_POTENTIAL: POTENTIALS,
      };
      return configLabelMenuSlugs?.[item?.menu_slug] || item?.name;
    }
    return intl.formatMessage({ id: `navbar.${item?.name}`, defaultMessage: item?.name });
  };

  const handleContextMenu = (e, item) => {
    console.log("handle context menu called");
    e.preventDefault();
    // e.stopPropagation();
    setIsPopoverOpen(true);
    setAnchorPosition({ top: e.pageY, left: e.pageX });
    setPopoverMenu([
      {
        title: "Open in New Tab",
        onClick: () => {
          alert("item clicked");
          console.log({ item });
        },
      },
    ]);
  };

  const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: "none",
    },
    popoverContent: {
      pointerEvents: "auto",
    },
  }));
  const classes = useStyles();

  return (
    <>
      {open && (
        <div className="custom-menu">
          <Menu
            open={open}
            className={`${classes.popover} menu-list`}
            classes={{
              paper: classes.popoverContent,
            }}
            onClose={(e) => popoverLeave(e)}
            anchorEl={anchorEl}
            PaperProps={{
              onMouseEnter: popoverEnter,
              onMouseLeave: popoverLeave,
              style: {
                background: secondaryColor || "#172B4D",
              },
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {nestedArr?.map((item, index) => {
              if (nestedMenu?.hasOwnProperty(item.id)) {
                return (
                  <NestedMenuItem
                    label={intl.formatMessage({ id: `navbar.${item.name}`, defaultMessage: item.name })}
                    parentMenuOpen={menuPosition}
                    onClick={(e) => handleItemClick(e, item, "child")}
                    onKeyDown={(e) => e.stopPropagation()}
                    // ContainerComponent={  <Link to={item.url} />}
                    key={index}
                    className="nested"
                    style={{
                      color: !lightBackground ? "#FFF" : "rgba(35, 31, 32, 1)",
                      "&:hover": {
                        backgroundColor: secondaryColor,
                        borderRadius: "3%",
                        color: lightBackground ? "black" : "white",
                      },
                    }}
                    PaperProps={{
                      style: {
                        background: secondaryColor || "#172B4D",
                      },
                    }}
                  >
                    {nestedMenu[item.id].map((obj, index) => {
                      return (
                        <MenuItem
                          PaperProps={{
                            style: {
                              background: secondaryColor || "#172B4D",
                            },
                          }}
                          // ContainerComponent={  <Link to={item.url} />}
                          className="menus"
                          onClick={(e) => {
                            handleItemClick(e, obj, "children");
                          }}
                          key={index}
                        >
                          {getItemName(obj)}
                        </MenuItem>
                      );
                    })}
                  </NestedMenuItem>
                );
              } else {
                return (
                  <MenuItem
                    className="menu1"
                    style={{
                      color: !lightBackground ? "#FFF" : "rgba(35, 31, 32, 1)",
                      "&:hover": {
                        backgroundColor: secondaryColor,
                        borderRadius: "3%",
                        color: lightBackground ? "black" : "white",
                      },
                    }}
                    // ContainerComponent={  <Link to={item.url} />}
                    key={index}
                    onClick={(e) => handleItemClick(e, item, "parent")}
                  // onContextMenu={(e) => handleContextMenu(e, item, "parent")}
                  >
                    {getItemName(item)}
                  </MenuItem>
                );
              }
            })}
          </Menu>
        </div>
      )}

      <CustomPopover
        isPopoverOpen={isPopoverOpen && popoverMenu?.length > 0}
        anchorPosition={anchorPosition}
        onClose={() => setIsPopoverOpen(false)}
        PopoverMenu={popoverMenu}
      />
    </>
  );
};
