import axios from "axios";
import apiPath from "../../../config/apiPath.json";
const BASE_URL = process.env.REACT_APP_SERVER_URL;

export const getAssessmentConfig = async ({ token, org_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_ASSESSMENT}?org_id=${org_id}`,
    { headers: { token } },
    {}
  );
};
export const updateAssessmentConfig = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.UPDATE_ASSESSMENT}`, data, {
    headers: { token },
  });
};
export const addUpdateCompensation = async ({ token, id, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.ORG_COMPENSATION}/${id ? id : ""}`,
    data,
    { headers: { token } }
  );
};
export const getRatingScales = async ({ token, org_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.RATING_SCALES}?org_id=${org_id}`,
    { headers: { token } },
    {}
  );
};
export const getOrgCompensation = async ({ token, org_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.ORG_COMPENSATION}?org_id=${org_id}`,
    { headers: { token } },
    {}
  );
};
export const getCycleList = async ({ token }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.CYCLE_LIST}?cycle_type=2&cycle_list_type=2`,
    { headers: { token } },
    {}
  );
};
export const getOrgEmpDetails = async ({ token }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.ORG_EMPLOYEE_DETAILS}`,
    { headers: { token } },
    {}
  );
};
export const getOrgDepartmentList = async ({ token }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_DEPARTMENT_LIST}`,
    { headers: { token } },
    {}
  );
};
export const getOrgSubDepartmentList = async ({ token }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_SUB_DEPARTMENT_LIST}`,
    { headers: { token } },
    {}
  );
};
export const getOrgDesignationList = async ({ token }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_DESIGNATION_LIST}`,
    { headers: { token } },
    {}
  );
};
export const getOrgLocationList = async ({ token }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_LOCATION_LIST}`,
    { headers: { token } },
    {}
  );
};
export const getNineBoxScoring = async ({ token, org_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.NINE_BOX_SCORING}?ORG_ID=${org_id}`,
    { headers: { token } },
    {}
  );
};
export const updateNineBoxScoring = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.NINE_BOX_SCORING_UPDATE}`,
    data,
    {
      headers: { token },
    }
  );
};
export const updateNineBoxConfig = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.NINE_BOX_CONFIG_UPDATE}`,
    data,
    {
      headers: { token },
    }
  );
};
export const getNineBoxConfig = async ({ token, org_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.NINE_BOX_CONFIG}?ORG_ID=${org_id}`,
    { headers: { token } },
    {}
  );
};
export const createBulkStructure = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.BULK_STRUCTURE_CREATE}`, data, {
    headers: { token },
  });
};

export const deleteSingleStructure = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.DELETE_STRUCTURE}`, data, {
    headers: { token },
  });
};
export const getStructureList = async ({
  token,
  user_id,
  start,
  limit,
  initData,
}) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_STRUCTURE_LIST}?user_id=${user_id}&` +
      `order_by=${initData.order_by}&start=${start}&limit=${limit}&search=${initData.search}&filterType=${initData.filterType}&showWithApID=${initData.showWithApID}`,
    { headers: { token } },
    {}
  );
};

export const sampleExportStructure = async ({ token, data = {} }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.SAMPLE_EXPORT_STRUCTURE}`,
    data,
    { headers: { token } }
  );
};

export const getQuickAppraisalHistory = async ({
  token,
  user_id,
  org_id,
  data = {},
}) => {
  return await axios.post(
    `${BASE_URL}${apiPath.QUICK_APPRAISAL_HISTORY}?user_id=${user_id}&org_id=${org_id}`,
    data,
    { headers: { token } }
  );
};

export const quickAppraisalBulkUpload = async ({ token, data = {} }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.QUICK_APPRAISAL_BULK_UPLOAD}`,
    data,
    { headers: { token } }
  );
};

export const sampleDownloadQuickAppraisalHistory = async ({
  token,
  data = {},
}) => {
  return await axios.post(
    `${BASE_URL}${apiPath.QUICK_APPRAISAL_SAMPLE_DOWNLOAD}`,
    data,
    { headers: { token } }
  );
};

export const deleteSinglePotential = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.POTENTIAL_DELETE_SECTION}`,
    data,
    { headers: { token } }
  );
};

export const potentialBulkSection = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.POTENTIAL_BULK_SECTION}`,
    data,
    { headers: { token } }
  );
};

export const getPotentialSectionList = async ({
  token,
  org_id,
  start = "",
  limit = "",
  initData = { search: "" },
}) => {
  return await axios.get(
    `${BASE_URL}${apiPath.POTENTIAL_SECTION_LIST}?org_id=${org_id}&start=${start}&limit=${limit}&search=${initData.search}&order_by=id`,
    { headers: { token } },
    {}
  );
};

export const getCompetencyList = async ({
  token,
  org_id,
  start = "",
  limit = "",
  initData = {
    search: "",
  },
}) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_COMPENTENCY_LIST}?org_id=${org_id}&start=${start}&limit=${limit}&search=${initData.search}&order_by=id`,
    { headers: { token } },
    {}
  );
};

export const deleteSingleCompetency = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.DELETE_COMPETENCY}`, data, {
    headers: { token },
  });
};

export const competencyBulkSection = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.BULK_COMPETENCY_SECTION}`,
    data,
    { headers: { token } }
  );
};

export const updateEmployeeDesignation = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPDATE_EMPLOYEE_DESIGNATION}`,
    data,
    { headers: { token } }
  );
};

export const updateReviewNotifications = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPDATE_REVIEW_NOTIFICATIONS}`,
    data,
    { headers: { token } }
  );
};

export const getMailTemplate = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.GET_MAIL_TEMPLATE}`, data, {
    headers: { token },
  });
};

export const saveMailTemplate = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.SAVE_MAIL_TEMPLATE}`, data, {
    headers: { token },
  });
};

export const getNotifications = async ({ token, org_id, type = 1 }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_NOTIFICATIONS}?org_id=${org_id}&type=${type}`,
    { headers: { token } },
    {}
  );
};

export const evaluationCycleConfig = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.QUICK_APPRAISAL_POST}`, data, {
    headers: { token },
  });
};

export const getAppraisalCycles = async ({ token, org_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_APPRAISAL_CYCLE}?org_id=${org_id}&order_by=ID&cycle_type=1&status=1`,
    { headers: { token } },
    {}
  );
};

export const getSearchEmployees = async ({ token, search }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_SEARCH_EMPLOYEE}?search_for=all&search=${search}`,
    { headers: { token } },
    {}
  );
};

export const getPayrollFields = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.GET_PAYROLL_FIELDS}`, data, {
    headers: { token },
  });
};

export const reviewVisibilty = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.REVIEW_VISIBILITY}`, data, {
    headers: { token },
  });
};

export const untagObjectiveCycle = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.UNTAG_OBJ_CYCLE}`, data, {
    headers: { token },
  });
};

export const untagReviewCycle = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.UNTAG_REVIEW_CYCLE}`, data, {
    headers: { token },
  });
};

export const getCycleEmployees = async ({
  token,
  cycle_id,
  page,
  limit,
  search,
}) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_CYCLE_EMPLOYEES}?cycle_id=${cycle_id}&page=${page}&limit=${limit}&search=${search}`,
    { headers: { token } },
    {}
  );
};

export const getEmployeeSectionList = async ({ token }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.EMPLOYEE_SECTION_LIST}`,
    { headers: { token } },
    {}
  );
};

export const getEmpDetails = async ({ token, emp_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.EMP_DETAILS}?employee_id=${emp_id}`,
    { headers: { token } },
    {}
  );
};

export const getTemplatesInBulk = async ({ token, search, page, limit }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.EMAIL_TEMPLATES_IN_BULK}?search=${search}&page=${page}&limit=${limit}`,
    { headers: { token } },
    {}
  );
};
