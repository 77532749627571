import React from "react";
import Slider from "../components/Slider";
import Grid from "@material-ui/core/Grid";
import LeftImage from '../assests/svg/LeftImage.svg';
import RocketImage from '../assests/svg/Rocket.svg';
import TwocolorImage from '../assests/svg/Twocolor.svg';
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
const GuestLayout = ({ component: Component, }) => {
  const location = window.location;

  const intl = useIntl();
  const sliderContent = ["hello", "email-verification", "register"].includes(
    location.pathname.replace("/", "").split("/")[0]
  )
    ? [
      {
        imageIcon: LeftImage,
        title: intl.formatMessage({ id: "login.img1Text", defaultMessage: "Best Employee Experience Platform" }),
        subTitle: intl.formatMessage({ id: "login.img1SubText", defaultMessage: "Don’t limit yourself. Many people limit themselves to what they think they can do. You can go as far as your mind lets you. You achieve what you believe." }),
      },
      {
        imageIcon: RocketImage,
        title: intl.formatMessage({ id: "login.img2Text", defaultMessage: "Finally a place where it all comes together" }),
        subTitle: intl.formatMessage({ id: "login.img2SubText", defaultMessage: "Employees like you can even make Mondays a joy. Thanks for your hard work and super attitude." }),
      },
      {
        imageIcon: TwocolorImage,
        title: intl.formatMessage({ id: "login.img3Text", defaultMessage: "Focus on the work that matters" }),
        subTitle: intl.formatMessage({ id: "login.img3SubText", defaultMessage: "Business opportunities are like buses, there’s always another one coming." }),
      },
    ]
    : [
      {
        imageIcon: LeftImage,
        title: intl.formatMessage({ id: "login.img1Text", defaultMessage: "Best Employee Experience Platform" }),
        subTitle: intl.formatMessage({ id: "login.img1SubText", defaultMessage: "Don’t limit yourself. Many people limit themselves to what they think they can do. You can go as far as your mind lets you. You achieve what you believe." }),
      },
      {
        imageIcon: RocketImage,
        title: intl.formatMessage({ id: "login.img2Text", defaultMessage: "Finally a place where it all comes together" }),
        subTitle: intl.formatMessage({ id: "login.img2SubText", defaultMessage: "Employees like you can even make Mondays a joy. Thanks for your hard work and super attitude." }),
      },
      {
        imageIcon: TwocolorImage,
        title: intl.formatMessage({ id: "login.img3Text", defaultMessage: "Focus on the work that matters" }),
        subTitle: intl.formatMessage({ id: "login.img3SubText", defaultMessage: "Business opportunities are like buses, there’s always another one coming." }),
      },
    ];
  return (
    <Box className='w-100'>
      <Grid container spacing={0} className="start-flex wh-vw-vh">
        {location.pathname.split('/').includes('review-details') ? <Grid item xs={12} style={{ width: '100%', margin: 'auto' }} className="wh-100">
          <Component location={location} />
        </Grid> : <>{(window.location &&
          window.location.pathname &&
          window.location.pathname.includes("offer_letter")) ||
          window.location.pathname.includes("appointment_letter") ||
          window.location.pathname.includes("joining") ||
          window.location.pathname.includes("joiningsuccess") ||
          window.location.pathname.includes("googlemap") ||
          window.location.pathname.includes("alreadyregistered") ||
          window.location.pathname.includes("acknowledgement") ? null : (
          <Grid item md={8} xs={12} className="b-1 wh-100" display={{ xs: "none", lg: "block", md: "block" }}>
            <Slider sliderContent={sliderContent} />
          </Grid>
        )}
          <Grid item md={4} xs={12} style={{ width: '100%', margin: 'auto' }} className="wh-100">
            <Component location={location} />
          </Grid></>}
      </Grid>
    </Box>

  );
};

export default GuestLayout;
