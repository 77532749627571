import React, { useMemo } from "react";
import "../style/Overlay.css";
import { TailSpin } from "react-loader-spinner";
// import { Skeleton } from "antd";
import { CircularProgress } from "@material-ui/core";
import { Backdrop, Skeleton } from "@mui/material";



const Loader = ({ visible, loaderType = "component" }) => {
  const isVisible = useMemo(() => {
    return visible
  }, [visible])

  let LoaderTypeComponent = () => {
    const LoaderComponent = {
      page: (
        <TailSpin
          height={80}
          width={80}
          wrapperStyle
          visible={isVisible}
          ariaLabel="loading-indicator"
          strokeWidthSecondary={1}
          strokeWidth={5}
          color="#1890ff"
          wrapperClass="overlay"
        />
      ),
      component: (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999999 }}
          open={isVisible}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ),
      customTableLoader: (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999999 }}
          open={isVisible}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ),
      skeleton: <Skeleton loading={isVisible} active />,
    };
    return LoaderComponent[loaderType];
  };

  return (
    // <Audio
    //   height="80"
    //   width="80"
    //   radius="9"
    //   color="green"
    //   ariaLabel="three-dots-loading"
    //   wrapperStyle
    //   wrapperClass="overlay"
    //   isVisible={isVisible}
    // />

    <LoaderTypeComponent />
  );
};

export default Loader;
