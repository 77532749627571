import axios from 'axios';
import apipath from './../config/apiPath.json'
const baseURL = process.env.REACT_APP_SERVER_URL;
const hrmsUrl = process.env.REACT_APP_HRMS_API_URL;
const token = localStorage.getItem("token");

// export const fetchPeopleInprocessList = async ({ token, data }) => {
//     return await axios.post(`${baseURL}performance/employee/filter_employee_data`,data, { headers: { token } });
// };
export const fetchPeopleInprocessList = async ({ token, data }) => {
    return await axios.post(`${baseURL}common/employee/list-employees`, data, { headers: { token } });
};

export const fetchCyclesTaggedToEmp = async ({ token, params }) => {
    return await axios.get(`${baseURL}common/employee/emp-cycle-list`, { headers: { token }, params });
};


export const fetchSearchFieldOptions = async ({ token, isFilter = 0, allMasterType = 0 }) => {
    return await axios.post(`${baseURL}${apipath.GET_SEARCH_FIELD_OPTIONS}?formid=12&only_active=1&checkaccess=1&allvalues=1`, { "is_filter": isFilter, "allvalues": 1, "formid": 12, AllMasterType: allMasterType }, { headers: { token } });
};
export const fetchRemoveEmployeeMatrix = async ({ initData }) => {
    return await axios.post(`${baseURL}${apipath.GET_REMOVE_EMPLOYEE_MATRIX}`, { ...initData }, { headers: { token } });
};
export const fetchSearchEmployee = async ({ initData }) => {
    return await axios.post(`${baseURL}${apipath.GET_SEARCH_EMPLOYEE}`, { ...initData }, { headers: { token } });
};
export const fetchUpdateApprovalMatrix = async ({ initData }) => {
    return await axios.post(`${baseURL}${apipath.GET_UPDATE_APPROVAL_MATRIX}`, { ...initData }, { headers: { token } });
};
export const fetchUpdateEmployeeTenure = async ({ initData }) => {
    return await axios.post(`${baseURL}${apipath.GET_UPDATE_EMPLOYEE_TENURE}`, { ...initData }, { headers: { token } });
};
export const fetchCompensationList = async ({ token, start, limit, data }) => {
    return await axios.get(`${baseURL}${apipath.GET_COMPENSATION_EMPLOYEE_LIST}?start=${start}&limit=${limit}&search=${data}`, { headers: { token } });
}
export const fetchCompPendAppYear = async ({ token, userID }) => {
    return await axios.get(`${baseURL}${apipath.GET_APPRAISAL_CYCLE}?org_id=${userID}&order_by=ID&cycle_type=1`, { headers: { token } });
}
export const fetchCompPendCycList = async ({ token, userID, employeeID, year }) => {
    return await axios.get(`${baseURL}${apipath.GET_CYCLE_LIST}?&org_id=${userID}&cycle_type=1&employee_id=${employeeID}&cycle_year=${year}&is_mapped_only=0`, { headers: { token } });
}
export const fetchCompNameOption = async ({ token, userID }) => {
    return await axios.get(`${baseURL}${apipath.GET_COMPENSATION_OPTION_LIST}?org_id=${userID}&order_by='pending'`, { headers: { token } });
}
export const handleEmployeeCompensation = async ({ token, postdata }) => {
    return await axios.post(`${baseURL}${apipath.UPDATE_EMPLOYEE_COMPENSATION}`, { ...postdata }, { headers: { token } });
}
export const handleMoveToPromotion = async ({ token, postdata }) => {
    return await axios.post(`${baseURL}${apipath.MOVE_TO_PROMOTION}`, { ...postdata }, { headers: { token } });
}
export const fetchPromotionList = async ({ token, start, limit, data }) => {
    return await axios.get(`${baseURL}${apipath.GET_PROMOTION_EMPLOYEE_LIST}?start=${start}&limit=${limit}&search=${data}`, { headers: { token } });
}
export const fetchNewDesignationOption = async ({ token }) => {
    return await axios.get(`${baseURL}${apipath.GET_PROMOTION_NEW_DESIGNATION_LIST}`, { headers: { token } });
}
export const scheduleOneToOneMeeting = async ({ token, initialValues }) => {
    return await axios.post(`${baseURL}${apipath.SCHEDULE_ONE_TO_ONE_MEETING}`, { ...initialValues }, { headers: { token } });
}
export const createEmployeeFeedback = async ({ token, postdata }) => {
    return await axios.post(`${baseURL}${apipath.CREATE_FEEDBACK_FORM}`, postdata, { headers: { token } });
}

export const fetchEmployeeNameIdFeedback = async ({ token, employeeName }) => {
    return await axios.get(`${baseURL}${apipath.SEARCH_EMPLOYEE}?search=${employeeName}&&search_for=feedback`, { headers: { token } });
}
export const fetchFeedbackListData = async ({ token, filterType, ...rest }) => {
    return await axios.get(`${baseURL}${apipath.GET_FEEDBACK_LIST}list`,
        {
            headers: { token }, params: {
                module_type: 1,
                filter_type: filterType,
                ...rest
            }
        }
    );
}

export const fetchOneToOneMeetingList = async ({ token, SCHEDULE_WITH = '', SCHEDULE_BY = '', SEARCH_QUERY = "", MEETING_STATUS = '', MONTHLY_FILTER = 0, ...rest }) => {
    return await axios.post(`${baseURL}${apipath.GET_ONE_TO_ONE_MEETINGLIST}`, { "module_type": 1, SCHEDULE_WITH, SCHEDULE_BY, SEARCH_QUERY, MEETING_STATUS, MONTHLY_FILTER, ...rest }, { headers: { token } });
}
export const fetchOneToOneMeetingDetails = async ({ token, meetID, ...rest }) => {
    return await axios.get(`${baseURL}${apipath.GET_ONE_TO_ONE_MEETING_DETAILS}/${meetID}`, {
        headers: { token }, params: {
            WITH_REMARK: true,
            ...rest
        }
    });
}
export const updateMeetingStatus = async ({ token, MEETING_ID, MEETING_STATUS }) => {
    return await axios.post(`${baseURL}${apipath.UPDATE_ONE_TO_ONE_MEETING_STATUS}`, { MEETING_ID, MEETING_STATUS }, { headers: { token } });
}
export const fetchOneToOneRemarks = async ({ token, form }) => {
    return await axios.post(`${hrmsUrl}${apipath.CREATE_ONE_TO_ONE_REMARKS}`, form, { headers: { token, 'content-type': 'multipart/form-data' } });
}
export const rescheduleOneToOneMeeting = async ({ token, MEETING_ID, DATE, START_TIME, DURATION }) => {
    return await axios.post(`${baseURL}${apipath.RESCHEDULE_ONE_TO_ONE_MEETING}`, { MEETING_ID, DATE, START_TIME, DURATION }, { headers: { token } });
}

export const createOkr = async ({ token, OKR_NAME, DISCRIPTION }) => {
    return await axios.post(`${baseURL}${apipath.CREATE_OKR}`, { OKR_NAME, DISCRIPTION }, { headers: { token } });
}

export const getOkrList = async ({ token, page, perPage, search }) => {
    return await axios.get(`${baseURL}${apipath.GET_OKR_LIST}?page=${page}&limit=${perPage}&search=${search}`, { headers: { token } });
}

export const getOkrDetails = async ({ token, okrId }) => {
    return await axios.get(`${baseURL}${apipath.GET_OKR_DETAILS}/${okrId}`, { headers: { token } });
}

export const updateOkrDetails = async ({ token, okrId, STATUS, OKR_NAME, DISCRIPTION }) => {
    return await axios.patch(`${baseURL}${apipath.UPDATE_OKR}`, { OKR_ID: okrId, STATUS, OKR_NAME, DISCRIPTION }, { headers: { token } });
}

export const deleteOkrDetails = async ({ token, okrId }) => {
    return await axios.delete(`${baseURL}${apipath.DELETE_OKR}`, { data: { OKR_ID: okrId }, headers: { token } });
}

export const deleteEmployeeAchievement = async ({ token, achievement_id, cycle_id, str_id }) => {
    return await axios.delete(`${baseURL}${apipath.DELETE_EMPLOYEE_ACHIEVEMENT}`, { data: { achievement_id, cycle_id, str_id }, headers: { token } });
}