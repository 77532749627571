const quater = "Q";
const HalfYear = "H";
const Monthly =  "M";
const Yearly = "Y"
const yearName = new Date().getFullYear();

const Calendermonth = {
    3: [
      { Jan: 31, Feb: 29, Mar: 31,startDay:'01', startMonth:'01',lastDay:31,lastMonth:'03',totalCount: 91, cycle: `Jan-Mar (${quater}1)`, quarter:1},
      { Apr: 30, May: 31, Jun: 30,startDay:'01', startMonth:'04', lastDay:30,lastMonth:'06', totalCount: 91, cycle: `Apr-Jun (${quater}2)` , quarter:2},
      { july: 31, Aug: 31, Sep: 30,startDay:'01', startMonth:'07',lastDay:30,lastMonth:'09', totalCount: 92, cycle: `July-Sep (${quater}3)`, quarter:3},
      { Oct: 31, Nov: 30, Dec: 31, startDay:'01', startMonth:'10',lastDay:31,lastMonth:'12',totalCount: 92, cycle: `Oct-Dec (${quater}4)`, quarter:4 },
    ],
    4: [
      { Jan: 31, Feb: 29, Mar: 31, Apr: 30, May: 31, Jun: 30, startDay:'01', startMonth:'01',lastDay:30,lastMonth:'06',totalCount: 182, cycle: `Jan-Jun (${HalfYear}1)`, quarter:1},
      { july: 31, Aug: 31, Sep: 30, Oct: 31, Nov: 30, Dec: 31, startDay:'01', startMonth:'07',lastDay:31,lastMonth:'12',totalCount: 184, cycle: `Jul-Dec (${HalfYear}2)`, quarter:2},
    ],
    2: [
      {Jan: 31, startDay:'01', startMonth:'01',lastDay:31,lastMonth:'01',totalCount: 31, cycle: `Jan (${Monthly }1)` , quarter:1},
      {Feb: 29, startDay:'01', startMonth:'02',lastDay:29,lastMonth:'02',totalCount: 29, cycle: `Feb (${Monthly }2)`, quarter:2},
      {Mar: 31, startDay:'01', startMonth:'03',lastDay:31,lastMonth:'03',totalCount: 31, cycle: `Mar (${Monthly }3)`, quarter:3},
      {Apr: 30, startDay:'01', startMonth:'04',lastDay:30,lastMonth:'04',totalCount: 30, cycle: `Apr (${Monthly }4)`, quarter:4},
      {May: 31, startDay:'01', startMonth:'05',lastDay:31,lastMonth:'05',totalCount: 31, cycle: `May (${Monthly }5)` , quarter:5},
      {Jun: 30, startDay:'01', startMonth:'06',lastDay:30,lastMonth:'06',totalCount: 30, cycle: `Jun (${Monthly }6)`, quarter:6},
      {july: 31 ,startDay:'01', startMonth:'07',lastDay:31,lastMonth:'07',totalCount: 31, cycle: `Jul (${Monthly}7)`, quarter:7},
      {Aug: 31, startDay:'01', startMonth:'08',lastDay:31,lastMonth:'08',totalCount: 31, cycle: `Aug (${Monthly }8)`, quarter:8},
      {Sep: 30, startDay:'01', startMonth:'09',lastDay:30,lastMonth:'09',totalCount: 30, cycle: `Sep (${Monthly }9)`, qurter:9},
      {Oct: 31, startDay:'01', startMonth:'10',lastDay:31,lastMonth:'10',totalCount: 31, cycle: `Oct (${Monthly }10)`, quater:10},
      {Nov: 30, startDay:'01', startMonth:'11',lastDay:30,lastMonth:'11',totalCount: 30, cycle: `Nov (${Monthly }11)`, quarter:11},
      {Dec: 31, startDay:'01', startMonth:'12',lastDay:31,lastMonth:'12',totalCount: 31, cycle: `Dec (${Monthly }12)`, quarter:12}
    ],
    5:[
      {
        Jan: 31, Feb: 28, Mar: 31, Apr: 30, May: 31, Jun: 30,july: 31, Aug: 31, Sep: 30, Oct: 31, Nov: 30, Dec: 31,startDay:'01', startMonth:'01',lastDay:31,lastMonth:'12',totalCount: 365, cycle: `Yearly`, quarter:''
      }
    ]
  };

  export default Calendermonth;