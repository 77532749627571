import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(
  ({ theme, tableCellHeadStyle = {}, tableCellBodyStyle = {} }) => ({
    [`&.${tableCellClasses.head}`]: {
      ...tableCellHeadStyle,
    zIndex:2,
    },
    [`&.${tableCellClasses.body}`]: {
      ...tableCellBodyStyle,
    },
    [`&.${tableCellClasses.root}`]: { border: "none" ,  },
  })
);

export default StyledTableCell;

