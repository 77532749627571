export const ARABIC = {
    ar: {
        language: "Arabic",
        header: {
            beacon: {
                searchPlaceholder: "انقر هنا للبحث الذكي..."
            },

            popOver: {
                profile: "ملفي",
                logout: "تسجيل الخروج"
            }
        },


        login: {
            title: "تسجيل الدخول",
            img1Text: "أفضل منصة تجربة موظف",
            img1SubText: "لا تقتصر على نفسك. كثير من الناس يقتصرون على أنفسهم فيما يعتقدون أنهم يستطيعون القيام به. يمكنك الذهاب بقدر ما يسمح لك عقلك. تحقق ما تؤمن به.",
            img2Text: "أخيرًا مكان حيث يتحقق كل شيء",
            img2SubText: "حتى يمكن للموظفين مثلك جعل أيام الاثنين فرحة. شكرًا على جهدك الشاق وموقفك الرائع.",
            img3Text: "التركيز على العمل المهم",
            img3SubText: "فرص العمل مثل الحافلات، دائمًا هناك واحدة قادمة.",
            notRegistered: "لم يتم التسجيل بعد؟",
            signUp: "سجل",
            forgotPassword: "نسيت كلمة المرور؟",
            loginBtnText: "تسجيل الدخول",
            loginWithOkta: "تسجيل الدخول باستخدام Okta",
            loginWithSso: "تسجيل الدخول باستخدام SSO",
            emailLabel: "البريد الإلكتروني أو رقم الهاتف المحمول",
            passwordLabel: "كلمة المرور"
        },

        inputs: {
            search: "بحث...",

            placeholder: {
                searchByEmployee: "البحث حسب الموظف"
            },

            labels: {
                appraisalPercentage: "نسبة التقييم",
                applicableDate: "تاريخ السريان",
                incentiveAmount: "مبلغ الحوافز",
                normalizedRating: "التقييم المعياري",
                year: "السنة",
                cycle: "الدورة",
                periodicity: "الانتظام"
            }
        },

        modals: {
            titles: {
                appraisalPercentage: "نسبة التقييم",
                incentiveAmount: "مبلغ الحوافز",
                initiateIncentive: "بدء الحوافز",
                normalizedRating: "التقييم المعياري"
            }
        },

        select: {

            labels: {
                selectEvaluationYear: "اختر عام التقييم",
                selectEvaluationCycle: "اختر دورة التقييم",
                selectCycleQuarter: "اختر ربع الدورة",
                chooseObjectiveCycle: "اختر دورة الهدف",
                choosePeriod: "اختر الفترة"
            },

            placeholder: {
                approvalStatus: "حالة الموافقة"
            },

            options: {
                all: "الكل",
                approved: "معتمد",
                pendingApproval: "قيد الانتظار للموافقة",
                pendingApprovalOnRM: "قيد الانتظار للموافقة على RM",
                rejected: "مرفوض",
                thisMonth: "هذا الشهر",
                lastMonth: "الشهر الماضي",
                thisQuarter: "هذا الربع",
                lastQuarter: "الربع الماضي",
                thisYear: "هذا العام",
                lastYear: "العام الماضي",
                upcoming: "القادمة",
                completed: "مكتملة",
                overdue: "متأخرة"
            }
        },

        buttons: {
            updateReviewers: "تحديث المراجعين",
            viewDetails: "عرض التفاصيل",
            appreciate: "تقدير",
            scheduleOneToOne: "جدولة واحد إلى واحد",
            giveFeedback: "إعطاء ملاحظات",
            bulkUploadGrade: "تحميل الدرجات بالجملة",
            cancel: "إلغاء",
            update: "تحديث",
            initiate: "بدء",
            createStructure: "إنشاء الهيكل",
            submit: "إرسال",
            createNewObjective: "إنشاء جديد"
        },

        table: {
            staticHeaders: {
                employeeCode: "رمز الموظف",
                employeeName: "اسم الموظف",
                employee: "موظف",
                cycle: "الدورة",
                evalCycle: "دورة التقييم",
                startDate: "تاريخ البدء",
                endDate: "تاريخ الانتهاء",
                cycleYear: "سنة الدورة",
                cycleQuarter: "ربع الدورة",
                structureName: "اسم الهيكل",
                action: "إجراء",
                employees: "الموظفين",
                finalRating: "التقييم النهائي",
                ratingYCoordinate: "إحداثيات التقييم Y",
                designation: "المسمى الوظيفي",
                department: "القسم",
                location: "الموقع",
                employeeEmail: "بريد الموظف",
                employeeCount: "عدد الموظفين",
                evaluationYear: "سنة التقييم",
                createdBy: "تم الإنشاء بواسطة",
                cycleName: "اسم الدورة",
                structureOwner: "مالك الهيكل",
                status: "الحالة",
                keyResult: "النتيجة الرئيسية",
                startTarget: "بدء الهدف",
                endTarget: "نهاية الهدف",
                checkinValue: "قيمة الفحص",
                checkinRemarks: "ملاحظات الفحص",
                objective: "الهدف",
                periodicity: "الدورية",
                appraisalYear: "سنة التقييم",
                reportingTo: "التقرير إلى",
                preNormalizedRating: "التقييم قبل التطبيع",
                grade: "الصف",
                appraisalPercentage: "نسبة التقييم",
                previousCtc: "الراتب السابق",
                increasedCtc: "الراتب المرتفع",
                incentiveAmount: "مبلغ الحوافز",
                applicableDate: "تاريخ السريان",
                previousDesignation: "التصميم السابق",
                newDesignation: "التصميم الجديد",
                objectiveWeightage: "وزن الهدف",
                employeeAcceptanceStatus: "حالة قبول الموظف",
                employeeApprovalRemarks: "ملاحظات موافقة الموظف"
            },
            dynamicHeaders: {
                EMPLOYEE_CODE: "كود الموظف",
                EMPLOYEE_NAME: "اسم الموظف",
                CYCLE_NAME: "اسم الدورة",
                STRUCTURE_NAME: "اسم الهيكل",
                H1_TOTAL_WTG: "الوزن الإجمالي لل H1",
                H1_ACHEIVED_WTG: "الوزن المحقق لل H1",
                H1_ACHEIVED_WTG_PERCENTAGE: "نسبة الوزن المحققة لل H1",
                H1_PERFORMANCE_RATING: "تقييم الأداء لـ H1",
                H1_COMPETENCY_RATING: "تقييم الكفاءة لـ H1",
                H1_POTENTIAL_RATING: "تقييم الإمكانات لـ H1",
                H1_WEIGHTED_PERFORMANCE_RATING: "تقييم الأداء الموزون لـ H1",
                H1_WEIGHTED_COMPETENCY_RATING: "تقييم الكفاءة الموزون لـ H1",
                H1_WEIGHTED_POTENTIAL_RATING: "تقييم الإمكانات الموزون لـ H1",
                H1_FINAL_RATING: "التقييم النهائي لـ H1"
            },

            actions: {
                compute: "حساب",
                view: "عرض",
                initiate: "بدء"
            },
        },

        tabs: {
            pending: "قيد الانتظار",
            completed: "مكتمل",
            expired: "منتهي الصلاحية",
            createStructure: "إنشاء الهيكل",
            received: "تلقى",
            given: "معطى",
            approved: "معتمد",
            rejected: "مرفوض"
        },

        titleOne: {
            titleOne: "1. تنزيل القالب",
            subTitleOne: "استخدم هذا النموذج مع الأعمدة المحددة مسبقًا، واملأ بياناتك.",
            downloadTemplate: "تنزيل القالب",
            titleTwo: "2. استيراد ورقة إكسل",
            subTitleTwo: "بمجرد ملء ورقة الإكسل، قم بتقديمها.",
            dragAndDrop: "اسحب وأسقط ملفك هنا أو",
            chooseFile: "اختيار الملف"
        },

        popConfirm: {
            areYouSure: "هل أنت متأكد؟",
            approveDesignation: "هل ترغب في الموافقة على التعيين الجديد",
            rejectDesignation: "هل ترغب في رفض التعيين الجديد",
            action: {
                cancel: "إلغاء",
                approve: "موافقة",
                reject: "رفض"
            }
        },

        objective: {
            organizationOKR: "هدف المنظمة",
            teamOKR: "هدف الفريق",
            myOKR: "هدفي"
        },


        compensation: {
            initiateEmployeeCompensation: "بدء تعويض الموظفين",
            popOver: {
                moveToNormalization: "الانتقال إلى التطبيع",
                moveToAppraisal: "الانتقال إلى التقييم",
                moveToIncentive: "الانتقال إلى الحوافز",
                moveToIncentiveAndAppraisal: "الانتقال إلى الحوافز والتقييم",
                updateGrade: "تحديث الدرجة",
                moveToAppraisal: "الانتقال إلى التقييم"
            },
            bulkUpload: {
                normalizationGradeLabel: "تحميل الدرجات المعيارية بالجملة"
            },
        },

        bulkUpload: {
            titleOne: "1. تنزيل القالب",
            subTitleOne: "استخدم هذا النموذج مع الأعمدة المحددة مسبقًا، واملأ بياناتك.",
            downloadTemplate: "تنزيل القالب",
            titleTwo: "2. استيراد ورقة إكسل",
            subTitleTwo: "بمجرد ملء ورقة الإكسل، قم بتقديمها.",
            dragAndDrop: "اسحب وأسقط ملفك هنا أو",
            chooseFile: "اختيار الملف"
        },

        navbar: {
            "Admin Dashboard": "لوحة تحكم المسؤول",
            "Dashboard": "لوحة تحكم ",
            "Review": "مراجعة",
            "Quick Actions": "إجراءات سريعة",
            "Analytics": "تحليلات",
            "Pipeline": "خط الأنابيب",
            "Rating Comparison": "مقارنة التقييم",
            "Bell Curve": "المنحنى الجرسي",
            "Objective": "هدف",
            "Feedback": "تغذية راجعة",
            "My Team": "فريقي",
            "Self": "الذات",
            "Team": "الفريق",
            "KRA-KPI Approval": "موافقة KRA-KPI",
            "KRA/KPI Approval": "موافقة KRA/KPI",
            "Target Setting": "ضبط الهدف",
            "Peer Assessment": "تقييم الأقران",
            "Approval": "الموافقة",
            "Organization": "المنظمة",
            "KRA-KPI Creation": "إنشاء KRA-KPI",
            "KRA-KPI Mapping": "رسم الخرائط KRA-KPI",
            "Result": "النتيجة",
            "Request": "طلب",
            "Check-In": "التحقق",
            "Key-Result": "النتيجة الرئيسية",
            "Appreciation": "تقدير",
            "One to One": "واحد إلى واحد",
            "Continuous Feedback": "تغذية راجعة مستمرة",
            "Compensation": "تعويض",
            "All": "الكل",
            "Normalization": "تطبيع",
            "Appraisal": "تقييم",
            "Incentive": "حافز",
            "Promotion": "ترقية",
            "Reports": "تقارير",
            "OKR": "OKR",
            "Settings": "الإعدادات",
            "Global": "عالمي",
            "Modules": "الوحدات",
            "Time Format": "تنسيق الوقت",
            "People": "الناس",
            "Untagged": "غير معلم",
            "Terminology": "مصطلحات",
            "Year": "سنة",
            "Cycle": "دورة",
            "KRA-KPI Configuration": "تكوين KRA-KPI",
            "Rating Scale": "مقياس التقييم",
            "Parameters": "المعلمات",
            "Mandatory Parameters": "المعلمات الإلزامية",
            "Employee Visibility": "رؤية الموظف",
            "9-Box": "صندوق 9",
            "Set-up": "إعداد",
            "Workflow": "سير العمل",
            "Templates": "قوالب",
            "Theme": "موضوع",
            "Normalization Rating": "تقييم التطبيع",
            "Library": "مكتبة",
            "KRAS": "KRAS",
            "KPIS": "KPIS",
            "Potential": "إمكانية",
            "Competency": "الكفاءة",
            "Key Result": "النتيجة الرئيسية",
            "Appreciation Badges": "شارات التقدير",
            "Skills": "مهارات",
            "Imports": "واردات",
            "Bulk Goals Setting": "ضبط الأهداف الجماعية",
            "Bulk Goals Achievement": "تحقيق الأهداف الجماعية",
            "Quick Performance Evaluation": "تقييم الأداء السريع",
            "Bulk Objective": "الهدف الجماعي",
            "Bulk Objective Key Results": "نتائج المفتاح الهدف الجماعي",
            "Bulk Structure": "الهيكل الجماعي",
            "Email": "البريد الإلكتروني",
            "Email Configuration": "تكوين البريد الإلكتروني",
            "Email Library": "مكتبة البريد الإلكتروني",
            "Notification": "إشعار",
            "Self Assessment": "التقييم الذاتي"
        },

        reports: {
            review: {
                appraiserMatrix: {
                    name: "مصفوفة تقييم الموظف",
                    desc: "يوفر نظرة عامة على المراجعين أو المقيمين الأداء المرتبطين بكل موظف في مراحل مختلفة من دورة المراجعة، مما يقدم صورة شاملة للمقيمين المعينين طوال عملية المراجعة."
                },
                stageStatus: {
                    name: "تقرير حالة المرحلة للموظف",
                    desc: "يوفر ملخصاً شاملاً لحالة المراجعة في كل مرحلة من مراحل دورة المراجعة، مما يقدم رؤى حول تقدم تقييمات الموظفين طوال مراحل مختلفة من عملية المراجعة."
                },
                combinedPerformenceAverage: {
                    name: "تقرير متوسط الأداء المجمع",
                    desc: "يوفر متوسط ​​تقييمات الأداء المستلمة في مراحل مختلفة من دورة المراجعة، مما يقدم نظرة عامة على أدائهم مقابل مختلف المعايير الأدائية طوال فترة التقييم."
                },
                competencyAverage: {
                    name: "تقرير متوسط الكفاءة %COMPETENCY%",
                    desc: "يوفر متوسط ​​تقييمات الكفاءة المستلمة في مراحل مختلفة من دورة المراجعة، مما يقدم نظرة عامة على أدائهم عبر مختلف الكفاءات طوال فترة التقييم."
                },
                kraAverage: {
                    name: "تقرير متوسط %KRA_LABEL%/%KPI_LABEL%",
                    desc: "يوضح التقرير تقييمات KRA/KPI للموظف عبر دورة المراجعة، مما يقدم نظرة عامة على أدائهم مقابل الأهداف المحددة طوال فترة التقييم."
                },
                targetAchievement: {
                    name: "تقرير تحقيق الهدف",
                    desc: "يوضح 'تقرير تحقيق الهدف' مدى تحقيق الأهداف المحددة في فترة التقييم، مع رصد التقدم والنجاح في تحقيق الأهداف المرغوبة."
                },
                potentialAverage: {
                    name: "تقرير متوسط الإمكانيات %POTENTIALS%",
                    desc: "يوفر متوسط ​​تقييمات الإمكانيات المستلمة في مراحل مختلفة من دورة المراجعة، مما يقدم نظرة عامة على أدائهم عبر مختلف الإمكانيات طوال فترة التقييم."
                },
                cycleRatings: {
                    name: "تقرير تقييمات الدورة",
                    desc: "يقدم ملخصاً شاملاً للمراجعات التي تم إجراؤها لموظف طوال دورة تقييم، مما يوفر نظرة عامة على أدائهم والملاحظات التي تلقوها خلال هذه الفترة."
                },
                targetVsAchievementOverview: {
                    name: "نظرة عامة على الهدف مقابل التحقيق",
                    desc: "توفير نظرة مفصلة على الإنجازات للهدف المحدد مقابل كل معلمة أداء."
                },
                targetBasedPerformence: {
                    name: "تقارير الأداء القائم على الهدف",
                    desc: "توفير نظرة مفصلة على الإنجازات للهدف المحدد مقابل كل معلمة أداء."
                },
                nineBox: {
                    name: "تقرير مفصل عن مصفوفة 9Box",
                    desc: "توفير نظرة مفصلة على مصفوفة 9Box للهدف المحدد مقابل كل معلمة أداء."
                },
                employeeReview: {
                    name: "التقييمات النهائية(ربع سنوي)",
                    desc: "توفير نقاط تقييم كل مراجع."
                },
                competencyRating: {
                    name: "تقرير تقييم الكفاءة",
                    desc: "يوفر هذا التقرير نظرة عامة مفصلة على تقييم الكفاءة."
                },
                employeeStructureKraKpi: {
                    name: "تقرير هيكل الموظف %KRA_LABEL% %KPI_LABEL%",
                    desc: "يوفر هذا التقرير نظرة عامة مفصلة على هيكل الموظف %KRA_LABEL% %KPI_LABEL%."
                },
                employeeAppraisal: {
                    name: "تقرير تقييم الموظف",
                    desc: "يقدم هذا التقرير لمحة مفصلة عن تقييم الموظف"
                },
                employeePromotion: {
                    name: "تقرير ترقية الموظف",
                    desc: "يقدم هذا التقرير لمحة مفصلة عن ترقية الموظف"
                },
                employeeUntag: {
                    name: "تقرير الموظف قم بفك العلامة",
                    desc: "يقدم هذا التقرير نظرة عامة مفصلة عن الموظفين غير الموسومين"
                },
                finalAverageRating: {
                    name: "متوسط ​​التقييم النهائي",
                    desc: "يقدم هذا التقرير نظرة عامة مفصلة عن متوسط ​​التقييم النهائي"
                },
                finalRatingAchievements: {
                    name: "إنجازات التقييم النهائي",
                    desc: "يقدم هذا التقرير نظرة عامة مفصلة عن إنجازات التقييم النهائي"
                },
                potentialRatings: {
                    name: "تقارير التقييم المحتملة",
                    desc: "يقدم هذا التقرير نظرة عامة مفصلة على تقارير التصنيف المحتملة"
                }
            },
            okr: {
                objectiveCompletion: {
                    name: "تقرير اكتمال الهدف حسب الموظف",
                    desc: "يوفر نظرة عامة على اكتمال الهدف حسب الموظف"
                },
                objectiveKeyResult: {
                    name: "%OBJECTIVE% %KEY_RESULT_LABEL%",
                    desc: "يوفر نظرة عامة على %OBJECTIVE% %KEY_RESULT_LABEL%"
                },
                checkInApproval: {
                    name: "حالة موافقة الدخول",
                    desc: "يوفر نظرة عامة على الدخولات وحالات الموافقة عليها"
                },
                objectiveLog: {
                    name: "تقارير سجل الهدف",
                    desc: "يوفر نظرة عامة على السجلات (الدخولات، الإنشاء، التحرير، الحذف) للأهداف/النتائج الرئيسية"
                }
            },
            continuousFeedback: {
                oneToOne: {
                    name: "تقرير متواصل عن التغذية الراجعة",
                    desc: "يوفر نظرة عامة على التغذية الراجعة حسب الموظف"
                },
                feedback: {
                    name: "تقرير اجتماع واحد إلى واحد",
                    desc: "يوفر نظرة عامة على الاجتماع الفردي مع الموظف"
                },
                appreciation: {
                    name: "تقرير تقدير الموظف",
                    desc: "يوفر نظرة عامة على تقرير تقدير الموظف"
                }
            },
        },

        filters: {
            default: {
                multiSelect: "حدد عوامل التصفية...",
                singleSelect: "حدد عامل التصفية..."
            },
            cycle: "دورة",
            department: "قسم",
            designation: "تعيين",
            location: "موقع",
            entity: "كيان",
            more: "أكثر",
            status: "الحالة",
            evalCycle: "دورة التقييم",
            employmentType: "نوع الوظيفة",
            businessUnit: "وحدة الأعمال",
            costCenter: "مركز التكلفة",
            shift: "نوبة عمل",
            structure: "الهيكل",
            periodicity: "التكرار",
            cycleYear: "عام الدورة",
            joiningYear: "عام الانضمام",
            joiningMonth: "شهر الانضمام",
            cycleUntaggedEmployees: "موظفون غير موسومين بدورة",
            cycleTaggedEmployees: "موظفون موسومين بدورة",
            employeeStatus: "حالة الموظف",
            mappingStatus: "حالة الرسم",
            reset: "إعادة تعيين",
            approvalStatus: "حالة الموافقة",
            evalYear: "عام التقييم",
            objectiveCycle: "دورة الهدف",
            month: "الشهر"
        },

        dashBoard: {
            review: {
                quickActions: {
                    pendingPeopleInformation: "تحتاج اهتمام (منتهية الصلاحية)",
                    needAttention: "معلومات الأشخاص المعلقين",
                    kraKpiApproval: "موافقة KRA/KPI",
                    targetSetting: "ضبط الأهداف",
                    selfAssesments: "التقييم الذاتي",
                    peerAssesments: "تقييم الأقران",
                    reviews: "المراجعات",
                    approvals: "الموافقة"
                },

                analytics: {
                    reviewDistributionByStage: "توزيع المراجعات حسب المرحلة",
                    organizationAchievement: "إنجازات المنظمة",
                    bestPerformers: "أفضل العاملين",
                    worstPerformers: "أسوأ العاملين",
                    departmentWiseRatingCount: "عدد التقييمات حسب القسم",
                    designationWiseRatingCount: "عدد التقييمات حسب التصنيف",
                    nineBoxMatrix: "مصفوفة 9-مربع",
                    performanceAssessment: "تقييم الأداء",
                    potentialAssessment: "تقييم الإمكانات"
                },
                pipeLines: {
                    kraKpiMapping: "رسم الخرائط %KRA%/%KPIS%",
                    kraKpiApproval: "موافقة %KRA%/%KPIS%",
                    targetSetting: "ضبط الأهداف",
                    selfAssessment: "التقييم الذاتي",
                    peerAssessment: "تقييم الأقران",
                    review1: "المراجعة (المستوى 1)",
                    review2: "المراجعة (المستوى 2)",
                    approve1: "موافقة (المستوى 1)",
                    approve2: "الموافقة (المستوى 2)"
                },
                ratingComparision: {
                    reviewRatingComparison: "مقارنة التقييم"
                },
                bellCurve: {
                    bellCurveBasedOnFinalRatings: "منحنى الجرس - استنادًا إلى التقييمات النهائية",
                    bellCurveEmployeesRatingWise: "منحنى الجرس - الموظفين (حسب التقييم)",
                    tabs: {
                        ideal: "مثالي",
                        actual: "فعلي"
                    }
                }
            }
        },

        empty: {
            noRecordsFound: "لم يتم العثور على سجلات",
            filters: {
                multiSelect: {
                    cycle: "يرجى تحديد دورات للسجلات"
                },
                single: {
                    cycle: "يرجى تحديد دورة ذات صلة"
                }
            }
        }
    }
}