import { createSelector } from 'reselect';

const selecAdminState = state => state.adminUserReducer;
const selectLocale = state => state.languageReducer.locale;

export const getUserAndLocality = createSelector(
    selecAdminState,
    selectLocale,
    (adminState, locale) => {
        return {
            adminState,
            locale
        };
    }
);