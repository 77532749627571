export const constantValue = {
  filterPeopleList: [
    {
      id: "cycleyear",
      name: "Evaluation Year",
      intlKey: "filters.evalYear",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: "cyclelist",
      name: "Evaluation Cycle",
      intlKey: "filters.evalCycle",
      disableLocalSearch: false,
      isSingleSelect: true,
    },

    {
      id: "current_status",
      name: "Approval Status",
      intlKey: "filters.approvalStatus",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: "strList",
      name: "Structure",
      intlKey: "filters.structure",
      disableLocalSearch: false,
      isSingleSelect: true,
      // showInMoreByDefault : true,
    },
  ],
  filterReviewList: [
    {
      id: "cycleyear",
      name: "Evaluation Year",
      intlKey: "filters.evalYear",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: "cyclelist",
      name: "Evaluation Cycle",
      intlKey: "filters.evalCycle",
      disableLocalSearch: false,
      isSingleSelect: true,
    },

    {
      id: "current_status",
      name: "Approval Status",
      intlKey: "filters.approvalStatus",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: "strList",
      name: "Structure",
      intlKey: "filters.structure",
      disableLocalSearch: false,
    }
  ],

  filterCompensationList: [
    {
      id: "cycleyear",
      name: "Evaluation Year",
      intlKey: "filters.evalYear",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: "cyclelist",
      name: "Evaluation Cycle",
      intlKey: "filters.evalCycle",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
  ],

  filterNeedAttention: [
    {
      id: "cycleyear",
      name: "Evaluation Year",
      intlKey: "filters.evalYear",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: "cyclelist",
      name: "Evaluation Cycle",
      intlKey: "filters.evalCycle",
      disableLocalSearch: false,
      isSingleSelect: true,
    },

    {
      id: "current_status",
      name: "Approval Status",
      intlKey: "filters.approvalStatus",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: "strList",
      name: "Structure",
      intlKey: "filters.structure",
      disableLocalSearch: false,
    }
  ],


  filterCompensation: [
    {
      id: "cycleyear",
      name: "Evaluation Year",
      intlKey: "filters.evalYear",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: "cyclelist",
      name: "Evaluation Cycle",
      intlKey: "filters.evalCycle",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
  ],
  filterReviewDashboard: [
    {
      id: "cyclelist",
      name: "Cycle",
      intlKey: "filters.cycle",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    // {
    //   id: "cycleyear",
    //   name: "Cycle Year",
    //   intlKey: "filters.cycleYear",
    //   disableLocalSearch: false,
    // },

    {
      id: 5,
      name: "Department",
      intlKey: "filters.department",
      disableLocalSearch: false,
    },
    {
      id: 7,
      name: "Designation",
      intlKey: "filters.designation",
      disableLocalSearch: false,
    },

    {
      id: 8,
      name: "Location",
      intlKey: "filters.location",
      disableLocalSearch: false,
      // showInMoreByDefault: true,
    },

    {
      id: 12,
      name: "Entity",
      intlKey: "filters.entity",
      disableLocalSearch: false,
      // showInMoreByDefault: true,
    },
  ],
  filterReviewRatingComparison: [
    {
      id: "cyclelist",
      name: "Cycle",
      intlKey: "filters.cycle",
      disableLocalSearch: false,
      // isSingleSelect: true,
    },
    // {
    //   id: "cycleyear",
    //   name: "Cycle Year",
    //   disableLocalSearch: false,
    // },

    {
      id: 5,
      name: "Department",
      intlKey: "filters.department",
      disableLocalSearch: false,
    },
    {
      id: 7,
      name: "Designation",
      intlKey: "filters.designation",
      disableLocalSearch: false,
    },

    {
      id: 8,
      name: "Location",
      intlKey: "filters.location",
      disableLocalSearch: false,
      // showInMoreByDefault: true,
    },

    {
      id: 12,
      name: "Entity",
      intlKey: "filters.entity",
      disableLocalSearch: false,
      // showInMoreByDefault: true,
    },
  ],
  filterPeopleListReview: [
    {
      id: "cyclelist",
      name: "Cycle",
      intlKey: "filters.cycle",
      disableLocalSearch: false,
    },
    {
      id: "cycleyear",
      name: "Cycle Year",
      intlKey: "filters.cycleYear",
      disableLocalSearch: false,
      showInMoreByDefault: true,
      // isSingleSelect: true,
    },
    {
      id: "strList",
      name: "Structure",
      intlKey: "filters.structure",
      disableLocalSearch: false,
    },

    {
      id: "joiningMonth",
      name: "Joining Month",
      intlKey: "filters.joiningMonth",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },

    {
      id: "joiningYear",
      name: "Joining Year",
      intlKey: "filters.joiningYear",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },

    {
      id: 5,
      name: "Department",
      intlKey: "filters.department",
      disableLocalSearch: false,
    },
    {
      id: 7,
      name: "Designation",
      intlKey: "filters.designation",
      disableLocalSearch: false,
    },

    {
      id: 8,
      name: "Location",
      intlKey: "filters.location",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 9,
      name: "Shift",
      intlKey: "filters.shift",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 12,
      name: "Entity",
      intlKey: "filters.entity",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },

    {
      id: 11,
      name: "Cost Center",
      intlKey: "filters.costCenter",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 52,
      name: "Business unit",
      intlKey: "filters.businessUnit",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 105,
      name: "Employment Type",
      intlKey: "filters.employmentType",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 125,
      name: "Employee Status",
      intlKey: "filters.employeeStatus",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
  ],

  filterFinalRatingReport: [
    {
      id: "cyclelist",
      name: "Cycle",
      intlKey: "filters.cycle",
      disableLocalSearch: false,
    },
    {
      id: "strList",
      name: "Structure",
      disableLocalSearch: false,
    },
    {
      id: 5,
      name: "Department",
      disableLocalSearch: false,
    },
    {
      id: 7,
      name: "Designation",
      disableLocalSearch: false,
    },
    {
      id: 8,
      name: "Location",
      disableLocalSearch: false,
    }
  ],
  filterAppraisalReport: [
    {
      id: "cyclelist",
      name: "Cycle",
      intlKey: "filters.cycle",
      disableLocalSearch: false,
    },
    {
      id: 5,
      name: "Department",
      disableLocalSearch: false,
    },
    {
      id: 7,
      name: "Designation",
      disableLocalSearch: false,
    },
    {
      id: 8,
      name: "Location",
      disableLocalSearch: false,
    }
  ],
  filterEmployeeStageStatusReport: [
    {
      id: "cyclelist",
      name: "Cycle",
      intlKey: "filters.cycle",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: 5,
      name: "Department",
      disableLocalSearch: false,
    },
    {
      id: 7,
      name: "Designation",
      disableLocalSearch: false,
    },
    {
      id: 8,
      name: "Location",
      disableLocalSearch: false,
    }
  ],
  filterCompetencyRatingReport: [
    {
      id: "cyclelist",
      name: "Cycle",
      intlKey: "filters.cycle",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: "strList",
      name: "Structure",
      intlKey: "filters.structure",
      disableLocalSearch: false,
    },
  ],
  filterPeopleListOkr: [
    {
      id: "objectivecyclelist",
      name: "Objective Cycle",
      intlKey: "Objective Cycle",
      disableLocalSearch: false,
    },
    {
      id: "objectiveYear",
      name: "Cycle Year",
      intlKey: "filters.cycleYear",
      disableLocalSearch: false,
    },

    {
      id: "joiningMonth",
      name: "Joining Month",
      intlKey: "filters.joiningMonth",
      disableLocalSearch: false,
    },

    {
      id: "joiningYear",
      name: "Joining Year",
      intlKey: "filters.joiningYear",
      disableLocalSearch: false,
    },

    {
      id: 5,
      name: "Department",
      intlKey: "filters.department",
      disableLocalSearch: false,
    },
    {
      id: 7,
      name: "Designation",
      intlKey: "filters.designation",
      disableLocalSearch: false,
    },

    {
      id: 8,
      name: "Location",
      intlKey: "filters.location",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 9,
      name: "Shift",
      intlKey: "filters.shift",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 12,
      name: "Entity",
      intlKey: "filters.entity",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },

    {
      id: 11,
      name: "Cost Center",
      intlKey: "filters.costCenter",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 52,
      name: "Business unit",
      intlKey: "filters.businessUnit",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 105,
      name: "Employment Type",
      intlKey: "filters.employmentType",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 125,
      name: "Employee Status",
      intlKey: "filters.employeeStatus",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
  ],
  filterOkrDashboard: [
    {
      id: "objective_month",
      name: "Month",
      intlKey: "filters.month",
      disableLocalSearch: false,
    },
    {
      id: "objectivecyclelist",
      name: "Cycle",
      intlKey: "filters.cycle",
      disableLocalSearch: false,
    },
    {
      id: 5,
      name: "Department",
      intlKey: "filters.department",
      disableLocalSearch: false,
    },
    {
      id: 7,
      name: "Designation",
      intlKey: "filters.designation",
      disableLocalSearch: false,
    },

    {
      id: 8,
      name: "Location",
      intlKey: "filters.location",
      disableLocalSearch: false,
      // showInMoreByDefault: true,
    },
    {
      id: 9,
      name: "Shift",
      intlKey: "filters.shift",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 12,
      name: "Entity",
      intlKey: "filters.entity",
      disableLocalSearch: false,
      // showInMoreByDefault: true,
    },

    {
      id: 11,
      name: "Cost Center",
      intlKey: "filters.costCenter",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 52,
      name: "Business unit",
      intlKey: "filters.businessUnit",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 105,
      name: "Employment Type",
      intlKey: "filters.employmentType",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
  ],
  filterPeopleListAll: [
    {
      id: 'mapping_status',
      name: "Mapping Status",
      intlKey: "filters.mappingStatus",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: 5,
      name: "Department",
      intlKey: "filters.department",
      disableLocalSearch: false,
    },
    {
      id: 7,
      name: "Designation",
      intlKey: "filters.designation",
      disableLocalSearch: false,
    },
    {
      id: 52,
      name: "Business unit",
      intlKey: "filters.businessUnit",
      disableLocalSearch: false,
      // showInMoreByDefault: true,
    },

    {
      id: 8,
      name: "Location",
      intlKey: "filters.location",
      disableLocalSearch: false,
    },
    {
      id: 12,
      name: "Entity",
      intlKey: "filters.entity",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 9,
      name: "Shift",
      intlKey: "filters.shift",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },

    {
      id: 125,
      name: "Employee Status",
      intlKey: "filters.employeeStatus",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: "joiningMonth",
      name: "Joining Month",
      intlKey: "filters.joiningMonth",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },

    {
      id: "joiningYear",
      name: "Joining Year",
      intlKey: "filters.joiningYear",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },

    {
      id: 11,
      name: "Cost Center",
      intlKey: "filters.costCenter",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },

    {
      id: 105,
      name: "Employment Type",
      intlKey: "filters.employmentType",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
  ],

  filterEmployeeListComponent: [
    {
      id: 5,
      name: "Department",
      intlKey: "filters.department",
      disableLocalSearch: false,
    },
    {
      id: 7,
      name: "Designation",
      intlKey: "filters.designation",
      disableLocalSearch: false,
    },
    {
      id: 8,
      name: "Location",
      intlKey: "filters.location",
      disableLocalSearch: false,
    },
    {
      id: "joiningYear",
      name: "Joining Year",
      intlKey: "filters.joiningYear",
      disableLocalSearch: false,
    },
    {
      id: "joiningMonth",
      name: "Joining Month",
      intlKey: "filters.joiningMonth",
      disableLocalSearch: false,
    },
    {
      id: 8,
      name: "Cycle Untagged Employees",
      intlKey: "filters.cycleUntaggedEmployees",
      disableLocalSearch: false,
    },
    {
      id: 8,
      name: "Cycle Tagged Employees",
      intlKey: "cycleTaggedEmployees",
      disableLocalSearch: false,
    },
  ],
  filterObjectiveComponent: [
    {
      id: "cycleyear",
      name: "Cycle Year",
      intlKey: "filters.cycleYear",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: "cyclelist",
      name: "Cycle",
      intlKey: "filters.cycle",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: "periodicity",
      name: "Periodicity",
      intlKey: "filters.periodicity",
      disableLocalSearch: false,
      isSingleSelect: true,
    },

    {
      id: "strList",
      name: "Structure",
      intlKey: "filters.structure",
      disableLocalSearch: false,
    },
    {
      id: 5,
      name: "Department",
      intlKey: "filters.department",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 7,
      name: "Designation",
      intlKey: "filters.designation",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },

    {
      id: 8,
      name: "Location",
      intlKey: "filters.location",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 9,
      name: "Shift",
      intlKey: "filters.shift",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 12,
      name: "Entity",
      intlKey: "filters.entity",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },

    {
      id: 11,
      name: "Cost Center",
      intlKey: "filters.costCenter",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 52,
      name: "Business unit",
      intlKey: "filters.businessUnit",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
    {
      id: 105,
      name: "Employment Type",
      intlKey: "filters.employmentType",
      disableLocalSearch: false,
      showInMoreByDefault: true,
    },
  ],
  filterNormalizationList: [
    {
      id: "cyclelist",
      name: "Evaluation Cycle",
      intlKey: "filters.evalCycle",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: 5,
      name: "Department",
      intlKey: "filters.department",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
    {
      id: 7,
      name: "Designation",
      intlKey: "filters.designation",
      disableLocalSearch: false,
      isSingleSelect: true,
    },

    {
      id: 8,
      name: "Location",
      intlKey: "filters.location",
      disableLocalSearch: false,
      isSingleSelect: true,
    },
  ],
  filterFeedbackDashboard: [
    {
      id: 5,
      name: "Department",
      intlKey: "filters.department",
      disableLocalSearch: false,
    },
    {
      id: 7,
      name: "Designation",
      intlKey: "filters.designation",
      disableLocalSearch: false,
    },
    {
      id: 8,
      name: "Location",
      intlKey: "filters.location",
      disableLocalSearch: false,
    },
  ],
  oneToOneReportList: [
    {
      id: 5,
      name: "Department",
      intlKey: "filters.department",
      disableLocalSearch: false,
    },
    {
      id: 7,
      name: "Designation",
      intlKey: "filters.designation",
      disableLocalSearch: false,
    },
    {
      id: 8,
      name: "Location",
      intlKey: "filters.location",
      disableLocalSearch: false,
    },
    {
      id: 'oneToOneStatus',
      name: "Status",
      intlKey: "filters.status",
      disableLocalSearch: false,
      isSingleSelect: true
    }
  ]
};

export const filterMappingForNameKeysMasterObject = {
  filterPeopleListAll: {
    "Mapping Status": "mapping_status",
    "Cycle Year": "cycle_years",
    Cycle: "cycles",
    "Objective Cycle": "obj_cycle",
    Structure: "structures",
    Department: "departments",
    Designation: "designations",
    Location: "location",
    Shift: "shifts",
    Entity: "entities",
    "Cost Center": "cost_centers",
    "Employment Type": "employee_types",
    "Joining Month": "joining_months",
    "Joining Year": "joining_years",
    "Business Unit": "business_units",
    "Employee Status": "employee_status",
  },
  filterReviewList: {
    "Evaluation Year": "cycle_year",
    "Evaluation Cycle": "cycle_id",
    Structure: "str_id",
    "Current Stage": "review_stage",
  },
  filterCompensationList: {
    "Evaluation Year": "cycle_year",
    "Evaluation Cycle": "cycle",
  },
  filterCompensationSettingList: {
    "Evaluation Year": "cycle_year",
    "Evaluation Cycle": "cycle",
  },
  filterNormalizationList: {
    "Evaluation Cycle": "cycle_id",
    Department: "departments",
    Designation: "designations",
    Location: "location",
  },
  filterFeedbackDashboard: {
    Department: "department",
    Designation: "designation",
    Location: "location",
  },
  filterRatingComparision: {
    'Cycle 1': 'cycle_id',
    'Cycle 2': "comparison_cycle_id"
  },
  filterReviewDashboard: {
    Cycle: "cycles",
    Department: "departments",
    Designation: "designations",
    Location: "location",
    Entity: "entities",
  },
  oneToOneReportList: {
    Department: "departments",
    Designation: "designations",
    Location: "locations",
    Status: "meeting_status"
  },
  filterCompetencyRatingReport: {
    Cycle: "cycle",
    Structure: "str_id"
  },
  filterEmployeeStageStatusReport: {
    Cycle: "cycle_id",
    Department: "departments",
    Designation: "designations",
    Location: "location",
  }
}