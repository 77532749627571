import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { getUserAndLocality } from './redux/selectors';
import { useIntl } from 'react-intl';

export const useGlobalContants = () => {

    const { locale } = useSelector(getUserAndLocality)

    const intl = useIntl();

    const COMPENSATION_TABS = useMemo(() => {
        return (
            [
                {
                    label: intl.formatMessage({ id: "tabs.pending", defaultMessage: "Pending" }),
                    value: 0,
                },
                {
                    label: intl.formatMessage({ id: "tabs.completed", defaultMessage: "Completed" }),
                    value: 1,
                },
            ]);
    }, [locale])


    const ONE_TO_ONE_MEDIUM_TYPES = {
        1: { LABEL: "Location", COLUMN_NAME: "LOCATION" },
        2: { LABEL: "Meeting Link", COLUMN_NAME: "MEETING_LINK" },
        3: { LABEL: "Phone Number", COLUMN_NAME: "PHONE_NO" }
    }


    const ONE_TO_ONE_TYPES = [
        { value: 1, label: "Face to Face" },
        { value: 2, label: "Online Meeting" },
        { value: 3, label: "Phone Call" }
    ]

    const TARGET_SETTING_OPTIONS = [
        { label: "Alphanumeric", value: 0 },
        { label: "Numeric", value: 1 }
    ]

    const FEEDBACK_TABS = useMemo(() => {
        return [
            { label: intl.formatMessage({ id: "tabs.received", defaultMessage: "Received" }), value: 'received' },
            { label: intl.formatMessage({ id: "tabs.given", defaultMessage: "Given" }), value: 'given' }
        ]
    }, [locale])

    const ONE_TO_ONE_SCHEDULE_OPTIONS = useMemo(() => {
        return [
            { label: intl.formatMessage({ id: "select.options.all", defaultMessage: "All" }), value: "" },
            { label: intl.formatMessage({ id: "select.options.upcoming", defaultMessage: "Upcoming" }), value: 0 },
            { label: intl.formatMessage({ id: "select.options.completed", defaultMessage: "Completed" }), value: 1 },
            { label: intl.formatMessage({ id: "select.options.overdue", defaultMessage: "Overdue" }), value: 3 },
        ]
    }, [locale])


    const MONTH_FILTERS_OPTIONS =
        useMemo
            (() => {
                return [
                    { label: intl.formatMessage({ id: "select.options.all", defaultMessage: "All" }), value: undefined, },
                    { label: intl.formatMessage({ id: "select.options.thisMonth", defaultMessage: "This Month" }), value: 0 },
                    { label: intl.formatMessage({ id: "select.options.lastMonth", defaultMessage: "Last Month" }), value: 1 },
                    { label: intl.formatMessage({ id: "select.options.thisQuarter", defaultMessage: "This Quarter" }), value: 2 },
                    { label: intl.formatMessage({ id: "select.options.lastQuarter", defaultMessage: "Last Quarter" }), value: 3 },
                    { label: intl.formatMessage({ id: "select.options.thisYear", defaultMessage: "This Year" }), value: 4 },
                    { label: intl.formatMessage({ id: "select.options.lastYear", defaultMessage: "Last Year" }), value: 5 }
                ]
            }, [locale])


    const EMPLOYEE_STATUS = [
        {
            value: "0",
            label: "Active"
        },
        {
            value: "1",
            label: "Probation"
        },
        {
            value: "3",
            label: "Separated"
        },
        {
            value: "4",
            label: "Absconded"
        },
        {
            value: "5",
            label: "Terminate"
        },
        {
            value: "6",
            label: "Suspended"
        }
    ]

    return { TARGET_SETTING_OPTIONS, ONE_TO_ONE_TYPES, COMPENSATION_TABS, FEEDBACK_TABS, MONTH_FILTERS_OPTIONS, ONE_TO_ONE_SCHEDULE_OPTIONS, ONE_TO_ONE_MEDIUM_TYPES,EMPLOYEE_STATUS }

}

