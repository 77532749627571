/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@material-ui/core/Box";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import reactDOM from "react-dom";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router-dom";
import { constantValue } from "./constants";
import {
  decryptAES,
  encryptAES,
  removeDuplicatesByProperty,
} from "../utitlies/HelpersFunction";
import DebouncedSearchComponent from "../DebouncedSearch/DebouncedSearch";
import MultiSelectPopperFilter from "../MultiSelectPopperFilter/MultiSelectPopperFilter";
import { updateFilterObj } from "./Utils";
import styles from "./styles.module.scss";
import SingleSelectPopperFilter from "../MultiSelectPopperFilter/SingleSelectPopperFilter";
import { useIntl } from 'react-intl';

// PROBLEM USING USEFETCH because of import issue of admin and ess so usinng saga.

const STRING = "string";
const DATE_RANGE_KEY = "date-range-val";
const SEARCH_KEY = "search";

const CustomFilter = ({
  filterKey = "",
  setFilter = () => { },
  filter = {},
  searchableFieldsInMore = [], // this "searchableFieldsInMore" has to be passed as a constant and not as a state variable as this is important for initial mounting
  showMainSearch = false,
  showReset = true,
  showMoreOptions = false,
  preSetFilter = undefined,
  doNotShowGetFormValuesAtAll = false,
  customOptionsMapping = null, // basically a way to insert your options from props to the customfilter , that may not come from the backend,
  enableURLCaching = true,
  formFieldValue = {},

  // IMPORTANT : IN case of sending values from props to be default selected the url thing may not work or may get overriden
  //Better to put enableURLCaching=false here then.
  // defaultSelectedValuesMappingFromProps = {},
}) => {
  const [options, setOptions] = useState();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const intl = useIntl();

  //put no dependency here as we only and only run this on mount .
  const initialQueryState = React.useMemo(() => {
    const tempQueryState = {};
    // Populate initialQueryState from URL stateForQueryParams parameters
    if (location.search && location.search.trim() !== "?") {
      const decryptedString = decryptAES(
        String(location.search).substring(1) // basically to ignore "?" the question mark that comes in the stateForQueryParams params part
      );

      const queryParams = new URLSearchParams(decryptedString);
      queryParams.forEach((value = "", key) => {
        if (
          key === SEARCH_KEY ||
          searchableFieldsInMore.findIndex((el) => el.id === key) > -1
        ) {
          tempQueryState[key] = value;
        } else if (value.startsWith("num__")) {
          try {
            tempQueryState[key] = parseInt(value.replace("num__", "").trim());
          } catch (err) {
            console.log({ err });
          }
        } else if (value.trim()) {
          tempQueryState[key] = value.split(",");
        } else {
          console.log({ key, value });
        }
      });
    }

    return tempQueryState;
  }, []);

  const filterTypesToBeShownInViewByDefault = React.useMemo(
    () =>
      (constantValue[filterKey || "filterKeyNameMapping"] ?? []).filter(
        (el) => !el?.showInMoreByDefault
      ),
    [filterKey]
  );

  const filterTypesToBeShownInMoreByDefault = React.useMemo(
    () =>
      (constantValue[filterKey || "filterKeyNameMapping"] ?? []).filter(
        (el) => el?.showInMoreByDefault
      ),
    [filterKey]
  );

  const FilterTypes = React.useMemo(
    () =>
      preSetFilter && Array.isArray(preSetFilter) && preSetFilter?.length > 0
        ? preSetFilter
        : doNotShowGetFormValuesAtAll
          ? []
          : filterTypesToBeShownInViewByDefault,
    [filterKey, preSetFilter, doNotShowGetFormValuesAtAll]
  );

  const [stateForQueryParams, setStateForQueryParams] =
    useState(initialQueryState);
  const [optionsForMorePopper, setOptionsForMorePopper] = useState([]);
  const [checkedOptionsInMore, setCheckedOptionsInMore] = useState([]);
  const [filtersAvailableInView, setFiltersAvailableInView] = useState([]);
  const [searchStateClearingToggler, setSearchStateClearingToggler] =
    useState(false);

  const mountedRef = useRef(false);
  const searchRef = useRef(null);

  const childRefs = useRef([]);

  const handleClearInputs = () => {
    childRefs.current.forEach((childRef) => {
      if (childRef && childRef.current) {
        childRef.current.clearInput();
      }
    });
  };

  // Create a debounced function for updating the URL
  const debouncedUpdateURL = useRef(
    debounce((newQueryParams) => {
      // console.log({ newQueryParams });
      if (mountedRef.current && enableURLCaching) {
        const searchParams = new URLSearchParams();

        Object.entries(newQueryParams).forEach(([key, value]) => {
          // console.log({ key, value });
          if (Array.isArray(value) && value.length) {
            searchParams.set(key, value.join(","));
          } else if (typeof value === STRING) {
            searchParams.set(key, value);
          } else if (typeof value === "number") {
            searchParams.set(key, "num__" + value);
          } else {
            console.log({ key, value });
          }
        });

        const encryptedString = encryptAES(searchParams.toString());
        navigate({ search: encryptedString, state }, { replace: true });
      }
    }, 300)
  ).current;

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      debouncedUpdateURL.cancel();
    };
  }, []);

  useEffect(() => {
    let tempFilter = {};

    Object.keys(initialQueryState).forEach((key) => {
      if (typeof initialQueryState[key] === STRING) {
        tempFilter[key] = String(initialQueryState[key]).trim();
      } else if (key === DATE_RANGE_KEY) {
        tempFilter[key] = initialQueryState[key];
      } else if (Array.isArray(initialQueryState[key])) {
        tempFilter[key] = initialQueryState[key].map((el) => {
          return { id: el, name: "" };
        });
      } else if (typeof initialQueryState[key] === "number") {
        tempFilter[key] = initialQueryState[key];
      }
    });

    if (!isEmpty(tempFilter))
      setFilter((prev) => {
        return { ...prev, ...tempFilter };
      });


  }, [initialQueryState]);

  // useEffect(() => {
  //   setStateForQueryParams(initialQueryState);
  // }, [initialQueryState]);

  useEffect(() => {
    debouncedUpdateURL(stateForQueryParams);
    return () => {
      debouncedUpdateURL.cancel();
    };
  }, [stateForQueryParams, debouncedUpdateURL]);

  // to be optimized
  const updateFilter = (value, name, type) => {

    if (type === "single") {

      if (filter[name] == value.id) {
        handleResetClick();
        return;
      }

      setFilter((prev) => {
        return {
          ...prev,
          [name]: value?.id ?? value,
        };
      });
      return;
    }

    //if multiple
    const data = updateFilterObj(value, name, filter);
    setFilter({ ...data });
  };

  const handleResetClick = () => {
    if (!isEmpty(stateForQueryParams)) {
      setStateForQueryParams({});
    }

    if (!isEmpty(filter)) {
      setFilter({});
    }

    if (searchRef.current) {
      searchRef.current.clearInput();
    }

    if (searchableFieldsInMore.length) {
      setSearchStateClearingToggler(true);
      setTimeout(() => setSearchStateClearingToggler(false), 500);
      handleClearInputs();
    }
  };

  const handleChangeInSearchableFields = (value, itemName) => {
    reactDOM.unstable_batchedUpdates(() => {
      setStateForQueryParams((prevQuery) => {
        return { ...prevQuery, [itemName]: value };
      });
      setFilter((prevFilter) => {
        return { ...prevFilter, [itemName]: value };
      });
    });
  };

  useEffect(() => {
    if (!isEmpty(customOptionsMapping)) {
      setOptions((prev) => ({ ...prev, ...customOptionsMapping?.["options"] }));
    }
  }, [customOptionsMapping]);

  // const debouncedHandleSearchChange = debounce(handleSearchChange, 300);

  const updateCheckedItems = (updatedCheckedArray, item) => {
    // console.log({ updatedCheckedArray, item });
    setStateForQueryParams((prevQuery) => ({
      ...prevQuery,
      [item.name]: updatedCheckedArray,
    }));
  };

  const updateRadioItems = (itemId, item) => {
    setStateForQueryParams((prevQuery) => ({
      ...prevQuery,
      [item.name]: itemId,
    }));
  };

  // const hasDefaultSelectionBeenCalledOnce = useRef(false);

  // useEffect(() => {
  //   if (hasDefaultSelectionBeenCalledOnce.current || isEmpty(defaultSelectedValuesMappingFromProps) ) return;

  //   // setStateForQueryParams((prev) => {
  //   //   return Object.keys(({...prev, ...defaultSelectedValuesMappingFromProps})).map((key) => ({
  //   //   [key] : {...prev[key] , ... }
  //   //   }))
  //   // } );

  //   setStateForQueryParams((prev) => ({...prev,...defaultSelectedValuesMappingFromProps}))


  //   hasDefaultSelectionBeenCalledOnce.current = true;
  // }, [ defaultSelectedValuesMappingFromProps ]);

  const clearFilterSelectionForItemName = (itemName, type) => {
    if (filter[itemName]) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        [itemName]: type === "single" ? null : [],
      }));
    }

    if (stateForQueryParams[itemName]) {
      setStateForQueryParams((prevFilter) => ({
        ...prevFilter,
        [itemName]: type === "single" ? null : [],
      }));
    }
  };

  const availableFilterTypes = useMemo(() => {
    let tempAvailableFilterTypes =
      FilterTypes.filter((item) =>
        Object.keys(formFieldValue).includes(`${item.id}`)
      ) || [];
    const returnValueForAvailableFilterTypes = [
      ...tempAvailableFilterTypes,
      ...searchableFieldsInMore,
      ...filterTypesToBeShownInMoreByDefault,
    ];

    setOptionsForMorePopper((prev) =>
      removeDuplicatesByProperty(
        [
          ...prev,
          ...returnValueForAvailableFilterTypes,
          ...filterTypesToBeShownInMoreByDefault,
        ],
        "id"
      )
    );

    //use initialQueryState with this....
    const tempCheckedOptionsInMore = tempAvailableFilterTypes.map(
      (el) => el.id
    );
    const fieldsIdInInitialQueryFilterAndSearchables = Object.keys(
      initialQueryState
    ).filter(
      (id) => searchableFieldsInMore.findIndex((el) => el.id === id) > -1
    );

    setCheckedOptionsInMore([
      ...tempCheckedOptionsInMore,
      ...fieldsIdInInitialQueryFilterAndSearchables,
    ]);
    return returnValueForAvailableFilterTypes;
  }, [FilterTypes, formFieldValue, initialQueryState]);

  useEffect(() => {
    const tempAvailableInView = [
      ...availableFilterTypes.filter((el) =>
        checkedOptionsInMore.includes(el?.id)
      ),
      ...(customOptionsMapping?.["filterTypes"] ?? []),
    ];

    setFiltersAvailableInView(tempAvailableInView);
  }, [availableFilterTypes, checkedOptionsInMore, customOptionsMapping]);

  const handleCheckedItemsStateChangeForMoreOptions = (
    updatedCheckedArray,
    itemId,
    newStateOfTheItem
  ) => {
    setCheckedOptionsInMore(updatedCheckedArray);
    if (newStateOfTheItem === false && Array.isArray(availableFilterTypes)) {
      let name = (availableFilterTypes || []).find(
        (el) => String(el.id).trim() === String(itemId).trim()
      )?.name;
      if (name) {
        clearFilterSelectionForItemName(name);
      }
    }
  };

  const defaultCheckedItems = useCallback(
    (item) => {
      return stateForQueryParams?.[item.name]
        ? Array.isArray(stateForQueryParams?.[item.name])
          ? stateForQueryParams[item.name]
          : []
        : [];
    },
    [stateForQueryParams]
  );

  const defaultSelectedOption = useCallback(
    (item) => {
      return stateForQueryParams?.[item.name];
    },
    [stateForQueryParams]
  );

  return (
    <>


      <Box className={styles?.container__styling}>
        {/* Search Filter - main */}
        {showMainSearch && (
          <DebouncedSearchComponent
            defaultValue={initialQueryState[SEARCH_KEY]}
            onSearch={(val) => handleChangeInSearchableFields(val, SEARCH_KEY)}
            delay={500}
            ref={searchRef}
          />
        )}

        {/* Multi Select filters */}
        {filtersAvailableInView.map((item, index) => (
          <div className="mp-0 cursor-pointer" key={index}>
            { }
            {searchableFieldsInMore.findIndex((el) => el.id === item.id) >
              -1 ? (
              <>
                <MultiSelectPopperFilter
                  disableLocalSearch={true}
                  itemData={item}
                  options={[]}
                  checkedItems={[]}
                  setCheckedItems={() => { }}
                  runAfterSearchChange={(val) =>
                    handleChangeInSearchableFields(val, item.id ?? "")
                  }
                  searchStateClearingToggler={searchStateClearingToggler}
                  placeHolderForSearch={`Search ${item.name}`}
                  defaultSearchValue={initialQueryState?.[item.id] ?? ""}
                  tooltip={{
                    show: true,
                    message: "filters only through search",
                  }}
                />
              </>
            ) : item.isSingleSelect ? (
              <SingleSelectPopperFilter
                disableLocalSearch={item.disableLocalSearch ?? true}
                itemData={item}
                options={
                  options?.[item?.id]
                    ? options[item.id]
                    : formFieldValue[`${item.id}`] || []
                }
                selectedOption={defaultSelectedOption(item)}
                setSelectedOption={(itemId) => updateRadioItems(itemId, item)}
                placeHolderForSearch={`Search ${item.name}`}
                onRadioChange={(option) =>
                  updateFilter(option, item.name, "single")
                }
                onClearSelection={() =>
                  clearFilterSelectionForItemName(item.name, "single")
                }
              />
            ) : (
              <MultiSelectPopperFilter
                disableLocalSearch={item.disableLocalSearch ?? true}
                itemData={item}
                options={
                  options?.[item?.id]
                    ? options[item.id]
                    : formFieldValue[`${item.id}`] || []
                }
                checkedItems={defaultCheckedItems(item)}
                setCheckedItems={(
                  updatedCheckedArray,
                  itemId,
                  newStateOfTheItem
                ) => updateCheckedItems(updatedCheckedArray, item)}
                placeHolderForSearch={`Search ${item.name}`}
                onCheckBoxChange={(option) => updateFilter(option, item.name)}
                onClearSelection={() =>
                  clearFilterSelectionForItemName(item.name)
                }
              />
            )}
          </div>
        ))}

        {/* More filter */}
        {showMoreOptions && (
          <MultiSelectPopperFilter
            itemData={{ name: intl.formatMessage({ id: 'filters.more', defaultMessage: "More" }), icon: <FiPlus /> }}
            options={optionsForMorePopper}
            disableLocalSearch={false}
            placeHolderForSearch={`Search Filters`}
            onCheckBoxChange={(option) => { }}
            setCheckedItems={handleCheckedItemsStateChangeForMoreOptions}
            checkedItems={checkedOptionsInMore}
          />
        )}

        {showReset && (
          <button
            className={`${isEmpty(stateForQueryParams) && isEmpty(filter)
              ? styles.reset__style__disabled
              : styles.reset__style
              }`}
            onClick={handleResetClick}
            disabled={isEmpty(stateForQueryParams) && isEmpty(filter)}
          >
            {intl.formatMessage({ id: 'filters.reset', defaultMessage: "Reset" })}
          </button>
        )}
      </Box>
    </>
  );
};
export default CustomFilter;
