import React, { useEffect, useState } from "react";
import TabPanel from "../TabPanel/TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useImperativeHandle, forwardRef } from "react";

const CompleteTabs = forwardRef(
  (
    {
      defaultValue = null,
      tabData = [],
      unMountPanelsOnTabChange = true,
      handleChange = () => {},
      withoutComponents = false,
      hideTabs = false,
    },
    ref
  ) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
      setValue(defaultValue || tabData[0]?.value);
    }, [defaultValue]);

    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };

    useImperativeHandle(ref, {
      changeInternalActiveTabState: (val) => {
        setValue(val);
      },
    });

    return (
      <React.Fragment>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", mt: 1, zIndex: 1 }}
          className={hideTabs ? "d-none" : ""}
        >
          <Tabs
            sx={{
              "&.MuiTabs-indicator": {
                backgroundColor: "#335AFF",
              },
            }}
            value={value}
            onChange={(event, newValue) => {
              handleTabChange(event, newValue);
              handleChange(event, newValue);
            }}
            indicatorColor="#335AFF"
            TabIndicatorProps={{
              sx: {
                backgroundColor: "#335AFF",
              },
            }}
          >
            {tabData.map((el) => (
              <Tab
                key={el.value}
                label={el.label}
                value={el.value}
                sx={{
                  "&.Mui-selected": {
                    color: "#335AFF !important",
                  },
                  "&.MuiTab-root": {
                    color: "#30323E",
                    fontWeight: 400,
                    fontSize: "14px",
                  },
                }}
              />
            ))}
          </Tabs>
        </Box>

        {withoutComponents ? null : (
          <>
            {tabData.map((el) => (
              <TabPanel
                key={el.value}
                value={value}
                index={el.value}
                unMountPanelsOnTabChange={unMountPanelsOnTabChange}
              >
                {el.component}
              </TabPanel>
            ))}
          </>
        )}
      </React.Fragment>
    );
  }
);

export default CompleteTabs;
