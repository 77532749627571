import React from "react";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import styles from "./styles.module.scss";
import { ClickAwayListener } from "@material-ui/core";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import { useEffect } from "react";
import { Divider } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { convertArrayToObjectWithKey } from "../utitlies/commonFunction";
import { useIntl } from "react-intl";
const SingleSelectPopperFilter = ({
  itemData = {
    name: "",
    icon: null,
  },
  openFromProps = false,
  options = [],
  placeHolderForSearch = "Search",
  onRadioChange = (option) => {},
  selectedOption = null,
  setSelectedOption = () => {},
  tooltip = { show: false, message: "" },
  searchStateClearingToggler = false,
  defaultSearchValue = "",
  onClearSelection = () => {},
  disableLocalSearch = true,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState(defaultSearchValue);

  const intl = useIntl();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //   useEffect(()=>{
  // console.log({selectedOption})
  //   } , [selectedOption])

  const handleSearchChange = (event) => {
    if (disableLocalSearch) onClearSelection();
    setSearchText(event.target?.value);
  };

  useEffect(() => {
    if (openFromProps) {
      handleClick();
    }
  }, [openFromProps]);

  useEffect(() => {
    if (searchStateClearingToggler && searchText) {
      setSearchText("");
    }
  }, [searchStateClearingToggler]);

  const handleClearSelection = () => {
    setSelectedOption(null);
    onClearSelection();
  };

  const open = Boolean(anchorEl) || openFromProps;
  const id = open ? "popover" : undefined;

  const optionsMapping = React.useMemo(() => {
    return Array.isArray(options)
      ? convertArrayToObjectWithKey(options, "id")
      : {};
  }, [options]);

  const handleToggleItem = (itemId) => {
    const isChecked = selectedOption === itemId;
    // checkedItems.includes(itemId) ||
    //   checkedItems.includes(String(itemId).trim());
    if (isChecked) {
      setSelectedOption(null);
    } else {
      setSelectedOption(itemId);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div style={{ position: "relative", zIndex: 100 }}>
        <div
          className={`${styles.filter__box} ${
            selectedOption ? styles.checked : ""
          }`}
          onClick={handleClick}
        >
          <div
            className={`${styles.filters__default__text} ${
              selectedOption ? styles.checked__text : ""
            } flex-start-center`}
            style={{ paddingRight: "10px" }}
          >
            <div style={{ marginRight: "15px" }}>
              {selectedOption
                ? itemData.name +
                  " : " +
                  (optionsMapping?.[selectedOption]?.name || "")
                : intl.formatMessage({
                    id: itemData?.intlKey || "filters.default.singleSelect",
                    defaultMessage: itemData?.name,
                  })}
            </div>
            <MdKeyboardArrowDown />
          </div>
        </div>

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          disablePortal
          placement="bottom-start"
          sx={{
            zIndex: 10,
          }}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
          ]}
        >
          <Paper className={styles?.paper__container}>
            <div className={`flex-start-center ${styles?.search__component}`}>
              <BsSearch style={{ width: 14, height: 14 }} />
              <input
                type="text"
                placeholder={placeHolderForSearch}
                name="searchText"
                autoComplete="off"
                value={searchText}
                onChange={handleSearchChange}
                style={{
                  margin: "0 !important",
                  width: "100% !important",
                }}
                className="input-search w-100"
              />
              {tooltip.show && (
                <div className="flex-center ml-10">
                  <InfoOutlinedIcon
                    className="cursor-pointer"
                    style={{ fontSize: "14px", color: "slategrey" }}
                  />
                </div>
              )}
            </div>

            <List
              sx={{
                maxHeight: "200px",
                overflow: "hidden",
                "&:hover": {
                  overflow: "auto",
                },
              }}
            >
              {options
                .filter((el) =>
                  String(el?.name)
                    .toLowerCase()
                    .includes(String(searchText).toLowerCase().trim())
                )
                .map((option) => {
                  return (
                    <ListItem
                      key={option.id}
                      sx={{
                        pl: "0.5rem !important",
                        alignItems: "center",
                        backgroundColor:
                          selectedOption === option.id
                            ? "rgba(51, 90, 255, 0.1);"
                            : "white",
                      }}
                      dense
                      button
                      onClick={() => {
                        onRadioChange(option);
                        handleToggleItem(option.id);
                      }}
                    >
                      {option.icon && <>{option.icon}</>}
                      <ListItemText
                        primary={
                          <span
                            className={styles?.filters__default__text}
                            style={option.textStyleOverrides}
                          >
                            {option.name}
                          </span>
                        }
                        disableTypography
                      />
                    </ListItem>
                  );
                })}
            </List>

            {selectedOption && (
              <>
                <Divider />

                <b
                  className="cursor-pointer mt-2"
                  onClick={handleClearSelection}
                >
                  clear selection
                </b>
              </>
            )}
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default SingleSelectPopperFilter;
