import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  LOGIN_ADMIN_USER,
  LOGIN_ADMIN_USER_SUCCESS,
  ADMIN_AUTHENTICATE_USER,
  ADMIN_LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SET_PASSWORD,
  RESEND_MAIL,
  REGISTER_USER,
  ADMIN_AUTHENTICATE_USER_SUCCESS,
  CLEAR_TOKEN,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS,
  GET_REDIRECT,
  ADMIN_LOGOUT_USER_SUCCESS,
  GET_REDIRECT_SUCCESS,
  UPDATE_TOUR_FLAG,
  UPDATE_TOUR_FLAG_SUCCESS,
  ON_REFRESH,
  AUTHENTICATE_USER_VIA_TOKEN_SUCCESS,
} from "./constant";
import {
  PARENT_TABS_DATA,
  CHILDREN_TABS_DATA,
} from "../local_state_changes/constant";
import {
  ERROR_MESSAGE_FROM_SERVER,
  SUCCESS_MESSAGE_FROM_SERVER,
} from "../message/constant";
import axios from "../../config/connection";
import { UPDATE_ADMIN_USER } from "../local_state_changes/constant";
import { treeToArray } from "../../components/utitlies/commonFunction";
import { findFirstChildUrl } from "../../utitlies/HelpersFunction";
import { START_LOADER, END_LOADER } from "./constant";
import { clearCookies } from "../../utils/Utils";
import {
  getEssAndAdminUrls,
  redirectToInitialAccountLogin,
  redirectToInitialEss,
} from "../../utitlies/commonFunction";

function* fetchAndSetMenus(resultUser) {
  let role_id =
    resultUser.data.auth.role_ids.indexOf(2) > -1
      ? 2
      : resultUser.data.auth.role_ids.indexOf(4) > -1
      ? 4
      : 7;
  const resultMenu = yield call(axios, {
    headers: { token: resultUser.data.token },
    nextUrl: `admin/user/getMenus?app_id=6&role_id=${role_id}`,
  });

  if (resultMenu.error) {
    yield put({
      type: ERROR_MESSAGE_FROM_SERVER,
      payload: resultMenu.message,
    });
  } else if (resultMenu.data.menu) {
    let updatedArr = [...treeToArray(resultMenu.data.menu, "children")].map(
      (item) => ({
        ...item,
        hideFromMenu: false,
      })
    );
    let menusObj = {
      menu_access: updatedArr,
      menu_access_tree: resultMenu.data.menu,
    };
    yield put({
      type: ADMIN_AUTHENTICATE_USER_SUCCESS,
      payload: menusObj,
    });
    localStorage.setItem("adminMenu", JSON.stringify(menusObj));

    // For HRMS
    const response = yield call(axios, {
      headers: { token: resultUser.data.token },
      nextUrl: `admin/user/orgdetails`,
      method: "POST",
    });
    localStorage.setItem("orgDetails", JSON.stringify(response.data));
    localStorage.setItem("adminUserDetails", JSON.stringify(resultUser));
    localStorage.setItem("token", resultUser?.data?.token);
    yield put({
      type: AUTHENTICATE_USER_VIA_TOKEN_SUCCESS,
      payload: {
        isAdmin:
          resultUser.data.auth.role_ids.indexOf(2) > -1 ||
          resultUser.data.auth.role_ids.indexOf(4) > -1 ||
          resultUser.data.auth.role_ids.indexOf(8) > -1
            ? true
            : false,
        authencated: true,
        token: resultUser.data.token,
        orgDetails: response.data,
        user: {
          ...resultUser?.data?.auth,
          adminProject: true,
        },
      },
    });
    let firstUrls = findFirstChildUrl(menusObj?.menu_access_tree);
    window.location.href = `/${firstUrls}`;
  }
}

function* loginUser({ payload }) {
  try {
    let { data, headers, callback_url, history } = payload;
    let resultUser = yield call(axios, {
      method: "post",
      headers,
      data,
      nextUrl: "guest/user/login",
    });

    if (!resultUser || resultUser.error) {
      yield put({
        type: ERROR_MESSAGE_FROM_SERVER,
        payload: resultUser.message,
      });
    } else {
      yield put({
        type: SUCCESS_MESSAGE_FROM_SERVER,
        payload: "Login Successfully.",
      });

      // Call the isolated function for getting menus and setting them
      yield call(fetchAndSetMenus, resultUser);
    }
  } catch (err) {
    console.log({ err });
    yield put({
      type: ERROR_MESSAGE_FROM_SERVER,
      payload: "Error occured while login",
    });
  }
}

function* authenticateUser({ payload }) {
  try {
    const { headers, callback_url } = payload;

    const result = yield call(axios, {
      headers,
      nextUrl: "guest/user/authenticateToken",
    });
    if (result.error) {
      console.log({ result, error: result.error });
      const { essUrl, adminUrl } = getEssAndAdminUrls();
      clearCookies();
      yield put({
        type: ADMIN_AUTHENTICATE_USER_SUCCESS,
        payload: {
          token: "",
        },
      });
      yield put({
        type: UPDATE_ADMIN_USER,
        payload: { loadingPage: false, authencatedUser: false },
      });
      redirectToInitialEss(essUrl, adminUrl);
      // window.location.assign(window.location.origin);
    } else {
      if (callback_url) {
        window.open([callback_url, "?token=", headers.token].join(""), "_self");
      } else {
        yield put({
          type: UPDATE_ADMIN_USER,
          payload: {
            authencatedUser: true,
          },
        });
      }
    }
  } catch (error) {
    console.log({ error });
    // yield put({
    //   type: ERROR_MESSAGE_FROM_SERVER,
    //   payload: error,
    // });
  }
}

function* logoutUser({ payload }) {
  try {
    const { headers, history } = payload;
    const { essUrl, adminUrl,accountLoginUrl } = getEssAndAdminUrls();
    const result = yield call(axios, {
      headers: { ...headers },
      nextUrl: "admin/user/logout",
    });
    if (result.error) {
      yield put({ type: ERROR_MESSAGE_FROM_SERVER, payload: result.message });
    } else {
      clearCookies();
      yield put({
        type: SUCCESS_MESSAGE_FROM_SERVER,
        payload: result.message,
      });
      yield put({
        type: CLEAR_TOKEN,
      });
      yield put({
        type: ADMIN_LOGOUT_USER_SUCCESS,
      });
      yield put({
        type: UPDATE_ADMIN_USER,
        payload: { loadingPage: false, authencatedUser: false },
      });
      redirectToInitialAccountLogin(essUrl, accountLoginUrl);
      // redirectToInitialEss(essUrl, adminUrl);
      // if (typeof history === "function") {
      //   history("/");
      // }
    }
  } catch (error) {}
}

function* resendMail({ payload }) {
  try {
    const result = yield call(axios, {
      nextUrl: "apiv2/resend-email",
      method: "post",
      data: { ...payload },
    });
    if (result.error) {
      yield put({ type: ERROR_MESSAGE_FROM_SERVER, payload: result.message });
    } else {
      yield put({
        type: SUCCESS_MESSAGE_FROM_SERVER,
        payload: result.message,
      });
    }
  } catch (error) {}
}

function* forgotPassword({ payload }) {
  try {
    const result = yield call(axios, {
      nextUrl: "apiv2/forgot-password",
      method: "post",
      data: { ...payload.data },
    });
    if (result.error) {
      yield put({ type: ERROR_MESSAGE_FROM_SERVER, payload: result.message });
    } else {
      yield put({
        type: SUCCESS_MESSAGE_FROM_SERVER,
        payload: result.message,
      });
      payload.history("/");
    }
  } catch (error) {}
}

function* setPassword({ payload }) {
  try {
    const { data, history } = payload;
    const result = yield call(axios, {
      nextUrl: "apiv2/email-verification-with-password-reset",
      method: "post",
      data,
    });
    if (result.error) {
      yield put({ type: ERROR_MESSAGE_FROM_SERVER, payload: result.message });
    } else {
      yield put({
        type: SUCCESS_MESSAGE_FROM_SERVER,
        payload: result.message,
      });
      history("/");
    }
  } catch (error) {}
}
function* resetPassword({ payload }) {
  try {
    const { data, history } = payload;
    const result = yield call(axios, {
      method: "post",
      nextUrl: "apiv2/forgot-password-reset",
      data,
    });
    if (result.error) {
      yield put({ type: ERROR_MESSAGE_FROM_SERVER, payload: result.message });
    } else {
      yield put({
        type: SUCCESS_MESSAGE_FROM_SERVER,
        payload: result.message,
      });
      history("/");
    }
  } catch (error) {}
}
function* registerUser({ payload }) {
  try {
    const { data, history } = payload;
    const result = yield call(axios, {
      nextUrl: "api/user-registration",
      method: "post",
      data,
    });
    if (result.error) {
      yield put({ type: ERROR_MESSAGE_FROM_SERVER, payload: result.message });
    } else {
      yield put({
        type: SUCCESS_MESSAGE_FROM_SERVER,
        payload: result.message,
      });
      history("/hello");
    }
  } catch (error) {}
}
function* updateTourFlag({ payload }) {
  try {
    const { data, headers } = payload;
    const result = yield call(axios, {
      method: "post",
      headers,
      nextUrl: "apiv2/auth/updatetourflag",
    });
    if (result.error) {
      yield put({
        type: ERROR_MESSAGE_FROM_SERVER,
        payload: result.message,
      });
    } else {
      yield put({
        type: UPDATE_TOUR_FLAG_SUCCESS,
        payload: true,
      });
    }
  } catch (err) {
    console.error("error in updating tour flag", err);
  }
}
function* getUserDetails({ payload }) {
  const { headers } = payload;
  try {
    const result = yield call(axios, {
      headers,
      nextUrl: "admin/user/getDetails",
    });
    if (result.error) {
      yield put({ type: ERROR_MESSAGE_FROM_SERVER, payload: result.message });
    } else {
      yield put({
        type: GET_USER_DETAILS_SUCCESS,
        payload: result?.data,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* redirection({ payload }) {
  try {
    const { data, headers, callback_url, history } = payload;
    // console.log('redirection called')
    yield put({ type: START_LOADER });
    console.log({ headers });
    const resultUser = yield call(axios, {
      headers,
      nextUrl: "admin/user/userDetails",
    });
    if (resultUser.error) {
      yield put({
        type: ERROR_MESSAGE_FROM_SERVER,
        payload: resultUser.message,
      });
    } else {
      let localData = {
        data: {
          auth: resultUser?.data,
          token: headers?.token,
        },
      };

      localStorage.setItem("token", headers?.token);
      localStorage.setItem("adminUserDetails", JSON.stringify(localData));

      yield put({
        type: UPDATE_ADMIN_USER,
        payload: {
          loadingPage: true,
          authencatedUser: true,
          isAdmin: true,
        },
      });
      const response = yield call(axios, {
        headers: { token: headers?.token },
        nextUrl: `admin/user/orgdetails`,
        method: "POST",
      });
      localStorage.setItem("orgDetails", JSON.stringify(response.data));

      yield put({
        type: AUTHENTICATE_USER_VIA_TOKEN_SUCCESS,
        payload: {
          orgDetails: response.data,
        },
      });

      if (true) {
        if (resultUser.data.v2_login === 1) {
          let role_id =
            resultUser.data.role_ids.indexOf(2) > -1
              ? 2
              : resultUser.data.role_ids.indexOf(4) > -1
              ? 4
              : 7;
          console.log("role_id", role_id);
          const resultMenu = yield call(axios, {
            headers: { token: headers?.token },
            nextUrl: `admin/user/getMenus?app_id=6&role_id=${role_id}`,
          });

          if (resultMenu.error) {
            yield put({
              type: ERROR_MESSAGE_FROM_SERVER,
              payload: resultMenu.message,
            });
          } else if (resultMenu.data.menu) {
            let updatedArr = [
              ...treeToArray(resultMenu.data.menu, "children"),
            ].map((item) => ({
              ...item,
              hideFromMenu: false,
            }));
            let menusObj = {
              menu_access: updatedArr,
              menu_access_tree: resultMenu.data.menu,
            };
            localStorage.setItem("adminMenu", JSON.stringify(menusObj));
            yield put({
              type: ADMIN_AUTHENTICATE_USER_SUCCESS,
              payload: {
                menu_access: updatedArr,
                menu_access_tree: resultMenu.data.menu,
              },
            });
          }
        }

        yield put({
          type: SUCCESS_MESSAGE_FROM_SERVER,
          payload: "Login Successfully.",
        });

        yield put({
          type: GET_REDIRECT_SUCCESS,
          payload: { data: resultUser, token: headers.token },
        });

        yield put({
          type: UPDATE_ADMIN_USER,
          payload: {
            loadingPage: true,
            authencatedUser: true,
            isAdmin:
              resultUser.data.role_ids.indexOf(2) > -1 ||
              resultUser.data.role_ids.indexOf(8) > -1,
          },
        });
      }

      let isMenusExistInLocalStorage = localStorage.getItem("adminMenu");
      if (isMenusExistInLocalStorage) {
        let menuObj = JSON.parse(localStorage.getItem("adminMenu"));
        let firstUrls = findFirstChildUrl(menuObj?.menu_access_tree);
        window.location.href = `/${firstUrls}`;
      }
    }
  } catch (err) {
    console.log({ err });
  } finally {
    yield put({ type: END_LOADER });
  }
}
function* onRefresh() {
  try {
    let menuObj = {};
    let isMenusExistInLocalStorage = localStorage.getItem("adminMenu");
    if (isMenusExistInLocalStorage) {
      menuObj = JSON.parse(localStorage.getItem("adminMenu"));
    }
    let orgDetailsVal = {};
    let isOrgDetails = localStorage.getItem("orgDetails");
    if (isOrgDetails) {
      orgDetailsVal = JSON.parse(localStorage.getItem("orgDetails"));
    }
    yield put({
      type: ADMIN_AUTHENTICATE_USER_SUCCESS,
      payload: {
        menu_access: menuObj.menu_access,
        menu_access_tree: menuObj.menu_access_tree,
      },
    });

    let userDetailsObj = {};
    let isUserDetailsExistInLocalStorage =
      localStorage.getItem("adminUserDetails");
    if (isUserDetailsExistInLocalStorage) {
      userDetailsObj = JSON.parse(localStorage.getItem("adminUserDetails"));
    }
    yield put({
      type: AUTHENTICATE_USER_VIA_TOKEN_SUCCESS,
      payload: {
        isAdmin:
          userDetailsObj.data.auth.role_ids.indexOf(2) > -1 ||
          userDetailsObj.data.auth.role_ids.indexOf(8) > -1
            ? true
            : false,
        authencated: true,
        token: userDetailsObj.data.token,
        user: {
          ...userDetailsObj.data.auth,
          adminProject: true,
          orgDetails: orgDetailsVal,
        },
        menu_access: menuObj.menu_access,
        menu_access_tree: menuObj.menu_access_tree,
        orgDetails: orgDetailsVal,
      },
    });
    yield put({
      type: LOGIN_ADMIN_USER_SUCCESS,
      payload: userDetailsObj.data,
    });
    yield put({
      type: UPDATE_ADMIN_USER,
      payload: {
        loadingPage: true,
        authencatedUser: true,
        isAdmin:
          userDetailsObj.data.auth.role_ids.indexOf(2) > -1 ||
          userDetailsObj.data.auth.role_ids.indexOf(8) > -1,
      },
    });

    let parentData = localStorage.getItem("parentTabsData")
      ? JSON.parse(localStorage.getItem("parentTabsData"))
      : [];
    let childData = localStorage.getItem("childrenTabsData")
      ? JSON.parse(localStorage.getItem("childrenTabsData"))
      : [];
    yield put({
      type: PARENT_TABS_DATA,
      payload: parentData,
    });
    yield put({
      type: CHILDREN_TABS_DATA,
      payload: childData,
    });
  } catch (err) {}
}
export default function* root() {
  yield all([
    takeLatest(GET_USER_DETAILS, getUserDetails),
    takeLatest(LOGIN_ADMIN_USER, loginUser),
    takeLatest(ADMIN_AUTHENTICATE_USER, authenticateUser),
    takeLatest(ADMIN_LOGOUT_USER, logoutUser),
    takeLatest(REGISTER_USER, registerUser),
    takeLatest(RESET_PASSWORD, resetPassword),
    takeLatest(SET_PASSWORD, setPassword),
    takeLatest(FORGOT_PASSWORD, forgotPassword),
    takeLatest(RESEND_MAIL, resendMail),
    takeLatest(GET_REDIRECT, redirection),
    takeLatest(UPDATE_TOUR_FLAG, updateTourFlag),
    takeLatest(ON_REFRESH, onRefresh),
  ]);
}
