import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Task, ViewMode, Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { useFetch } from "../../../hooks/useFetch";
import {
  getAxiosForuseFetch,
  postAxiosForuseFetch,
} from "../../../Api/axiosPrivate";
import { useSelector } from "react-redux";
import apiPath from "../../../config/apiPath.json";
import moment from "moment";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { Typography } from "@mui/material";
import useLoadingIndicator from "../../../hooks/useLoadingIndicator";

export function initTasks() {
  const currentDate = new Date();
  const tasks = [
    {
      start: new Date(currentDate.getFullYear() - 1, 1, 1),
      end: new Date(currentDate.getFullYear() - 1, 1, 2, 12, 28),
      name: "Idea",
      id: "Task 0",
      progress: 45,
      type: "task",
      displayOrder: 122,
    },
    {
      start: new Date(currentDate.getFullYear() - 1, 2, 2),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
      name: "Research",
      id: "Task 1",
      progress: 25,
      dependencies: ["Task 0"],
      type: "task",
      displayOrder: 3,
    },
    {
      start: new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 4),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
      name: "Discussion with team",
      id: "Task 2",
      progress: 10,
      dependencies: ["Task 1"],
      type: "task",
      displayOrder: 4,
    },
    {
      start: new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 8),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
      name: "Developing",
      id: "Task 3",
      progress: 2,
      dependencies: ["Task 2"],
      type: "task",
      displayOrder: 5,
    },
    {
      start: new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 8),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
      name: "Review",
      id: "Task 4",
      type: "task",
      progress: 70,
      dependencies: ["Task 2"],
      displayOrder: 6,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      end: new Date(),
      name: "Release",
      id: "Task 6",
      progress: currentDate.getMonth(),
      type: "milestone",
      dependencies: ["Task 4"],
      displayOrder: 7,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
      name: "Party Time",
      id: "Task 9",
      progress: 0,
      isDisabled: true,
      type: "task",
      displayOrder: -4,
    },
  ].map((el) => ({
    ...el,
    isDisabled: true,
    progress: 0,
    dependencies: null,
    project: null,
    type: "task",
  }));
  return tasks;
}

export function getStartEndDateForProject(tasks, projectId) {
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}

function CareerProgression({ employee_id, employeeData }) {
  // console.log({ employee_id, employeeData });
  const { user, token } = useSelector((state) => state.adminUserReducer);
  const [view, setView] = React.useState(ViewMode.Year);
  const [tasks, setTasks] = React.useState(initTasks());
  const [isChecked, setIsChecked] = React.useState(true);
  let columnWidth = 65;

  // console.log({tasks})

  if (view === ViewMode.Year) {
    columnWidth = 150;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const { data, isFetching, refetch } = useFetch(
    getAxiosForuseFetch,
    {
      token,
      url: apiPath.CAREER_PROGRESS,
      params: {
        employee_id: employeeData?.EMPLOYEE_ID || employee_id,
      },
    },
    apiPath.CAREER_PROGRESS,
    {}
  );

  const { data : objProgress, isFetching:isFetchingOKR,  } = useFetch(
    getAxiosForuseFetch,
    {
      token,
      url: apiPath.OBJ_PROGRESS,
      params: {
        employee_id: employeeData?.EMPLOYEE_ID || employee_id,
      },
    },
    apiPath.OBJ_PROGRESS,
    {}
  );

  const effectiveTaskData = useMemo(() => {
    try {
      return Object.entries(data)
        .map(([name, otherDetails]) => ({
          start: moment(otherDetails?.START_DATE).toDate(),
          end: moment(otherDetails?.END_DATE).toDate(),
          name,
          id: otherDetails.ID,
          displayOrder: otherDetails.ID,
          progress: 0,
          isDisabled: true,
          type: "task",
        }))
        .map((el, index, array) => ({
          ...el,
          dependencies: array?.[index - 1]?.id
            ? [array?.[index - 1]?.id]
            : null,
        }));
    } catch (err) {
      console.log({ err });
      return [];
    }
  }, [data]);

  const effectiveTaskDataForObjective = useMemo(() => {
    try {
      let tempArr=  Object.entries(objProgress)
        .map(([name, otherDetails]) => ({
          start: moment(otherDetails?.START_DATE).toDate(),
          end: moment(otherDetails?.END_DATE).toDate(),
          name : otherDetails?.obj_name,
          id: otherDetails.ID,
          displayOrder: otherDetails.ID,
          progress: otherDetails.progress,
          isDisabled: true,
          type: "task",
        }))
        .map((el, index, array) => ({
          ...el,
          dependencies: array?.[index - 1]?.id
            ? [array?.[index - 1]?.id]
            : null,
        }));
        console.log({tempArr})
        return tempArr;
    } catch (err) {
      console.log({ err });
      return [];
    }
  }, [objProgress]);


  useEffect(() => {
    console.log({ effectiveTaskDataForObjective });
  }, [effectiveTaskDataForObjective]);

  useEffect(()=>{
console.log({objProgress})
  } , [objProgress])

  useLoadingIndicator(isFetching || isFetchingOKR)

  const handleTaskChange = (task) => {
    console.log("On date change Id:" + task.id);
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleClick = (task) => {
    console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task, isSelected) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };

  return (
    <div className="Wrapper">

<Typography variant="h2" className="mb-10" > Designation Progress </Typography>
      {effectiveTaskData?.length > 0 ? (
        <Gantt
          tasks={effectiveTaskData}
          viewMode={view}
          onDateChange={handleTaskChange}
          onDelete={handleTaskDelete}
          onProgressChange={handleProgressChange}
          onDoubleClick={handleDblClick}
          onClick={handleClick}
          onSelect={handleSelect}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? "155px" : ""}
          columnWidth={columnWidth}
        />
      ) : (
        <NoDataFound />
      )}

<Typography variant="h2" className="mt-10 mb-10" > OKR Progress </Typography>

{effectiveTaskDataForObjective?.length > 0 ? (
        <Gantt
          tasks={effectiveTaskDataForObjective}
          viewMode={ViewMode.Month}
          onDateChange={handleTaskChange}
          onDelete={handleTaskDelete}
          onProgressChange={handleProgressChange}
          onDoubleClick={handleDblClick}
          onClick={handleClick}
          onSelect={handleSelect}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? "155px" : ""}
          columnWidth={columnWidth}
        />
      ) : (
        <NoDataFound />
      )}


    </div>
  );
}

export default CareerProgression;
