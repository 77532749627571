import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_SERVER_URL;
const ENGAGE_SERVER_BASE_URL = process.env.REACT_APP_ENGAGE_SERVER_URL;
const ADMIN_SERVER_BASE_URL = process.env.REACT_APP_HRMS_API_URL

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

const apiResponse = async (url, method = "get", formData = {}) => {
  try {
    const token = localStorage.getItem("token");
    let data = await axiosInstance({
      method: method,
      url: url,
      headers: {
        Token: token,
      },
      data: formData,
    });
    return data;
  } catch (err) {
    if (err.response && err.response.data) {
      if (err.response.data.code === 401) {
        // clearLocalStorageAndSessionStorage();
      } else if (err.response.data.code === 500) {
        if (err.response.data.forceLogin) {
          toast.error("Something Went Wrong!");
          // clearLocalStorageAndSessionStorage(err.response.data.forceLogin);
        }
        return err.response.data;
      } else if (err.response.data.code === 422) {
        return err.response.data;
      }
      throw new Error(err.message);
    }
    return { error: true, code: err.code, message: err.message };
  }
};

export const getAxiosForuseFetch = async ({ url, token, params, reqToEngage = false, reqToAdmin = false }) => {
  return await axios.get(`${reqToAdmin ? ADMIN_SERVER_BASE_URL : reqToEngage ? ENGAGE_SERVER_BASE_URL : BASE_URL}${url}`, { headers: { token }, params });
};

export const postAxiosForuseFetch = async ({ url, token, data, reqToEngage = false, reqToAdmin = false }) => {
  return await axios.post(`${reqToAdmin ? ADMIN_SERVER_BASE_URL : reqToEngage ? ENGAGE_SERVER_BASE_URL : BASE_URL}${url}`, data, { headers: { token, } });
};

export const patchAxiosForuseFetch = async ({ url, token, data, reqToEngage = false, reqToAdmin = false }) => {
  return await axios.patch(`${reqToAdmin ? ADMIN_SERVER_BASE_URL : reqToEngage ? ENGAGE_SERVER_BASE_URL : BASE_URL}${url}`, data, { headers: { token } });
}

export const deleteAxiosForuseFetch = async ({ url, token, data, reqToEngage = false, reqToAdmin = false }) => {
  return await axios.delete(`${reqToAdmin ? ADMIN_SERVER_BASE_URL : reqToEngage ? ENGAGE_SERVER_BASE_URL : BASE_URL}${url}`, { data: data, headers: { token } });
}

export default apiResponse;