//This functional component returns null but exists as a helper for Account.js so we can call apis and stuff
// that might be needed in global state

import React from "react";
import { useFetch } from "./hooks/useFetch";
import { getPmsOrgSettings } from "./modules/Configuration/apiPromises";
import isEmpty from "lodash/isEmpty";

function AccountHelperComponent({ token, user }) {
  //   console.log({ token, user });

  const { data: allSettingData, isFetching: isLoadingSettingData } = useFetch(
    getPmsOrgSettings,
    {
      token,
      org_id: user.org_id,
    },
    "org_setting",
    {
      cacheTime: 500 * 60 * 1000,
      staleTime: 500 * 60 * 1000,
    }
  );

  React.useEffect(() => {
    if (!isEmpty(allSettingData)) {
      localStorage.setItem(
        "config_labels",
        JSON.stringify({
          KRA_LABEL: allSettingData?.KRA_LABEL ?? "KRA",
          KPI_LABEL: allSettingData?.KPI_LABEL ?? "KPI",
          REVIEW: allSettingData?.REVIEW_LABEL ?? "Review",
          OBJECTIVE: allSettingData?.OBJECTIVE_LABEL ?? "Objective",
          COMPETENCY: allSettingData?.COMPETENCY_LABEL ?? "Competency",
          POTENTIALS: allSettingData?.POTENTIALS_LABEL ?? "Potential",
          KEY_RESULT_LABEL: allSettingData?.KEY_RESULT_LABEL ?? "Key Results",
        })
      );
    }
  }, [allSettingData]);

  return null;
}

export default AccountHelperComponent;
