// import { Beacon } from "@beacon.li/bar";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import "antd/dist/antd.css";
// import axios from "axios";
// import React, { PureComponent, Suspense } from "react";
// import { connect } from "react-redux";
// import {
//   BrowserRouter,
//   Navigate,
//   Route,
//   Routes
// } from "react-router-dom";
// import "react-toastify/dist/ReactToastify.css";
// import AccountHelperComponent from "./AccountHelperComponent";
// import { PlaceHolderComponet } from "./PlaceHolderComponent";
// import AlertComponent from "./components/AlertComponent";
// import Loader from "./components/Loader";
// import config from "./config/configFile.json";
// import ErrorPage from "./pages/ErrorPage";
// import { redirection } from "./redux/auth/action";
// import { ADMIN_LOGOUT_USER_SUCCESS } from "./redux/auth/constant";
// import { CLEAR_MESSAGE_FROM_SERVER } from "./redux/message/constant";
// import {
//   authenticateUser,
//   getOrgMenu,
//   isLoadingPage,
//   isUserAuthenicated,
//   logoutUser,
//   onRefresh,
// } from "./redux/rootAction";
// import { AuthRoute, PublicRoute , routesMapping } from "./routes";
// import "./style/appStyles.scss";
// import "./style/pms.style.css";

// const AppRoute = React.lazy(() => import("./routes/AppRoutes"));



// class Account extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.app_callback_url = new URL(window.location.href).searchParams.get(
//       "app_callback_url"
//     );
//     this.urlToken = new URL(window.location.href).searchParams.get("token");
//     if (typeof window.process === "undefined") {
//       window.process = {};
//     }
//     if (
//       !props.token &&
//       !this.urlToken &&
//       localStorage.getItem("adminUserDetails")
//     ) {
//       props.dispatch(onRefresh());
//       this.getUrlOrMenus();
//     }
//     if (!props.token && !localStorage.getItem("adminUserDetails")) {
//       props.dispatch(isUserAuthenicated(false));
//       localStorage.clear();
//     }

//     if (process.env.NODE_ENV === "production") {
//       console.log = () => {};
//       console.debug = () => {};
//     }
//   }

//   componentDidMount() {
//     const { dispatch, token } = this.props;
//     const url = new URL(window.location.href);

//     this.urlToken = url.searchParams.has("token")
//       ? url.searchParams.get("token")
//       : "";

//     if (this?.urlToken?.length > 0) {
//       this.checkAuth(this.urlToken, dispatch);
//       return;
//     }

//     if (token) {
//       dispatch(
//         authenticateUser({
//           headers: { token: token },
//           callback_url: this.app_callback_url,
//         })
//       );
//     }

//     if (this.props.type || this.props.message) {
//       this.updateMessage(1200);
//     }

//     Beacon.load("TVYBWY");
//   }

//   updateMessage = (timeout = 2000) => {
//     const { message, type, dispatch } = this.props;
//     if (message || type) {
//       if (message === "Logged out successfully.") {
//         const clear_timeout = setTimeout(() => {
//           clearTimeout(clear_timeout);
//           dispatch({ type: CLEAR_MESSAGE_FROM_SERVER });

//           dispatch({ type: ADMIN_LOGOUT_USER_SUCCESS });
//           localStorage.clear();
//         }, 2000);
//       } else if (
//         type === "error" &&
//         (message === "Your session has expired, kindly login again." ||
//           message === "Your session has expired, kindly login again.")
//       ) {
//         localStorage.clear();
//         window.location.href = '/'
//         // window.open([`${process.env.REACT_APP_SERVER_URL}`], "_self");
//       } else {
//         const clear_timeout = setTimeout(() => {
//           clearTimeout(clear_timeout);
//           dispatch({ type: CLEAR_MESSAGE_FROM_SERVER });
//           if (message === "Logged out successfully.") {
//             dispatch({ type: ADMIN_LOGOUT_USER_SUCCESS });
//             localStorage.clear();
//           }
//         }, timeout);
//       }
//     }
//   };

//   getUrlOrMenus = () => {
//     const { user = {}, token = "", dispatch, loadingPage = false } = this.props;

//     if (user?.role_ids?.indexOf(2) > -1 || user?.role_ids?.indexOf(8) > -1) {
//       if (user?.v2_login === 1) {
//         dispatch(getOrgMenu({ headers: { token: this.urlToken || token } }));
//       }
//     }
//     loadingPage && dispatch(isLoadingPage(false));
//   };

//   getSnapshotBeforeUpdate(prevProps) {
//     if (
//       (this.props.type && prevProps.type !== this.props.type) ||
//       (this.props.message && this.props.message !== prevProps.message)
//     ) {
//       return "run-message-function";
//     }
//     if (
//       this.props.authencatedUser &&
//       this.props.authencatedUser !== prevProps.authencatedUser
//     ) {
//       return "authencated-user";
//     }
//     return null;
//   }

//   componentDidUpdate(prevProps, prevState, snapshot) {
    
//     const { menu_access: currentMenuAccess , user } = this.props;
//     const { menu_access: previousMenuAccess } = prevProps;

//     if (previousMenuAccess?.length === 0 && Array.isArray(currentMenuAccess) && currentMenuAccess?.length > 0) {
//       Beacon.loadUser(user?.user_emp_id, {
//         menu_slugs_allowed: currentMenuAccess?.map(el => el?.menu_slug)
//       })
//     }

//     if ("authencated-user" === snapshot) {
//       this.getUrlOrMenus();
//     }
//     if (snapshot === "run-message-function") {
//       this.updateMessage();
//     }
//   }

//   checkAuth(token, dispatch) {
//     localStorage.clear();
//     console.log({ token }, "in check auth");
//     // dispatch({type:START_LOADER})
//     axios
//       .get(`${process.env.REACT_APP_SERVER_URL}guest/user/authenticateToken`, {
//         headers: { token: token },
//       })
//       .then(function (response) {
//         dispatch(
//           redirection({
//             headers: { token: token },
//           })
//         );

//       })
//       .catch(function (error) {
//         localStorage.clear();
//         dispatch(logoutUser({ headers: { token } }));
//         window.location.href = "/";
//         return false;
//       })
//       // .finally(()=>dispatch({type:END_LOADER}));
//   }

//   render() {
//     const {
//       loadingPage = true,
//       message = "",
//       type = "",
//       menu_access = [],
//       token = "",
//       authencatedUser = false,
//       user = {},
//       menu_access_tree = [],
//       loader_parent = false,
//       dispatch = () => {},
//     } = this.props;

//     if (this?.urlToken?.length > 0) {
//       // this.checkAuth(this.urlToken, dispatch);
//     } else if (
//       token &&
//       authencatedUser &&
//       user.role_ids.indexOf(2) === -1 &&
//       user.role_ids.indexOf(8) === -1 &&
//       user.role_ids.indexOf(7) === -1 &&
//       user.role_ids.indexOf(3) === -1 &&
//       !user.IS_ACTIVE_ATS &&
//       user.PMS
//     ) {
//       window.location.href = [config["performance-link"], token].join("");
//       return;
//     }

//     return loadingPage ? (
//       <div className="wh-vw-vh flex-center">
//         <CircularProgress />
//       </div>
//     ) : (
//       <>
//         {message ? <AlertComponent type={type} message={message} /> : null}
//         {loader_parent ? <Loader visible={loader_parent} /> : null}

//         {token && user ? (
//           <AccountHelperComponent token={token} user={user} />
//         ) : null}

//         <Suspense
//           fallback={
//             <div
//               className="wh-vw-vh flex-center"
//               style={{
//                 zIndex: 9999999999,
//               }}
//             >
//               <CircularProgress />
//             </div>
//           }
//         >
//           <BrowserRouter basename="/">
//             <Routes>
//               {authencatedUser
//                 ? AuthRoute.map((routeValue, index) =>
                    
//                 routeValue.hasOwnProperty("common") ||
                    
//                 menu_access.findIndex(
//                       (child) => child.name === routeValue.name
//                     ) > -1 
//                 // true    
//                     ? 
                    
//                     (
//                       <Route
//                         path={routeValue.path}
//                         key={`auth_` + index}
//                         element={
//                           <AppRoute
//                             propsObj={{
//                               token,
//                               leftNavigation: routeValue.hasOwnProperty(
//                                 "leftNavigation"
//                               )
//                                 ? routeValue.leftNavigation
//                                 : true,
//                               ...routeValue                              
//                             }}
//                             component={routeValue.component}
//                             exact={routeValue.exact}
//                             isAuthProtected={true}
//                           />
//                         }
//                       />
//                     ) : (
//                       <Route
//                         path={routeValue.path || ""}
//                         element={<ErrorPage />}
//                         key={"error" + index}
//                       />
//                     )
//                   )
//                 : null}

//               {PublicRoute.map((routeValue, index) => (
//                 <Route
//                   path={routeValue.path}
//                   key={`guest_` + index}
//                   element={
//                     <AppRoute
//                     propsObj={{
//                         token,
//                         withHeader: false,
//                         rpaths:
//                           menu_access_tree[0]?.children?.[0]?.url ||
//                           menu_access_tree[0]?.url,
//                       }}
//                       component={routeValue.component}
//                       exact={routeValue.exact}
//                       isAuthProtected={false}
//                     />
//                   }
//                 />
//               ))}

//               {token && !loadingPage ? (
//                 <Route
//                   path="/"
//                   element={
//                     <Navigate
//                       replace
//                       to={`/${
//                         menu_access_tree[0]?.children?.[0]?.url ||
//                         menu_access_tree[0]?.url ||
//                         ""
//                       }`}
//                     />
//                   }
//                 />
//               ) : !token && !localStorage.getItem("adminUserDetails") ? (
//                 <Route path="*" element={<PlaceHolderComponet />} />
//               ) : null}
//             </Routes>
//           </BrowserRouter>
//         </Suspense>
//       </>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   token: state.adminUserReducer.token,
//   user: state.adminUserReducer.user,
//   loader_parent: state.adminUserReducer.loader_parent,
//   menu_access: state.adminUserReducer.menu_access,
//   menu_access_tree: state.adminUserReducer.menu_access_tree,
//   message: state.adminMessageReducer.message,
//   type: state.adminMessageReducer.type,
//   loadingPage: state.adminLocalStateReducer.loadingPage,
//   authencatedUser: state.adminLocalStateReducer.authencatedUser,
// });

// export default connect(mapStateToProps)(Account);

export default <></>;