import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import adminMessageReducer from "./message/reducer";
import sessionReducer from "./session/reducer";
import organisationUnitsReducer from "./organisationUnits/reducer";
import adminLocalStateReducer from "./local_state_changes/reducer";
import languageReducer from "./language/reducer";

const rootReducer = combineReducers({
  adminUserReducer: authReducer,
  adminMessageReducer: adminMessageReducer,
  sessionReducer: sessionReducer,
  organisationUnitsReducer: organisationUnitsReducer,
  adminLocalStateReducer: adminLocalStateReducer,
  languageReducer: languageReducer
});
export default rootReducer;
