import React from "react";
import PropTypes from "prop-types";

const CustomChip = ({
  text,
  icon,
  iconPosition,
  onClick,
  style,
  className,
  iconStyles , 
}) => {
  const chipStyle = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.25rem 0.5rem",
    borderRadius: "2px",
    backgroundColor: "#e0e0e0",
    cursor: onClick ? "pointer" : "default",
    ...style,
  };

  const iconStyle = {
    marginRight: iconPosition === "right" ? "0.5rem" : 0,
    marginLeft: iconPosition === "left" ? "0.5rem" : 0,
  };

  return text ? (
    <div style={chipStyle} onClick={onClick} className={className}>
      {icon && iconPosition === "left" && <span style={ iconStyles ? iconStyles : iconStyle}>{icon}</span>}
      {text}
      {icon && iconPosition === "right" && (
        <span style={iconStyle}>{icon}</span>
      )}
    </div>
  ) : null;
};

CustomChip.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
};

CustomChip.defaultProps = {
  iconPosition: "left",
};

export default CustomChip;
