import React, { useMemo } from "react";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
// import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import styles from "./styles.module.scss";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import { useEffect } from "react";
import { Divider } from "@material-ui/core";
import { FixedSizeList as List } from "react-window";
import TextReducingTooltipComponent from "../components/TextReducingTooltipComponent";
import { useIntl } from 'react-intl';


const MultiSelectPopperFilter = ({
  itemData = {
    name: "",
    icon: null,
  },
  openFromProps = false,
  options = [],
  placeHolderForSearch = "Search",
  onCheckBoxChange = (option) => { },
  runAfterSearchChange = (value) => { },
  checkedItems = [],
  setCheckedItems = () => { },
  onClearSelection = () => { },
  disableLocalSearch = true,
  defaultSearchValue = "",
  tooltip = { show: false, message: "" },
  searchStateClearingToggler = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState(defaultSearchValue);
  const intl = useIntl();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    if (disableLocalSearch) runAfterSearchChange(event.target?.value);
    setSearchText(event.target?.value);
  };

  const searchableOptions = useMemo(
    () =>
      Array.isArray(options)
        ? options.filter((el) =>
          String(el?.name)
            .toLowerCase()
            .includes(String(searchText).toLowerCase().trim())
        )
        : [],
    [searchText, options]
  );

  const handleToggleItem = (itemId) => {
    const isChecked =
      checkedItems.includes(itemId) ||
      checkedItems.includes(String(itemId).trim());

    let updatedCheckedArray = null;
    if (isChecked) {
      updatedCheckedArray = checkedItems.filter(
        (id) => id !== itemId && id !== String(itemId).trim()
      );
    } else {
      updatedCheckedArray = [...checkedItems, itemId];
    }

    let newStateOfTheItem = !isChecked;
    if (Array.isArray(updatedCheckedArray)) {
      setCheckedItems(updatedCheckedArray, itemId, newStateOfTheItem);
    }
  };

  const handleClearSelection = () => {
    setCheckedItems([]);
    onClearSelection();
  };

  useEffect(() => {
    if (searchStateClearingToggler && searchText) {
      // easier alternative than using ref and useImperative handle. It doesn't work with array.map
      setSearchText("");
    }
  }, [searchStateClearingToggler]);

  const open = Boolean(anchorEl) || openFromProps;
  const id = open ? "popover" : undefined;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div style={{ position: "relative", zIndex: 100 }}>
        <div
          className={`${styles.filter__box}  ${checkedItems.length > 0 ? styles.checked : ""
            }`}
          onClick={handleClick}
        >
          <div
            className={`${styles.filters__default__text} ${checkedItems.length > 0 ? styles.checked__text : ""
              } flex-start-center`}
            style={{ paddingRight: "10px" }}
          >
            {
              <div style={{ marginRight: "15px" }}>{`${checkedItems.length > 0
                ? itemData.name + " : +" + checkedItems.length
                : intl.formatMessage({ id: itemData?.intlKey || 'filters.default.multiSelect', defaultMessage: itemData?.name })
                }`}
              </div>
            }

            {itemData.sideIcon ? itemData.sideIcon : <MdKeyboardArrowDown />}
          </div>
        </div>

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          disablePortal
          placement="bottom-start"
          sx={{
            zIndex: 10,
          }}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 10], // Set the gap between the button and popover
              },
            },
          ]}
        >
          <Paper className={styles?.paper__container}>
            <div className={`flex-start-center ${styles?.search__component}`}>
              <BsSearch style={{ width: 14, height: 14 }} />

              <input
                type="text"
                placeholder={placeHolderForSearch}
                name="searchText"
                autoComplete="off"
                value={searchText}
                onChange={handleSearchChange}
                style={{ margin: "0 !important", width: "100% !important" }}
                className="input-search w-100"
              />
            </div>

            {/* Replace your existing List component with FixedSizeList */}
            <List
              height={200} // Set the desired height of the virtualized list
              itemCount={searchableOptions.length} // Pass the total number of items
              itemSize={40} // Set the height of each item (adjust as needed)
              width="100%" // Set the width of the list
            >
              {({ index, style }) => {
                const option = searchableOptions[index];
                const isChecked =
                  checkedItems.includes(option?.id) ||
                  checkedItems.includes(String(option?.id).trim());

                return (
                  <div
                    style={style}
                    key={option.id}
                    onClick={() => {
                      handleToggleItem(option.id);
                      onCheckBoxChange(option);
                    }}
                  >
                    <ListItem
                      sx={{
                        pl: "0px !important",
                        alignItems: "center",
                        ...(option.sx ?? {}),
                      }}
                      dense
                      button
                    >
                      <Checkbox
                        checked={isChecked}
                        tabIndex={-1}
                        disableRipple
                        sx={{
                          mr: "8px",
                          "&.MuiSvgIcon-root": {
                            width: "1.25rem",
                            height: "1.25rem",
                          },
                          "&.Mui-checked": {
                            color: "#335AFF",
                          },
                          "&.Mui-checked:hover": {
                            color: "grey",
                          },
                        }}
                      />

                      {option.icon && <>{option.icon}</>}

                      <ListItemText
                        primary={
                          <span
                            className={styles?.filters__default__text}
                            style={option.textStyleOverrides}
                          >
                            <TextReducingTooltipComponent data={option.name} limit={25} />
                          </span>
                        }
                        disableTypography
                      />
                    </ListItem>
                  </div>
                );
              }}
            </List>

            {checkedItems?.length > 0 && (
              <>
                <Divider />

                <b
                  className="cursor-pointer mt-2"
                  onClick={handleClearSelection}
                >
                  clear selection
                </b>
              </>
            )}
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default MultiSelectPopperFilter;
