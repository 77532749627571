import { Beacon } from "@beacon.li/bar";
import SearchIcon from "@material-ui/icons/Search";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import React, { useMemo } from "react";
import "./search.css";
import { useSelector, useDispatch } from "react-redux";

function SearchBar({ placeholder, data }) {
  const openBeacon = () => {
    console.log("open beacon called");
    Beacon.open();
  };

  const { user, token } = useSelector((state) => state.adminUserReducer);

  const isAdmin = useMemo(
    () =>
      user?.role_ids && Array.isArray(user.role_ids) &&
      (user.role_ids.includes(2) ||
        user.role_ids.includes(4) ||
        user.role_ids.includes(8)),
    [user]
  );
  return (
    <>
      {user && isAdmin ? (
        <div className="admin-searchInputs" onClick={openBeacon}>
          <div className="admin-NavIcon">
            <SearchIcon />
          </div>

          <div className="clickable-text" onClick={openBeacon}>
            {placeholder}
          </div>

          <div className="admin-searchIcon">
            <AutoAwesomeIcon />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default React.memo(SearchBar);
