import { Box, Grid, Typography } from "@material-ui/core";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import isEqual from "lodash/isEqual";
import React from "react";
import { useSelector } from "react-redux";
// import { get_formated_date } from "../../utitlies/commonFunction";
import {get_formated_date} from '../../components/utitlies/commonFunction.js'
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import TextReducingTooltipComponent from "../TextReducingTooltipComponent";

// const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default function TimeLineComponent({ data }) {
  const { token, user } = useSelector(
    (state) => state.adminUserReducer,
    isEqual
  );
  return (
    <>
      <Grid className="timeline_grid">
        {data && data.length ? (
          <Timeline style={{ padding: "0px", margin: "0px" }}>
            {data &&
              data?.map((item, idx) => {
                return (
                  <TimelineItem key={idx}>
                    <TimelineOppositeContent style={{ flex: "none" }}>
                      <Box className="timeline_date_box">
                        <div>
                          {item?.UPDATED_ON
                            ? get_formated_date(
                                item?.UPDATED_ON ?? "",
                                user.ng_date_str
                              )
                            : ""}
                        </div>
                      </Box>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        style={{
                          backgroundColor: "#4C70FF",
                          width: "1px",
                        }}
                      />
                      <TimelineConnector
                        style={{ backgroundColor: "#E8E8EA" }}
                      />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Box className="timeline_message">
                        <span>{`${item?.SETTING_LABEL}`}</span>

                        {item?.SETTING_VALUE_NEW === "Enabled" ||
                        item?.SETTING_VALUE_NEW === "Disabled" ? (
                          <span
                            className={`boolean-value-${
                              item?.SETTING_VALUE_NEW === "Enabled"
                                ? "blue"
                                : "red"
                            }`}
                          >
                            &nbsp;
                            {item?.SETTING_VALUE_NEW}
                          </span>
                        ) : (
                          <>
                            &nbsp; changed from
                            <span className="old-value">
                              &nbsp;
                              <TextReducingTooltipComponent
                                data={`${item?.SETTING_VALUE_OLD}`}
                                limit={50}
                              />
                            </span>
                            <span>&nbsp;to</span>
                            <span className="new-value">
                              &nbsp;
                              <TextReducingTooltipComponent
                                data={`${item?.SETTING_VALUE_NEW}`}
                                limit={50}
                              />
                            </span>
                          </>
                        )}

                        <Typography className="timeline_updatedby">
                          Updated by &nbsp;
                          <span className="timeline_emp_name">
                            {item?.["km_employee_details.EMPLOYEE_NAME"] ?? ""}
                          </span>
                          &nbsp; at &nbsp;
                          {item?.UPDATED_ON &&
                            item?.UPDATED_ON.split("T")?.[1].substring(0, 5)}
                        </Typography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
          </Timeline>
        ) : (
          <React.Fragment>
            <Box
              className="card-content-container"
              style={{ paddingTop: "60px" }}
            >
              <Box
                className="wh-100 flex-column flex-center"
                style={{ overflow: "hidden !important" }}
              >
                <img src="/img/svg/noData.svg" alt="Transaction" />
                <Typography>No records found</Typography>
              </Box>
            </Box>
          </React.Fragment>
        )}
      </Grid>
    </>
  );
}

export const TimeLineComponentWithCardRender = ({
  data,
  colors,
  renderTimelineContent,
  config = {},
}) => {
  const { user = {}, token } = useSelector(
    (state) => state.adminUserReducer
    // isEqual
  );
  // console.log({ data });

  const {
    timelineDotColor = "#4C70FF",
    timelineConnectorColor = "#E8E8EA",
    booleanValueColors = { enabled: "blue", disabled: "red" },
  } = colors || {};

  return (
    <Grid
      className="timeline_grid"
      sx={{ height: "100%", background: "initial" }}
    >
      {data && data.length ? (
        <Timeline style={{ padding: "0px", margin: "0px" }}>
          {data.map((item, idx) => (
            <TimelineItem key={idx}>
              <TimelineOppositeContent style={{ flex: "none" }}>
                <Box className="timeline_date_box">
                  <div>
                    {console.log({ date: user?.ng_date_str })}
                    {item[config.DATE_KEY]
                      ? get_formated_date(
                          item[config.DATE_KEY],
                          user?.ng_date_str
                        ) ||
                        (String(item[config.DATE_KEY]).split(" ")?.[0] ?? "")
                      : ""}
                  </div>
                  {/* Code  */}
                </Box>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  style={{
                    backgroundColor: timelineDotColor,
                    width: "1px",
                  }}
                />
                <TimelineConnector
                  style={{
                    backgroundColor: timelineConnectorColor,
                  }}
                />
              </TimelineSeparator>
              <TimelineContent>{renderTimelineContent(item)}</TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      ) : (
        <NoDataComponent />
      )}
    </Grid>
  );
};
