import { getConfigLabels } from "../utitlies/HelpersFunction";

let { KRA_LABEL, KPI_LABEL } = getConfigLabels();
export const ENGLISH = {
  en: {
    language: "English",

    header: {
      beacon: {
        searchPlaceholder: "Click here for smart search...",
      },

      popOver: {
        profile: "My Profile",
        logout: "Logout",
      },
    },

    tabs: {
      pending: "Pending",
      completed: "Completed",
      expired: "Expired",
      rejected: "Rejected",
      received: "Received",
      given: "Given",
      approved: "Approved",
    },

    inputs: {
      search: "Search...",

      placeholder: {
        searchByEmployee: "Search by Employee",
      },

      labels: {
        appraisalPercentage: "Appraisal Percentage",
        applicableDate: "Applicable Date",
        incentiveAmount: "Incentive Amount",
        normalizedRating: "Normalized Rating",
        year: "Year",
        cycle: "Cycle",
        periodicity: "Periodicity",
      },
    },

    select: {
      labels: {
        selectEvaluationYear: "Select Evaluation Year",
        selectEvaluationCycle: "Select Evaluation Cycle",
        selectCycleQuarter: "Select Cycle Quarter",
        chooseObjectiveCycle: "Choose Objective Cycle",
        choosePeriod: "Choose Period",
      },
    },

    placeholder: {
      approvalStatus: "Approval Status",
    },

    options: {
      all: "All",
      approved: "Approved",
      pendingApproval: "Pending Approval",
      pendingApprovalOnRM: "Pending Approval on RM",
      rejected: "Rejected",
      thisMonth: "This Month",
      lastMonth: "Last Month",
      thisQuarter: "This Quarter",
      lastQuarter: "Last Quarter",
      thisYear: "This Year",
      lastYear: "Last Year",
      upcoming: "Upcoming",
      completed: "Completed",
      overdue: "Overdue",
    },

    filers: {
      default: {
        multiSelect: "Select Filters...",
        singleSelect: "Select Filter...",
      },
      cycle: "Cycle",
      department: "Department",
      designation: "Designation",
      location: "Location",
      entity: "Entity",
      more: "More",
      status: "Status",
      evalCycle: "Evaluation Cycle",
      employmentType: "Employment Type",
      businessUnit: "Business unit",
      costCenter: "Cost Center",
      shift: "Shift",
      structure: "Structure",
      periodicity: "Periodicity",
      cycleYear: "Cycle Year",
      joiningYear: "Joining Year",
      joiningMonth: "Joining Month",
      cycleUntaggedEmployees: "Cycle Untagged Employees",
      cycleTaggedEmployees: "Cycle Tagged Employees",
      employeeStatus: "Employee Status",
      mappingStatus: "Mapping Status",
      reset: "Reset",
      approvalStatus: "Approval Status",
      evalYear: "Evaluation Year",
      objectiveCycle: "Objective Cycle",
      month: "Month",
    },

    buttons: {
      updateReviewers: "Update Reviewers",
      viewDetails: "View Details",
      initiate: "Initiate",
      createStructure: "Create Structure",
      appreciate: "Appreciate",
      scheduleOneToOne: "Schedule One to One",
      giveFeedback: "Give Feedback",
      bulkUploadGrade: "Bulk Upload Grade",
      createNewObjective: "Create Objective",
      cancel: "Cancel",
      update: "Update",
      submit: "Submit",
    },

    modals: {
      titles: {
        appraisalPercentage: "Appraisal Percentage",
        incentiveAmount: "Incentive Amount",
        initiateIncentive: "Initiate Incentive",
        normalizedRating: "Normalized Rating",
      },
    },

    popConfirm: {
      areYouSure: "Are you sure?",
      approveDesignation: "Do you want to approve new designation",
      rejectDesignation: "Do you want to reject new designation",
      action: {
        cancel: "Cancel",
        approve: "Approve",
        reject: "Reject",
      },
    },

    table: {
      staticHeaders: {
        employeeCode: "Employee Code",
        employeeName: "Employee Name",
        cycle: "Cycle",
        startDate: "Start Date",
        endDate: "End Date",
        cycleYear: "Cycle Year",
        cycleQuarter: "Cycle Quarter",
        structureName: "Structure Name",
        action: "Action",
        employees: "Employees",
        employee: "Employee",
        finalRating: "Final Rating",
        ratingYCoordinate: "Rating Y Co-ordinate",
        designation: "Designation",
        evalCycle: "Evaluation Cycle",
        department: "Department",
        location: "Location",
        employeeEmail: "Employee Email",
        employeeCount: "Employee Count",
        evaluationYear: "Evaluation Year",
        createdBy: "Created By",
        cycleName: "Cycle Name",
        structureOwner: "Structure Owner",
        status: "Status",
        keyResult: "Key Result",
        startTarget: "Start Target",
        endTarget: "End Target",
        checkinValue: "Check-in Value",
        checkinRemarks: "Check-in Remarks",
        objective: "Objective",
        periodicity: "Periodicity",
        appraisalYear: "Appraisal Year",
        reportingTo: "Reporting To",
        preNormalizedRating: "Pre-Normalized Rating",
        grade: "Grade",
        appraisalPercentage: "Appraisal Percentage",
        previousCtc: "Previous CTC",
        increasedCtc: "Increased CTC",
        incentiveAmount: "Incentive Amount",
        applicableDate: "Applicable Date",
        previousDesignation: "Previous Designation",
        newDesignation: "New Designation",
        objectiveWeightage: "Objective Weightage",
        employeeAcceptanceStatus:"Employee Acceptance Status",
        employeeApprovalRemarks: "Employee Approval Remarks"
      },
      dynamicHeaders: {
        EMPLOYEE_CODE: "Employee Code",
        EMPLOYEE_NAME: "Employee Name",
        CYCLE_NAME: "Cycle Name",
        STRUCTURE_NAME: "Structure Name",
        H1_TOTAL_WTG: "H1 Total Weightage",
        H1_ACHEIVED_WTG: "H1 Achieved Weightage",
        H1_ACHEIVED_WTG_PERCENTAGE: "H1 Achieved Weightage Percentage",
        H1_PERFORMANCE_RATING: "H1 Performance Rating",
        H1_COMPETENCY_RATING: "H1 Competency Rating",
        H1_POTENTIAL_RATING: "H1 Potential Rating",
        H1_WEIGHTED_PERFORMANCE_RATING: "H1 Weighted Performance Rating",
        H1_WEIGHTED_COMPETENCY_RATING: "H1 Weighted Competency Rating",
        H1_WEIGHTED_POTENTIAL_RATING: "H1 Weighted Potential Rating",
        H1_FINAL_RATING: "H1 Final Rating",
      },

      actions: {
        compute: "Compute",
        view: "View",
        initiate: "Initiate",
      },
    },

    empty: {
      noRecordsFound: "No records found",
      filters: {
        multiSelect: {
          cycle: "Please Select Cycles for Records",
        },

        single: {
          cycle: "Please select a relevant cycle",
        },
      },
    },

    navbar: {
      "Admin Dashboard": "Admin Dashboard",
      Dashboard: "Dashboard",
      Review: "Review",
      "Quick Actions": "Quick Actions",
      Analytics: "Analytics",
      Pipeline: "Pipeline",
      "Rating Comparison": "Rating Comparison",
      "Bell Curve": "Bell Curve",
      Objective: "Objective",
      Feedback: "Feedback",
      "My Team": "My Team",
      Self: "Self",
      Team: "Team",
      "KRA-KPI Approval": `${KRA_LABEL}-${KPI_LABEL} Approval`,
      "KRA/KPI Approval": `${KRA_LABEL}/${KPI_LABEL} Approval`,
      "Target Setting": "Target Setting",
      "Peer Assessment": "Peer Assessment",
      Approval: "Approval",
      Organization: "Organization",
      "KRA-KPI Creation": `${KRA_LABEL}-${KPI_LABEL} Creation`,
      "KRA-KPI Mapping": `${KRA_LABEL}-${KPI_LABEL} Mapping`,
      Result: "Result",
      Request: "Request",
      "Check-In": "Check-In",
      "Key-Result": "Key-Result",
      Appreciation: "Appreciation",
      "One to One": "One to One",
      "Continuous Feedback": "Continuous Feedback",
      Compensation: "Compensation",
      All: "All",
      Normalization: "Normalization",
      Appraisal: "Appraisal",
      Incentive: "Incentive",
      Promotion: "Promotion",
      Reports: "Reports",
      OKR: "OKR",
      Settings: "Settings",
      Global: "Global",
      Modules: "Modules",
      "Time Format": "Time Format",
      People: "People",
      Untagged: "Untagged",
      Terminology: "Terminology",
      Year: "Year",
      Cycle: "Cycle",
      "KRA-KPI Configuration": `${KRA_LABEL}-${KPI_LABEL} Configuration`,
      "Rating Scale": "Rating Scale",
      Parameters: "Parameters",
      "Mandatory Parameters": "Mandatory Parameters",
      "Employee Visibility": "Employee Visibility",
      "9-Box": "9-Box",
      "Set-up": "Set-up",
      Workflow: "Workflow",
      Templates: "Templates",
      Theme: "Theme",
      "Normalization Rating": "Normalization Rating",
      Library: "Library",
      KRAS: `${KRA_LABEL}'s`,
      KPIS: `${KPI_LABEL}'s`,
      Potential: "Potential",
      Competency: "Competency",
      "Key Result": "Key Result",
      "Appreciation Badges": "Appreciation Badges",
      Skills: "Skills",
      Imports: "Imports",
      "Bulk Goals Setting": "Bulk Goals Setting",
      "Bulk Goals Achievement": "Bulk Goals Achievement",
      "Quick Performance Evaluation": "Quick Performance Evaluation",
      "Bulk Objective": "Bulk Objective",
      "Bulk Objective Key Results": "Bulk Objective Key Results",
      "Bulk Structure": "Bulk Structure",
      Email: "Email",
      "Email Configuration": "Email Configuration",
      "Email Library": "Email Library",
      Notification: "Notification",
      "Self Assessment": "Self Assessment",
    },

    bulkUpload: {
      titleOne: "1. Download the template",
      subTitleOne:
        "Use this template with pre-defined columns, and fill in your data.",
      downloadTemplate: "Download Template",
      titleTwo: "2. Import Excel Sheet",
      subTitleTwo: "Once you’ve filled out the Excel Sheet, import it.",
      dragAndDrop: "Drag and Drop your file here or",
      chooseFile: "Choose file",
    },

    login: {
      title: "Login",
      img1Text: "Best Employee Experience Platform",
      img1SubText:
        "Don’t limit yourself. Many people limit themselves to what they think they can do. You can go as far as your mind lets you. You achieve what you believe.",
      img2Text: "Finally a place where it all comes together",
      img2SubText:
        "Employees like you can even make Mondays a joy. Thanks for your hard work and super attitude.",
      img3Text: "Focus on the work that matters",
      img3SubText:
        "Business opportunities are like buses, there’s always another one coming.",
      notRegistered: "Not yet registered?",
      signUp: "Sign Up",
      forgotPassword: "Forgot Password?",
      loginBtnText: "Login",
      loginWithOkta: "Login With Okta",
      loginWithSso: "Login With SSO",
      emailLabel: "Email or Mobile number",
      passwordLabel: "Password",
    },

    // dashBoard.review.quickActions.kraKpiApproval

    dashBoard: {
      review: {
        quickActions: {
          pendingPeopleInformation: "Pending People Information",
          needAttention: "Need Attention (Expired)",
          kraKpiApproval: `${KRA_LABEL}/${KPI_LABEL} Approval`,
          targetSetting: "Target Setting",
          selfAssesments: "Self Assessments",
          peerAssesments: "Peer Assessments",
          reviews: "Reviews",
          approvals: "Approvals",
        },

        analytics: {
          reviewDistributionByStage: "Reviews Distribution By Stage",
          organizationAchievement: "Organization Achievement",
          bestPerformers: "Best Performers",
          worstPerformers: "Worst Performers",
          departmentWiseRatingCount: "Department Wise Rating Count",
          designationWiseRatingCount: "Designation Wise Rating Count",
          nineBoxMatrix: "9-BOX Matrix",
          performanceAssessment: "Performance Assessment",
          potentialAssessment: "Potential Assessment",
        },

        pipeLines: {
          kraKpiMapping: "%KRA%/%KPIS% Mapping",
          kraKpiApproval: "%KRA%/%KPIS% Approval",
          targetSetting: "Target Setting",
          selfAssessment: "Self Assessment",
          peerAssessment: "Peer Assessment",
          review1: "Review (Level 1)",
          review2: "Review (Level 2)",
          approve1: "Approve (Level 1)",
          approve2: "Approve (Level 2)",
          completed: "Completed",
        },

        ratingComparision: {
          reviewRatingComparison: "Review Rating Comparison",
        },

        bellCurve: {
          bellCurveBasedOnFinalRatings: "Bell Curve - Based on Final Ratings",
          bellCurveEmployeesRatingWise: "Bell Curve - Employees(Rating Wise)",
          tabs: {
            ideal: "Ideal",
            actual: "Actual",
          },
        },
      },
    },

    objective: {
      organizationOKR: "Organization OKR",
      teamOKR: "Team OKR",
      myOKR: "My OKR",
    },

    compensation: {
      initiateEmployeeCompensation: "Initiate Employee Compensation",
      popOver: {
        moveToNormalization: "Move To Normalization",
        moveToAppraisal: "Move To Appraisal",
        moveToIncentive: "Move To Incentive",
        moveToIncentiveAndAppraisal: "Move To Incentive & Appraisal",
        updateGrade: "Update Grade",
      },
      bulkUpload: {
        normalizationGradeLabel: "Bulk Upload Normalization Grade",
      },
    },

    reports: {
      review: {
        appraiserMatrix: {
          name: "Employee Appraiser Matrix",
          desc: "Provides an overview of the performance reviewers or appraisers associated with each employee at different stages of the review cycle, presenting a comprehensive snapshot of the assigned evaluators throughout the review process.",
        },

        stageStatus: {
          name: "Employee Stage Status Report",
          desc: "Provides a comprehensive summary of the review status at each stage of the review cycle, offering insights into the progress of employee evaluations throughout the various stages of the review process.",
        },

        combinedPerformenceAverage: {
          name: "Combined Performance Average Report",
          desc: "Provides an average of the performance ratings received at various stages of the review cycle, offering an overall view of their performance against different performance parameters throughout the evaluation period.",
        },

        competencyAverage: {
          name: "%COMPETENCY% Average Report",
          desc: "Provides an average of the competency ratings received at various stages of the review cycle, offering an overall view of their performance across different competencies throughout the evaluation period.",
        },

        kraAverage: {
          name: "%KRA_LABEL%/%KPI_LABEL% Average Report",
          desc: `The report summarizes an employee's ${KRA_LABEL}/${KPI_LABEL}  ratings across the review cycle, offering an overview of their performance against set objectives throughout the evaluation period.`,
        },

        singleRowKraAverage: {
          name: "Single Row %KRA_LABEL%/%KPI_LABEL% Average Report",
          desc: `The report summarizes an employee's ${KRA_LABEL}/${KPI_LABEL} ratings across the review cycle in single row, offering an overview of their performance against set objectives throughout the evaluation period.`,
        },

        consolidateReport: {
          name: `Consolidate ${KRA_LABEL}/${KPI_LABEL} Report`,
          desc: `The report summarizes an employee's ${KRA_LABEL}/${KPI_LABEL} ratings, designation and CTC across the review cycle in consolidate, offering an overview of their performance against set objectives throughout the evaluation period.`,
        },

        targetAchievement: {
          name: "Target Achievement Report",
          desc: "The 'Target Achievement Report' details the extent to which set targets have been met in an evaluation period, outlining the progress and success in reaching desired goals.",
        },

        potentialAverage: {
          name: "%POTENTIALS% Average Report",
          desc: "Provides an average of the potential ratings received at various stages of the review cycle, offering an overall view of their performance across different potentials throughout the evaluation period.",
        },

        cycleRatings: {
          name: "Cycle Ratings Report",
          desc: "Offers a comprehensive summary of the reviews conducted for an employee throughout an evaluation cycle, providing an overall view of their performance and feedback received during this period.",
        },

        targetVsAchievement: {
          name: "Target Vs Achievement Overview",
          desc: "This report provide a detailed view of the achievements for the set target against each performance parameter.",
        },

        targetBasedPerformence: {
          name: "Target Based Performance Reports",
          desc: "This report provide a detailed view of the achievements for the set target against each performance parameter.",
        },

        nineBox: {
          name: "9Box Detailed Report",
          desc: "This report provide a detailed view of the 9Box Matrix for the set target against each performance parameter.",
        },

        employeeReview: {
          name: "Final Ratings (Quaterly)",
          desc: "This report provide a every reviwers rating scores.",
        },
        competencyRating: {
          name: "Competency Rating Report",
          desc: "This report provides a detailed overview of Competency Rating.",
        },
        employeeStructureKraKpi: {
          name: "Employee Structure %KRA_LABEL% %KPI_LABEL% Report",
          desc: "This report provides a detailed overview of Employee Structure %KRA_LABEL% %KPI_LABEL%.",
        },
        employeeAppraisal: {
          name: "Employee Appraisal Report",
          desc: "This report provides a detailed overview of Employee Appraisal",
        },
        employeePromotion: {
          name: "Employee Promotion Report",
          desc: "This report provides a detailed overview of Employee Promotion",
        },
        employeeUntag: {
          name: "Employee Untag Report",
          desc: "This report provides a detailed overview of Untagged Employees",
        },
        finalAverageRating: {
          name: "Final Average Rating",
          desc: "This report provides a detailed overview of Final Average Rating",
        },
        finalRatingAchievements: {
          name: "Final Rating Achievements",
          desc: "This report provides a detailed overview of Final Rating Achievemnts",
        },
        potentialRatings: {
          name: "Potential Rating Reports",
          desc: "This report provides a detailed overview of Potential Rating Reports",
        },
        employeeFeedback: {
          name: "Review Feedback Reports",
          desc: "This report provides a detailed overview of employee feedback",
        },
      },

      okr: {
        objectiveCompletion: {
          name: `Employee-wise %OBJECTIVE% Completion Report`,
          desc: "Provides an overview of the Employee-wise %OBJECTIVE% Completion",
        },
        objectiveKeyResult: {
          name: "%OBJECTIVE% %KEY_RESULT_LABEL%",
          desc: "Provides an overview of the %OBJECTIVE% %KEY_RESULT_LABEL%",
        },
        checkInApproval: {
          name: "Chekins Approval Status",
          desc: "Provides an overview of the checkins and their approval statuses",
        },
        objectiveLog: {
          name: "Objective Logs Reports",
          desc: "Provides an overview of the logs (checkins,create,edit,delete) objective/key results",
        },
      },

      continuousFeedback: {
        oneToOne: {
          name: "Continous Feedback Report",
          desc: "Provides an overview of the Employee-wise Continous Feedback",
        },

        feedback: {
          name: "One to One Meeting Report",
          desc: "Provides an overview of the Employee One to One",
        },

        appreciation: {
          name: "Employee Appreciation Report",
          desc: "Provides an overview of the Employee Appreciation Report",
        },
        pip: {
          name: "Employee PIP Report",
          desc: "Provides an overview of the Employee's PIP",
        },
      },
    },
  },
};
