import {
    CustomSelect
} from "@zimyo/components";
import { reactSelectStyles } from './constants';
import styles from "./styles.module.scss";
  
const SelectWithLabel = ({ stickyLabel, width, height, ...props }) => {
    return (
      <div style={{ position: "relative" }}>
        <div className={styles?.select_label}>{stickyLabel}</div>
        <CustomSelect
          {...props}
          styles={reactSelectStyles(width, height)}
          menuPortalTarget={document.body}
          menuPlacement = 'auto'
        />
      </div>
    );
  };
  
  export default SelectWithLabel