import React, { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { getAxiosForuseFetch } from "../../../Api/axiosPrivate";
import { useSelector } from "react-redux";
import { TimeLineComponentWithCardRender } from "../../../components/Timeline/TimeLineComponent";
import { Typography, Box } from "@mui/material";
import TextReducingTooltipComponent from "../../../components/TextReducingTooltipComponent";
import { convertTime24to12 } from "../../../utitlies/HelpersFunction";
import { CustomSelect } from "@zimyo/components";
import { convertArrayToObjectWithKey } from "../../../utitlies/commonFunction";
import useLoadingIndicator from "../../../hooks/useLoadingIndicator";

const config = { DATE_KEY: "CREATED_ON" };

const modulesArr = [
  { label: "All", value: 0 },
  { label: "Review", value: 1 },
  { label: "Objective", value: 2 },
  { label: "Feedback", value: 3 },
];

const modulesArrMapping = convertArrayToObjectWithKey(modulesArr, "value");

const renderTimelineContent = (item) => {
  // console.log({ item });
  return (
    <Box
      className="timeline_message"
      sx={{
        mb: 1,
        pt: 0.5,
        height: "100% !important",
        overflow: "auto !important",
        minHeight: "50px",
      }}
    >
      <Box sx={{ mb: 0.5 }} className="flex-start-center g-10">
        <b>{item?.MODULES_NAME}</b> : <div>{item?.MESSAGE}</div>
      </Box>

      <Typography className="timeline_updatedby">
        Updated by{" "}
        <span className="timeline_emp_name">
          {" "}
          <b>{item?.CREATED_BY || ""}</b>
        </span>
        {" "}at{" "}
        {convertTime24to12(
          item?.CREATED_ON && String(item?.CREATED_ON).split(" ")?.[1]
        )}
      </Typography>
    </Box>
  );
};

function EmployeeTimeline({ employeeData , employee_id }) {
  const { token, user } = useSelector((state) => state.adminUserReducer);
  const [moduleId, setModuleId] = useState(0);
  const { data, isFetching } = useFetch(getAxiosForuseFetch, {
    token,
    url: "/admin/employee-timeline",
    params: { employee_id: employeeData?.EMPLOYEE_ID || employee_id , module_id: moduleId },
  });

  useLoadingIndicator(isFetching);

  const handleChange = (obj) => {
    setModuleId(obj?.value);
  };

  return (
    <div className="w-100">
      <div style={{ width: "250px" }}>
        <CustomSelect
          options={modulesArr}
          value={modulesArrMapping[moduleId]}
          onChange={handleChange}
          label="Module"
        />
      </div>

      <TimeLineComponentWithCardRender
        data={data}
        config={config}
        renderTimelineContent={renderTimelineContent}
      />
    </div>
  );
}

export default EmployeeTimeline;
