export const ACTIVE_DRAWER_LISTTEM = "ACTIVE_DRAWER_LISTITEM";
export const COLLASE_ORGANISATION_DRAWER_LIST =
  "COLLAPE_ORGANISATION_DRAWER_LIST";

export const ACCESS_GROUP_OPEN_CREATE_MODAL = "ACCESS_GROUP_OPEN_CREATE_MODAL";

export const OPEN_TRANSFER_EMPLOYEE_MODAL = "OPEN_TRANSFER_EMPLOYEE_MODAL";
export const OPEN_SUSPEND_EMPLOYEE_MODAL = "OPEN_SUSPEND_EMPLOYEE_MODAL";
export const OPEN_PROBATION_UPDATE_EMPLOYEE_MODAL =
  "OPEN_PROBATION_UPDATE_EMPLOYEE_MODAL";
export const SET_EMPLOYEE_DATA = "SET_EMPLOYEE_DATA";

export const OPEN_BULK_MODIFICATION = "OPEN_BULK_MODIFICATION";
export const OPEN_BULK_MODIFICATION_CONFIG = "OPEN_BULK_MODIFICATION_CONFIG";
export const OPEN_BULK_UPDATION = "OPEN_BULK_UPDATION";
export const OPEN_BULK_UPDATION_CONFIG = "OPEN_BULK_UPDATION_CONFIG";
export const OPEN_BULK_CREATION = "OPEN_BULK_CREATION";
export const OPEN_BULK_CREATION_CONFIG = "OPEN_BULK_CREATION_CONFIG";
export const OPEN_EXCLUDE_EMPLOYEE_MODAL = "OPEN_EXCLUDE_EMPLOYEE_MODAL";

export const IS_USER_AUTHENICATED = "IS_USER_AUTHENICATED";
export const IS_LOADING_PAGE = "IS_LOADING_PAGE";
export const UPDATE_ADMIN_USER = "UPDATE_ADMIN_USER";

export const EDIT_SUSPEND_EMPLOYEE_DETAILS = "EDIT_SUSPEND_EMPLOYEE_DETAILS";

export const ORG_MASTER_ORG_UNIT_PAGE = "ORG_MASTER_ORG_UNIT_PAGE";
export const ORG_MASTER_FORM_LIBRARY_PAGE = "ORG_MASTER_FORM_LIBRARY_PAGE";
export const ORG_MASTER_EMAIL_LIBRARY_PAGE = "ORG_MASTER_EMAL_LIBRARY_PAGE";

export const ORGANISATION_FORM_PAGE = "ORGANISATION_FORM_PAGE";

export const ORGANISATION_ENTITES_PAGE = "ORGANISATION_ENTITES_PAGE";
export const ORGANISATION_BUSINESS_UNIT_PAGE =
  "ORGANISATION_BUSINESS_UNIT_PAGE";
export const ORGANISATION_REGIONS_PAGE = "ORGANISATION_REGIONS_UNIT_PAGE";
export const ORGANISATION_DEPARTMENT_PAGE = "ORGANISATION_DEPARTMENT_PAGE";
export const ORGANISATION_DESIGNATIONS_PAGE = "ORGANISATION_DESIGNATIONS_PAGE";
export const ORGANISATION_EMPLOYEE_TYPE_PAGE =
  "ORGANISATION_EMPLOYEE_TYPE_PAGE";
export const ORGANISATION_LOCATION_PAGE = "ORGANISATION_LOCATION_PAGE";
export const ORGANISATION_GRADE_PAGE = "ORGANISATION_GRADE_PAGE";
export const ORGANISATION_ZONE_PAGE = "ORGANISATION_ZONE_PAGE";
export const ORGANISATION_CHANNEL_PAGE = "ORGANISATION_CHANNEL_PAGE";
export const ORGANISATION_SUB_DEPARTMENT_PAGE =
  "ORGANISATION_SUB_DEPARTMENT_PAGE";
export const ORGANISATION_CONST_CENTER_PAGE = "ORGANISATION_CONST_CENTER_PAGE";
export const COLLAPSE_LEFTBAR = "COLLAPSE_LEFTBAR";
export const OPEN_LMS_CRED_MODAL = "OPEN_LMS_CRED_MODAL";
export const OPEN_AADHAR_VERIFY_MODAL = "OPEN_AADHAR_VERIFY_MODAL";
export const PARENT_TABS_DATA = "PARENT_TABS_DATA";
export const CHILDREN_TABS_DATA = "CHILDREN_TABS_DATA";
export const VIEW_RELAXED_GEO_EMPLOYEE = "VIEW_RELAXED_GEO_EMPLOYEE";
export const VIEW_GEO_EMPLOYEE = "VIEW_GEO_EMPLOYEE";
export const OPEN_RELAXED_GEO_MODAL = "OPEN_RELAXED_GEO_MODAL";
