import React, { lazy } from "react";
import { convertArrayToObjectWithKey } from "../components/utitlies/commonFunction.js";
import { BadgeMaster as EngageBadgeMaster, FeedbackConfigration, SkillsMaster } from "@zimyo/engage"

const Modules = lazy(() =>
  import("../modules/objectives/configuration/Components/Modules.jsx")
);
const LoginPage = lazy(() => import("../pages/AuthLayout/LoginPage"));
const LoginSSOPage = lazy(() => import("../pages/AuthLayout/LoginSSoPage"));
const RegisterPage = lazy(() => import("../pages/AuthLayout/RegisterPage"));
const ForgotPasswordPage = lazy(() =>
  import("../pages/AuthLayout/ForgotPassword")
);
const VerifyOtpPage = lazy(() => import("../pages/AuthLayout/VerifyOtp"));
const SetPasswordPage = lazy(() => import("../pages/AuthLayout/SetPassword"));
const ResetPasswordPage = lazy(() =>
  import("../pages/AuthLayout/ResetPassword")
);
const TargetSettingViewPage = React.lazy(() =>
  import("../modules/reviews/TargetSettingViewPage")
);
const SetReview = React.lazy(() =>
  import(
    "../modules/reviews/selfassessment.component/ViewSelfAssessmentDetails"
  )
);
const ReviewApprovalCompleted = React.lazy(() =>
  import("../modules/reviews/reviewApprovalCompleted/ReviewApprovalCompleted")
);

const ApproveEmployeeFinal = React.lazy(() =>
  import("../modules/reviews/approveEmployeeFinal/ApproveEmployeeFinal")
);

// const ObjectiveDashboard = lazy(() =>
//   import("../modules/reviews/dashboard/ObjectiveDashboard")
// );

const People = lazy(() => import("../modules/reviews/reviewpeople/PeopleList"));

const EmployeeCompensation = React.lazy(() =>
  import(
    "../modules/Compensation/EmployeeCompensation/EmployeeCompensation.jsx"
  )
);

const InitiateEmployeeCompensation = React.lazy(() =>
  import(
    "../modules/Compensation/EmployeeCompensation/Components/InitiateEmployeeCompensation.jsx"
  )
);

const EmployeeAppraisal = React.lazy(() =>
  import(
    "../modules/Compensation/EmployeeCompensation/Components/EmployeeAppraisal.jsx"
  )
);

const EmployeeIncentive = React.lazy(() =>
  import(
    "../modules/Compensation/EmployeeCompensation/Components/EmployeeIncentive.jsx"
  )
);

const ViewCompensation = React.lazy(() =>
  import(
    "../modules/Configuration/Compensation/CompensationSetup/ViewCompensation.jsx"
  )
);

const EmployeeNormalization = React.lazy(() =>
  import(
    "../modules/Compensation/EmployeeCompensation/Components/EmployeeNormalization.jsx"
  )
);

const EmployeePromotion = React.lazy(() =>
  import(
    "../modules/Compensation/EmployeePromotion/EmployeePromotion.jsx"
  )
);

// const Compute = React.lazy(() => import("../modules/reviews/compute/Compute"));

/** Reports start */
const Reports = lazy(() => import("../modules/reports/Reports"));

const EmployeeAppraiserMatrix = lazy(() =>
  import("../modules/reports/employeeAppraiserMatrixReport")
);

// const EmployeeStatusReports = lazy(() =>
//   import("../modules/reports/employeeStageStatusReport")
// );

// const FinalReport = lazy(() =>
//   import("../modules/reports/FinalReport")
// );

const CompetencyRatingReport = lazy(() =>
  import("../modules/reports/CompetencyRatingReport")
);

const ObjectiveReportsCommon = lazy(() =>
  import("../modules/reports/ObjectiveReportsCommon/index.js")
);

const ObjectiveLogsReport = lazy(() =>
  import("../modules/reports/ObjectiveReportsCommon/ObjectiveLogsReports.jsx")
);

const ReportsWithCustomFilter = lazy(() =>
  import("../modules/reports/ReportsWithCustomFilter")
);

const AverageReport = lazy(() => import("../modules/reports/averagereport"));
const TargetBasedPerformanceReport = lazy(() =>
  import("../modules/reports/targetBasedPerformanceReport")
);

const CompentacyAverageReports = lazy(() =>
  import("../modules/reports/competencyAverageReport")
);

const KraKpiAvergaReports = lazy(() =>
  import("../modules/reports/KpiKraAverageReport")
);


const SingleRowKraKpiAvergaReports = lazy(() =>
  import("../modules/reports/KpiKraAverageReport/singleRowReport.jsx")
);


const consolidateReport = lazy(() =>
  import("../modules/reports/consolidatedReport/consolidateReport.jsx")
);

const TargetAchievementReports = lazy(() =>
  import("../modules/reports/targetachievementreport")
);
const PotentialAverageReport = lazy(() =>
  import("../modules/reports/potentialAverageReport")
);

const NineBoxReport = lazy(() =>
  import("../modules/reports/NineBoxReport")
);

const CycleRatingsReport = lazy(() =>
  import("../modules/reports/cycleRatingsReport/CycleRatingsReport")
);

const GoalAcievementReports = lazy(() =>
  import("../modules/reports/GoalVsAchievement")
);
const ViewSelfAssessmentDetails = React.lazy(() =>
  import(
    "../modules/reviews/selfassessment.component/ViewSelfAssessmentDetails"
  )
);
const EmployeeDetails = React.lazy(() =>
  import("../modules/reviews/reviewpeople/EmployeeDetails")
);

const PeerEmployeeFinal = React.lazy(() =>
  import("../modules/reviews/peerEmployeeFinal/PeerEmployeeFinal")
);
const PeerAssessmentEmployee = React.lazy(() =>
  import("../modules/reviews/peerAssessmentEmployee/PeerAssessmentEmployee")
);

const AllSelfAssessmentPendingOnMe = React.lazy(() =>
  import(
    "../modules/reviews/selfassessment.component/selfassessmentTable/allSelfAssessments/AllSelfAssessment"
  )
);

const AllSelfAssessmentOrganisation = React.lazy(() =>
  import(
    "../modules/reviews/selfassessment.component/selfassessmentTable/allSelfAssessments/AllSelfAssessment"
  )
);

const ResultComponent = React.lazy(() =>
  import(
    "../modules/reviews/selfassessment.component/selfassessmentTable/allSelfAssessments/ResultComponent.jsx"
  )
);

// const ApproveEmployeeform = React.lazy(() =>
//   import("../modules/reviews/approveEmployeeForm.component/ApproveEmployeeForm")
// );
// const ReviewEmployeeForm = React.lazy(() =>
//   import("../modules/reviews/reviewEmployeeForm.component/ReviewEmployeeForm")
// );
const SelfAssessmentForm = React.lazy(() =>
  import(
    "../modules/reviews/selfassessment.component/selfAssessmentForm/SelfAssessmentForm"
  )
);

const ReviewVisiblity = React.lazy(() =>
  import("../modules/reviews/configuration/ReviewVisibility.jsx")
);

const AppraisalReviewDetailsPage = React.lazy(() =>
  import("../modules/Configuration/AppraisalReviewDetailsPage")
);

/** End Reports */
/** Condfiguration Routes Start */
const ObjectiveOrgConfig = React.lazy(() =>
  import("../modules/objectives/configuration/Components/ObjectiveOrgConfig")
);
const ObjectiveConfiguration = React.lazy(() =>
  import(
    "../modules/objectives/configuration/Components/ObjectiveConfiguration"
  )
);

const Methodoligies = React.lazy(() =>
  import("../modules/Configuration/Methodoligies")
);
const KRA = React.lazy(() =>
  import(
    "../modules/reviews/configuration/components/section.component/SectionList"
  )
);
const KPIS = React.lazy(() =>
  import(
    "../modules/reviews/configuration/components/attribute.component/Attribute"
  )
);
const Competency = React.lazy(() =>
  import(
    "../modules/reviews/configuration/components/competency.component/Competency"
  )
);
const Potential = React.lazy(() =>
  import(
    "../modules/reviews/configuration/components/potential.component/Potential"
  )
);
const Structure = React.lazy(() =>
  import(
    "../modules/reviews/configuration/components/structure.component/StructureList"
  )
);
const CreateStructure = React.lazy(() =>
  import(
    "../modules/reviews/configuration/components/structure.component/CreateStructure"
  )
);
const UpdateStructure = React.lazy(() =>
  import(
    "../modules/reviews/configuration/components/structure.component/UpdateStructure"
  )
);
const ViewStructure = React.lazy(() =>
  import(
    "../modules/reviews/configuration/components/structure.component/ViewStructure"
  )
);
const BulkStructure = React.lazy(() =>
  import(
    "../modules/reviews/configuration/components/structure.component/BulkStructure"
  )
);
// const AssessmentConfiguration = React.lazy(() =>
//   import("../modules/reviews/configuration/AssessmentConfiguration")
// );
const AssessmentConfiguration = React.lazy(() =>
  import("../modules/reviews/configuration/AssessmentConfigurationNew.jsx")
);

const AssessmentConfigurationMandatory = React.lazy(() =>
  import("../modules/reviews/configuration/AssessmentConfigurationMandatory.jsx")
);
// const Compensation = React.lazy(() =>
//   import("../modules/reviews/configuration/Compenstionsettings")
// );

const CompensationSetup = React.lazy(() =>
  import('../modules/Configuration/Compensation/CompensationSetup/CompensationSetup.jsx')
)

const NormalizationRating = React.lazy(() =>
  import('../modules/Configuration/Compensation/NormalizationRating/NormalizationRating.jsx')
)

const Nineboxsettings = React.lazy(() =>
  import("../modules/reviews/9box_settings/Nineboxsettings")
);
const ReviewNotification = React.lazy(() =>
  import("../modules/reviews/notification/Notification")
);

/** End Configuration Routes */

const Competencydetails = React.lazy(() =>
  import("../modules/reviews/CompetencyDetails")
);
const ObjectiveReviewCycle = React.lazy(() =>
  import("../modules/objectives/configuration/Components/ObjectiveReviewCycle")
);

const ObjectiveTemplates = React.lazy(() =>
  import("../modules/objectives/configuration/Components/ObjectiveTemplates")
);
const ObjectiveNewComponent = React.lazy(() =>
  import("../modules/objectives/ObjectiveNew/index.jsx")
);

const EmployeeFeedback = React.lazy(() =>
  import("../modules/continuous_feedback/employee_feedback/EmployeeFeedback")
);

const OneToOne = React.lazy(() => import("../modules/OneToOne/OneToOne"));

const PipList = React.lazy(() => import("../modules/PIP/PIP"));

const PipNotifications = React.lazy(() => import("../modules/PIP/PIPNotification"))

const ObjectiveWorkflow = React.lazy(() =>
  import("../modules/objectives/configuration/Components/ObjectiveWorkflow")
);

const ObjectiveColorCode = React.lazy(() =>
  import("../modules/objectives/configuration/Components/ObjectiveColorCode")
);

const ObjectiveNotification = React.lazy(() =>
  import("../modules/objectives/configuration/Components/ObjectiveNotification")
);

const AppraisalReviewYear = React.lazy(() =>
  import("../modules/Configuration/AppraisalReviewYear")
);

const WeightageConfiguration = React.lazy(() =>
  import("../modules/Configuration/WeightageConfiguration")
);

const AppraisalReviewCycle = React.lazy(() =>
  import("../modules/Configuration/AppraisalReviewCycle")
);

const EmailSetting = React.lazy(() =>
  import("../modules/reviews/configuration/EmailSetting")
);
const EmailLibrary = React.lazy(() =>
  import("../modules/reviews/configuration/EmailLibrary")
);
const BulkTargetSettings = React.lazy(() =>
  import("../modules/reviews/configuration/components/BulkTargetSettings")
);
const Bulktargetachievements = React.lazy(() =>
  import(
    "../modules/reviews/configuration/components/bulktargetAchievement/BulktargetAchievement"
  )
);
const BulkImportObjectiveKeyResult = React.lazy(() =>
  import(
    "../modules/reviews/configuration/components/BulkObjective/BulkImportObjectiveKeyResult"
  )
);
const BulkImportObjective = React.lazy(() =>
  import(
    "../modules/reviews/configuration/components/BulkObjective/BulkImportObjective"
  )
);
// const Quickappraisal = React.lazy(() =>
//   import("../modules/reviews/quickappraisal/Quickappraisal")
// );
const QuickPerformanceEvaluation = React.lazy(() =>
  import("../modules/reviews/quickappraisal/QuickPerformanceEvaluation")
);
const QuickAppraisalHistory = React.lazy(() =>
  import("../modules/reviews/quickappraisal/QuickAppraisalhistory")
);
const ReviewRatingScale = React.lazy(() =>
  import("../modules/Configuration/ReviewRatingScale")
);
const Feedback = React.lazy(() =>
  import("../modules/continuous_feedback/feedback/FeedbackTabs")
);
// const Library = React.lazy(() => import("../modules/Library"));

const EmployeeAppreciation = React.lazy(() =>
  import(
    "../modules/continuous_feedback/employeeAppreciation/EmployeeAppreciation"
  )
);
const FeedbackSurvey = React.lazy(() =>
  import(
    "../modules/continuous_feedback/survey/FeedbackSurvey"
  )
);
const MyTeam = React.lazy(() => import("../modules/MyTeam/MyTeam"));

const RMDashboard = React.lazy(() =>
  import("../modules/reviews/dashboard/RMdashboard")
);

// const ReviewDashboard = React.lazy(() =>
//   import("../modules/reviews/dashboard/ReviewDashboard.jsx")
// );

const FeedbackDashboard = React.lazy(() =>
  import("../modules/AdminDashboard/FeedbackDashboard/FeedbackDashboard.jsx")
);

const DashboardNeedAttentionPage = React.lazy(() =>
  import("../modules/reviews/dashboard/DashboardNeedAttentionPage.jsx")
);

const ObjectiveLibrary = React.lazy(() =>
  import("../modules/Configuration/Library/Objective/ObjectiveLibrary.jsx"))


const KeyResultLibrary = React.lazy(() =>
  import("../modules/Configuration/Library/Objective/KeyResultLibrary.jsx"))

const PublicRatingPage = React.lazy(() => import('../components/HorizontalAccordion/PublicReviewRating/PublicReviewRating.jsx'))

const KeyCheckinApprovalRequests = React.lazy(() =>
  import("../modules/objectives/KeyCheckinApprovalRequest/KeyCheckinApprovalRequests.jsx")
);

const ObjectiveApprovalRequest = React.lazy(() =>
  import("../modules/objectives/ObjectiveApprovalRequest/ObjectiveApprovalRequest.jsx")
);

const ReviewQuickActions = React.lazy(() =>
  import("../modules/reviews/dashboard/Review/QuickActions.jsx")
);
const ReviewAnalytics = React.lazy(() =>
  import("../modules/reviews/dashboard/Review/Analytics.jsx")
);
const ReviewPipeline = React.lazy(() =>
  import("../modules/reviews/dashboard/Review/Pipeline.jsx")
);
const ReviewBellCurve = React.lazy(() =>
  import("../modules/reviews/dashboard/Review/BellCurve.jsx")
);
const ReviewRatingAnalytics = React.lazy(() =>
  import("../modules/reviews/dashboard/Review/ReviewRatingAnalytics.jsx")
);

const ObjectiveQuickActions = React.lazy(() =>
  import("../modules/reviews/dashboard/Objective/QuickActions.jsx")
);

const ObjectiveAnalytics = React.lazy(() =>
  import("../modules/reviews/dashboard/Objective/Analytics.jsx")
);

const IncentiveCategoryDetails = React.lazy(() =>
  import("../modules/Incentive/IncentiveCategoryDetails.js")
);

const IncentiveSlabDetails = React.lazy(() =>
  import("../modules/Incentive/IncentiveSlabDetails.js")
);

const IncentiveCategory = React.lazy(() =>
  import("../modules/Incentive/IncentiveCategory.js")
);

const IncentiveCategorySlab = React.lazy(() =>
  import("../modules/Incentive/IncentiveSlab.js")
);

const CycleAutoTag = React.lazy(() => import("../modules/Configuration/Review/CycleAutoTag/CycleAutoTag"))

const ObjectiveTag=lazy(()=>import("../modules/objectives/configuration/Components/ObjectiveTag"))
const StructureApprovalLogs=lazy(()=>import('../modules/reviews/StructureApprovalLogs/StructureApprovalLogs'))

const PublicRoute = [
  {
    path: "/email-verification/:id",
    exact: true,
    component: SetPasswordPage,
  },
  {
    path: "/forgot-password-verification/:id",
    exact: true,
    component: ResetPasswordPage,
  },
  {
    path: "/forgotpassword",
    exact: true,
    component: ForgotPasswordPage,
  },
  {
    path: "/verifyotp/:id",
    exact: true,
    component: VerifyOtpPage,
  },
  {
    path: "/register",
    exact: true,
    component: RegisterPage,
  },
  {
    path: "/login-sso",
    exact: true,
    component: LoginSSOPage,
  },
  {
    path: "/:app_callback_url",
    exact: true,
    component: LoginPage,
  },
  {
    path: "/review-details/:id",
    exact: true,
    component: PublicRatingPage,
  },
];

const internalFixedAuthRoutes = [
  {
    isAuthProtected: true,
    exact: false,
    component: QuickAppraisalHistory,
    path: "/configuration/imports/quick_appraisal_history",
    name: "Bulk Goals Setting",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    exact: true,
    component: TargetSettingViewPage,
    path: "/review/setgoal/:id",
    name: "KPIS",
    withHeader: true,
    isAdmin: true,
    common: true,
  },
  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/employee-stage-status-report",
    name: "Employee Stage Status Report",
    withHeader: true,
    isAdmin: true,
    api_url: "admin/report/employee_stage_status_report",
    filterKey: 'filterEmployeeStageStatusReport'
  },
  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/employee-untag-report",
    name: "Employee Untag Report",
    withHeader: true,
    isAdmin: true,
    api_url: "admin/report/employee-untag-employee-report",
    filterKey: 'filterEmployeeStageStatusReport'
  },
  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/employee-review-report",
    name: "Final Rating (Quaterly)",
    api_url: "admin/final-rating/report",
    withHeader: true,
    isAdmin: true,
    filterKey: "filterFinalRatingReport"
  },
  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/final-average-rating",
    name: "Final Average Rating",
    api_url: "admin/final-rating-average/report",
    withHeader: true,
    isAdmin: true,
    filterKey: "filterFinalRatingReport"
  },
  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/final-rating-achievement",
    name: "Final Rating Achievements",
    api_url: "admin/final-achivement/report",
    withHeader: true,
    isAdmin: true,
    filterKey: "filterFinalRatingReport"
  },
  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/potential-rating-report",
    name: "Potential Rating Report",
    api_url: "admin/report/potential-rating",
    withHeader: true,
    isAdmin: true,
    filterKey: "filterFinalRatingReport"
  },
  {
    isAuthProtected: true,
    component: CompetencyRatingReport,
    path: "/reports/employee-competency-rating-report",
    name: "Competency Ratings",
    api_url: "admin/reports/individual-stage-competency",
    withHeader: true,
    isAdmin: true,
    filterKey: 'filterCompetencyRatingReport'
  },
  {
    isAuthProtected: true,
    component: AverageReport,
    path: "/reports/average-report",
    name: "Reports",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: TargetBasedPerformanceReport,
    path: "/reports/target-based-performance-report",
    name: "Reports",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: CompentacyAverageReports,
    path: "/reports/competency-average-report",
    name: "Reports",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: KraKpiAvergaReports,
    path: "/reports/kraAverageReport",
    name: "Reports",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: SingleRowKraKpiAvergaReports,
    path: "/reports/singleRowKraKpiAverageReport",
    name: "Reports",
    withHeader: true,
    isAdmin: true,
  },

  {
    isAuthProtected: true,
    component: consolidateReport,
    path: "/reports/consolidateReport",
    name: "Reports",
    withHeader: true,
    isAdmin: true,
  },

  {
    isAuthProtected: true,
    component: TargetAchievementReports,
    path: "/reports/targetReport",
    name: "Reports",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: PotentialAverageReport,
    path: "/reports/potentialAverageReport",
    name: "Reports",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: NineBoxReport,
    path: "/reports/NineBoxReport",
    name: "Reports",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/employee-strucutre-kra-kpi-report",
    name: "Employee Structure Kra Kpi Report",
    api_url: "admin/reports/krakpi-employee-details-report",
    withHeader: true,
    isAdmin: true,
    filterKey: "filterFinalRatingReport"
  },
  {
    isAuthProtected: true,
    component: CycleRatingsReport,
    path: "/reports/cycleRatingsReport",
    name: "Reports",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: GoalAcievementReports,
    path: "/reports/goal_vs_achievement",
    name: "Reports",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: EmployeeAppraiserMatrix,
    path: "/reports/employee-appraiser-matrix-report",
    name: "Reports",
    withHeader: true,
    isAdmin: true,
  },

  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/employee-appraisal-report",
    withHeader: true,
    isAdmin: true,
    name: "Employee Appraisal Report",
    api_url: "admin/cycle-appraisal-list",
    filterKey: "filterAppraisalReport"
  },

  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/employee-promotion-report",
    withHeader: true,
    isAdmin: true,
    name: "Employee Promotion Report",
    api_url: "admin/reports/employee-promotion-report",
    filterKey: "filterAppraisalReport"
  },

  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/employee-feedback-report",
    name: "Employee Feedback Report",
    api_url: "admin/reports/employee-feedback-report",
    withHeader: true,
    isAdmin: true,
    filterKey: "filterFinalRatingReport"
  },

  {
    isAuthProtected: true,
    component: ObjectiveReportsCommon,
    path: "/reports/okr/employee-wise-objective-completion-report",
    name: "Employee Wise Objective Completion Report",
    api_url: "admin/okr-reports/employee-obj-completion",
    withHeader: true,
    isAdmin: true,
  },

  {
    isAuthProtected: true,
    component: ObjectiveReportsCommon,
    path: "/reports/okr/objective-key-report",
    name: "Objective Key-Results Report",
    api_url: "admin/okr-reports/key-results-completion",
    withHeader: true,
    isAdmin: true,
  },

  {
    isAuthProtected: true,
    component: ObjectiveReportsCommon,
    path: "/reports/okr/checkin-key-result-report",
    name: "Checkin Status Report",
    api_url: "admin/okr-reports/checkin-key-result-report",
    withHeader: true,
    isAdmin: true,
  },

  {
    isAuthProtected: true,
    component: ObjectiveLogsReport,
    path: "/reports/okr/objective-logs-report",
    name: "Objective Logs Reports",
    api_url: "admin/okr-reports/objective-logs-report",
    withHeader: true,
    isLogs: true,
    isAdmin: true,
  },

  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/feedback/continous-feedback-report",
    name: "Continous Feedback Report",
    api_url: "admin/feedbackReport",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "//reports/feedback/employee-appreciation-report",
    name: "Employee Appreciation Report",
    api_url: "admin/appreciation/report",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/feedback/employee-one-to-one-report",
    name: "Employee One to One Report",
    api_url: "admin/report/meeting",
    withHeader: true,
    isAdmin: true,
    reqToEngage: true,
    filterKey: "oneToOneReportList"
  },
  {
    isAuthProtected: true,
    component: ReportsWithCustomFilter,
    path: "/reports/feedback/pip-report",
    name: "Employee's PIP Report",
    api_url: "admin/report/pip-report",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    exact: false,
    component: BulkStructure,
    path: "/configuration/measurement-parameters/performance-evalution-structures/bulk-structure",
    name: "Structure",
    withHeader: true,
    isAdmin: true,
  },

  {
    isAuthProtected: true,
    exact: false,
    component: CreateStructure,
    path: "/configuration/measurement-parameters/performance-evalution-structures/create-structure",
    name: "Structure",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: false,
    exact: false,
    component: PeerEmployeeFinal,
    path: "/review/approval-peer-assessment-employee/:id",
    name: "Review",
    withHeader: true,
    isAdmin: true,
  },

  {
    isAuthProtected: true,
    exact: true,
    component: DashboardNeedAttentionPage,
    path: "/dashboard/need-attention/:id",
    name: "Dashboard",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    exact: false,
    component: SelfAssessmentForm,
    path: "/review/:ids",
    name: "Self",
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
  },

  // to make the right header highlighted
  // {
  //   isAuthProtected: true,
  //   exact: false,
  //   component: SelfAssessmentForm,
  //   path: "/review/self/:ids",
  //   name: "Self",
  //   doNotWrapWithDefaultContainer: true,
  //   withHeader: true,
  //   isAdmin: true,
  // },
  // {
  //   isAuthProtected: true,
  //   exact: false,
  //   component: SelfAssessmentForm,
  //   path: "/review/team/:ids",
  //   name: "Self",
  //   doNotWrapWithDefaultContainer: true,
  //   withHeader: true,
  //   isAdmin: true,
  // },
  // {
  //   isAuthProtected: true,
  //   exact: false,
  //   component: SelfAssessmentForm,
  //   path: "/review/organization/:ids",
  //   name: "Self",
  //   doNotWrapWithDefaultContainer: true,
  //   withHeader: true,
  //   isAdmin: true,
  // },
  {
    isAuthProtected: true,
    exact: false,
    component: EmployeeFeedback,
    path: "/feedback/employee-feedbacks/:empId",
    name: "Review",
    withHeader: true,
    isAdmin: true,
  },

  {
    isAuthProtected: true,
    exact: false,
    component: ViewSelfAssessmentDetails,
    path: "/self-assessment-details/:id",
    name: "SetReview",
    withHeader: true,
    isAdmin: true,
  },

  {
    isAuthProtected: true,
    exact: false,
    component: PeerAssessmentEmployee,
    path: "/review/peer-assessment-employee/:id",
    name: "Review",
    withHeader: true,
    isAdmin: false,
  },
  // {
  //   isAuthProtected: true,
  //   exact: false,
  //   component: ApproveEmployeeform,
  //   path: "/review/approve-employee-form/:id",
  //   name: "Review",
  //   withHeader: true,
  //   isAdmin: true,
  // },
  // {
  //   isAuthProtected: true,
  //   exact: false,
  //   component: ReviewEmployeeForm,
  //   path: "/review/review-employee-pending/:id",
  //   name: "Review",
  //   withHeader: true,
  //   isAdmin: true,
  // },

  {
    isAuthProtected: true,
    exact: true,
    component: AppraisalReviewDetailsPage,
    path: "/configuration/review/review-cycle/:id",
    name: "Cycle",
    withHeader: true,
    isAdmin: true,
    doNotWrapWithDefaultContainer: true,
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmployeeDetails,
    path: "/review/organization/employee_details/:id",
    name: "People",
    withHeader: true,
    isAdmin: true,
  },

  {
    isAuthProtected: true,
    exact: false,
    doNotWrapWithDefaultContainer: true,
    component: ViewStructure,
    path: "/review/organization/evaluation-structure/view-structure/:id",
    name: "Structure",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    exact: false,
    doNotWrapWithDefaultContainer: true,
    component: ViewStructure,
    path: "/configuration/evaluation-structure/view-structure/:id",
    name: "Structure",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    exact: false,
    component: UpdateStructure,
    path: "/review/organization/evaluation-structure/update-structure/:id",
    name: "Structure",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    exact: false,
    component: UpdateStructure,
    path: "/configuration/evaluation-structure/update-structure/:id",
    name: "Structure",
    withHeader: true,
    isAdmin: true,
  },

  // {
  //   isAuthProtected: true,
  //   exact: true,
  //   component: Compute,
  //   path: "/compensation/compute/:id",
  //   name: "Compensation",
  //   withHeader: true,
  //   isAdmin: true,
  // },
  {
    isAuthProtected: true,
    exact: true,
    component: TargetSettingViewPage,
    path: "/review/setgoal/:id",
    name: "people",
    withHeader: true,
    isAdmin: true,
    common: true,
  },

  {
    isAuthProtected: true,
    component: TargetSettingViewPage,
    path: "/review/setgoal/:id",
    name: "KPIS",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: SetReview,
    path: "/review/self-assessment-details/:id",
    name: "KPIS",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: ReviewApprovalCompleted,
    path: "/review/approval-review-employee/:id",
    name: "KPIS",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: ApproveEmployeeFinal,
    path: "/review/approve-employee-final/:id",
    name: "KPIS",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: Competencydetails,
    path: "/review/competency-details/:id",
    name: "KPIS",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: InitiateEmployeeCompensation,
    path: "/compensation/initiate-employee-compensation/:id",
    name: "Compensation",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: EmployeeAppraisal,
    path: "/compensation/employee-appraisal/:id",
    name: "Compensation",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: EmployeeNormalization,
    path: "/compensation/normalization/:id",
    name: "Compensation",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    component: ViewCompensation,
    path: "/configuration/compensation/compensation-configuration/:id",
    name: "Compensation",
    withHeader: true,
    isAdmin: true,
  },
  {
    isAuthProtected: true,
    exact: true,
    component: IncentiveCategoryDetails,
    path: "/incentive/category-details/:id",
    name: "Incentive Category Details ",
    withHeader: true,
    isAdmin: true,
    doNotWrapWithDefaultContainer: true,
  },
  {
    isAuthProtected: true,
    exact: true,
    component: IncentiveSlabDetails,
    path: "/incentive/slab-details/:id",
    name: "Incentive Slab Details ",
    withHeader: true,
    isAdmin: true,
    doNotWrapWithDefaultContainer: true,
  },
].map((el) => ({
  ...el,
  menu_layout: el.path,
  isInternalFixedAuthRoute: true,
}));

const updatedAuthRoutes = [
  //internal routes from a page (like details page ,etc.) . available if user is authenticated. These are fixed routes.
  // ...internalFixedAuthRoutes,

  {
    isAuthProtected: true,
    exact: true,
    doNotWrapWithDefaultContainer: true,
    component: ReviewQuickActions,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_DASHBOARD",
  },
  {
    isAuthProtected: true,
    exact: true,
    doNotWrapWithDefaultContainer: true,
    component: FeedbackDashboard,
    withHeader: true,
    isAdmin: true,
    menu_layout: "FEEDBACK_DASHBOARD",
  },
  {
    isAuthProtected: true,
    exact: true,
    doNotWrapWithDefaultContainer: true,
    component: RMDashboard,
    withHeader: true,
    isAdmin: false,
    menu_layout: "EMP_DASHBOARD",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveQuickActions,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "OBJ_DASHBOARD",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: People,
    withHeader: true,
    isAdmin: true,
    doNotWrapWithDefaultContainer: true,
    menu_layout: "CONFIG_PEOPLE",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: People,
    withHeader: true,
    isAdmin: true,
    doNotWrapWithDefaultContainer: true,
    menu_layout: "CONFIG_PEOPLE_ALL",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: People,
    withHeader: true,
    isAdmin: true,
    doNotWrapWithDefaultContainer: true,
    menu_layout: "CONFIG_PEOPLE_OKR",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: People,
    withHeader: true,
    isAdmin: true,
    doNotWrapWithDefaultContainer: true,
    menu_layout: "CONFIG_PEOPLE_REVIEW",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: People,
    withHeader: true,
    isAdmin: true,
    doNotWrapWithDefaultContainer: true,
    menu_layout: "CONFIG_PEOPLE_UNTAGGED",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentPendingOnMe,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isSelf: 1,
      review_stage: 6,
    },
    menu_layout: "REVIEW_SELF",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: MyTeam,
    withHeader: true,
    isAdmin: true,
    menu_layout: "MY_TEAM",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isTeam: 1,
      review_stage: 1,
    },
    menu_layout: "REVIEW_TARGET_SETTING",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isTeam: 1,
      review_stage: 13,
    },
    menu_layout: "REVIEW_TARGET_SETTING_APPROVAL",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isTeam: 1,
      review_stage: 7,
    },
    menu_layout: "REVIEW_TEAM_PEER_ASSESSMENT",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isTeam: 1,
      review_stage: 2,
    },
    menu_layout: "REVIEW_TEAM_REVIEW",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isTeam: 1,
      review_stage: 4,
    },
    menu_layout: "REVIEW_TEAM_APPROVAL",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isTeam: 1,
      review_stage: 12,
    },
    menu_layout: "REVIEW_TEAM_KRA/KPI_APPROVAL",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_ORGANIZATION",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isOrganization: 1,
      review_stage: 1,
    },
    menu_layout: "REVIEW_ORG_TARGET_SETTING",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isOrganization: 1,
      review_stage: 1,
    },
    menu_layout: "REVIEW_ORG_TARGET_APPROVAL",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isOrganization: 1,
      review_stage: 7,
    },
    menu_layout: "REVIEW_ORG_PEER_ASSESSMENT",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isOrganization: 1,
      review_stage: 2,
    },
    menu_layout: "REVIEW_ORG_REVIEW",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isOrganization: 1,
      review_stage: 4,
    },
    menu_layout: "REVIEW_ORG_APPROVAL",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isOrganization: 1,
      review_stage: 13,
    },
    menu_layout: "REVIEW_ORG_TARGET_APPROVAL",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isOrganization: 1,
      review_stage: 12,
    },
    menu_layout: "REVIEW_ORG_KRA/KPI_APPROVAL",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AllSelfAssessmentOrganisation,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isOrganization: 1,
      review_stage: 6,
    },
    menu_layout: "REVIEW_ORG_SELF_ASSESSMENT",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: ResultComponent,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      isOrganization: 1,
      review_stage: 6,
    },
    menu_layout: "REVIEW_ORG_RESULT",
  },

  {
    isAuthProtected: true,
    exact: true,
    withHeader: true,
    isAdmin: true,
    list_for: "self",
    component: ObjectiveNewComponent,
    menu_layout: "OBJECTIVE",
  },
  {
    isAuthProtected: true,
    exact: true,
    withHeader: true,
    isAdmin: true,
    component: ObjectiveNewComponent,
    list_for: "self",
    menu_layout: "OBJ_SELF",
  },
  {
    isAuthProtected: true,
    exact: true,
    withHeader: true,
    isAdmin: true,
    component: ObjectiveNewComponent,
    list_for: "dept",
    menu_layout: "OBJ_DEPARTMENT",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveNewComponent,
    withHeader: true,
    isAdmin: true,
    list_for: "org",
    menu_layout: "OBJ_ORGANIZATION",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: KeyCheckinApprovalRequests,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CHECK_IN_REQUEST",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveApprovalRequest,
    withHeader: true,
    isAdmin: true,
    menu_layout: "OBJ_OBJECTIVE_REQUEST",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveApprovalRequest,
    withHeader: true,
    isAdmin: true,
    menu_layout: "OBJ_KEY_RESULT_REQUEST",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: Feedback,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONTINUOUS_FEEDBACK_FEEDBACK",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: Feedback,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONTINUOUS_FEEDBACK_FEEDBACK_SELF",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: Feedback,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONTINUOUS_FEEDBACK_FEEDBACK_TEAM",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: Feedback,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONTINUOUS_FEEDBACK_FEEDBACK_ORG",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: SkillsMaster,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONTINUOUS_FEEDBACK_FEEDBACK_SKILL",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmployeeAppreciation,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONTINUOUS_FEEDBACK_APPRECIATION",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmployeeAppreciation,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONTINUOUS_FEEDBACK_APPRECIATION_SELF",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmployeeAppreciation,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONTINUOUS_FEEDBACK_APPRECIATION_TEAM",
  },
  {
    isAuthProtected: true,
    exact: true,
    doNotWrapWithDefaultContainer: true,
    component: EngageBadgeMaster,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONTINUOUS_FEEDBACK_APPRECIATION_BADGES",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmployeeAppreciation,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONTINUOUS_FEEDBACK_APPRECIATION_ORG",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: FeedbackConfigration,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONTINUOUS_FEEDBACK_CONFIGURATION",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: OneToOne,
    withHeader: true,
    isAdmin: true,
    menu_layout: "ONE_TO_ONE",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: FeedbackSurvey,
    withHeader: true,
    isAdmin: true,
    menu_layout: "LIB_FEEDBACK_FORM",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: OneToOne,
    withHeader: true,
    isAdmin: true,
    menu_layout: "ONE_TO_ONE_SELF",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: OneToOne,
    withHeader: true,
    isAdmin: true,
    menu_layout: "ONE_TO_ONE_TEAM",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: OneToOne,
    withHeader: true,
    isAdmin: true,
    menu_layout: "ONE_TO_ONE_ORG",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: PipList,
    withHeader: true,
    isAdmin: true,
    menu_layout: "PIP",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: PipList,
    withHeader: true,
    isAdmin: true,
    menu_layout: "PIP_SELF",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: PipList,
    withHeader: true,
    isAdmin: true,
    menu_layout: "PIP_TEAM",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: PipList,
    withHeader: true,
    isAdmin: true,
    menu_layout: "PIP_LISTING_ORG",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveOrgConfig,
    // component: Modules,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONFIG_KEY_CONFIGURATION",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: KRA,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONFIG_REVIEW",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Methodoligies,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONFIG_OBJECTIVE",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ReviewNotification,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONFIG_EMAIL",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: BulkTargetSettings,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONFIG_IMPORTS",
  },
  // {
  //   isAuthProtected: true,
  //   exact: true,
  //   component: Library,
  //   withHeader: true,
  //   isAdmin: true,
  //   menu_layout: "CONFIG_LIBRARY",
  // },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveOrgConfig,
    withHeader: true,
    isAdmin: true,
    menu_layout: "KEY_CONFIG_GLOBAL",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Modules,
    withHeader: true,
    isAdmin: true,
    menu_layout: "MODULES",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveConfiguration,
    withHeader: true,
    isAdmin: true,
    menu_layout: "OBJECTIVE_SETUP",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: Methodoligies,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_TERMINOLOGY",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: Methodoligies,
    withHeader: true,
    isAdmin: true,
    isOKRScreen: true,
    menu_layout: "OBJECTIVE_TERMINOLOGY",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AppraisalReviewYear,
    withHeader: true,
    isAdmin: true,
    cycle_type: 1,
    menu_layout: "EVALUATION_YEAR",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AppraisalReviewYear,
    withHeader: true,
    isAdmin: true,
    cycle_type: 2,
    menu_layout: "CONFIG_OBJ_OBJECTIVE_YEAR",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AppraisalReviewCycle,
    withHeader: true,
    isAdmin: true,
    menu_layout: "EVALUATION_CYCLE",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: WeightageConfiguration,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_SET_UP",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ReviewRatingScale,
    withHeader: true,
    isAdmin: true,
    menu_layout: "RATING_SCALE",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: ReviewVisiblity,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_VISIBILITY",
  },

  {
    isAuthProtected: true,
    exact: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "LIB_COMPETENCIES",
    component: Competency,
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Potential,
    withHeader: true,
    isAdmin: true,
    menu_layout: "LIB_POTENTIAL",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Competency,
    withHeader: true,
    isAdmin: true,
    isEmployee: true,
    menu_layout: "EMP_COMPETENCIES",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Potential,
    withHeader: true,
    isAdmin: true,
    isEmployee: true,
    menu_layout: "EMP_POTENTIAL",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: KRA,
    withHeader: true,
    isAdmin: true,
    isEmployee: true,
    menu_layout: "EMP_KRAS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: KPIS,
    withHeader: true,
    isAdmin: true,
    isEmployee: true,
    menu_layout: "EMP_KPIS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Structure,
    withHeader: true,
    isAdmin: true,
    isEmployee: true,
    menu_layout: "EMP_EVAL_STRUCTURE",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Structure,
    path: "/configuration/review/evaluation-structure",
    name: "Structure",
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_EVAL_STRUCTURE",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: AssessmentConfigurationMandatory,
    withHeader: true,
    isAdmin: true,
    apiParams: {
      type: 1
    },
    menu_layout: "REVIEW_MANDATORY_PARAMETERS",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: AssessmentConfiguration,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_PERMISSIONS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: CompensationSetup,
    name: "Configuration",
    withHeader: true,
    isAdmin: true,
    menu_layout: "COMP_CONFIGURATION",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: NormalizationRating,
    name: "Configuration",
    withHeader: true,
    isAdmin: true,
    menu_layout: "COMP_CONFIGURATION_RATING",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Nineboxsettings,
    path: "/configuration/review/box-configuration",
    name: "9-Box",
    withHeader: true,
    isAdmin: true,
    menu_layout: "9_BOX",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveReviewCycle,
    withHeader: true,
    isAdmin: true,
    menu_layout: "OBJ_CYCLE",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveTemplates,
    withHeader: true,
    isAdmin: true,
    menu_layout: "OBJ_TEMPLATES",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveWorkflow,
    withHeader: true,
    isAdmin: true,
    menu_layout: "OBJ_WORKFLOW",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveColorCode,
    withHeader: true,
    isAdmin: true,
    menu_layout: "OBJ_THEME",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ReviewNotification,
    withHeader: true,
    isAdmin: true,
    menu_layout: "OBJ_REVIEW",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveNotification,
    withHeader: true,
    isAdmin: true,
    menu_layout: "NOTIF_OBJECTIVE",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmailSetting,
    withHeader: true,
    isAdmin: true,
    menu_layout: "EMAIL_CONFIGURATION",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmailLibrary,
    withHeader: true,
    isAdmin: true,
    menu_layout: "EMAIL_LIBRARY",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: BulkTargetSettings,
    withHeader: true,
    isAdmin: true,
    menu_layout: "BULK_GOALS_SETTING",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: BulkImportObjective,
    withHeader: true,
    isAdmin: true,
    menu_layout: "BULK_OBJECTIVE",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: BulkImportObjectiveKeyResult,
    withHeader: true,
    isAdmin: true,
    menu_layout: "BULK_OBJECTIVE_KEY_RESULTS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: BulkStructure,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONFIG_BULK_STRUCTURE",
  },

  {
    isAuthProtected: true,
    exact: true,
    component: Bulktargetachievements,
    withHeader: true,
    isAdmin: true,
    menu_layout: "BULK_GOALS_ACHIEVEMENT",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: QuickPerformanceEvaluation,
    withHeader: true,
    isAdmin: true,
    menu_layout: "QUICK_PERFORMANCE_EVALUATION",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: SkillsMaster,
    withHeader: true,
    isAdmin: true,
    menu_layout: "LIB_EMP_SKILLS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveLibrary,
    withHeader: true,
    isAdmin: true,
    menu_layout: "LIB_OKR",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: KeyResultLibrary,
    withHeader: true,
    isAdmin: true,
    menu_layout: "LIB_KEY_RESULT",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: KRA,
    withHeader: true,
    isAdmin: true,
    menu_layout: "LIB_KRAS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: KPIS,
    path: "/configuration/library/kpis",
    name: "KPIS",
    withHeader: true,
    isAdmin: true,
    menu_layout: "LIB_KPIS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EngageBadgeMaster,
    withHeader: true,
    isAdmin: true,
    menu_layout: "LIB_BADGE_MASTER",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmployeeCompensation,
    withHeader: true,
    isAdmin: true,
    menu_layout: "COMPENSATION",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmployeeCompensation,
    withHeader: true,
    isAdmin: true,
    menu_layout: "COMPENSATION_ALL",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmployeeAppraisal,
    withHeader: true,
    isAdmin: true,
    menu_layout: "COMPENSATION_APPRAISAL",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmployeeIncentive,
    withHeader: true,
    isAdmin: true,
    menu_layout: "COMPENSATION_INSENTIVE",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmployeeNormalization,
    withHeader: true,
    isAdmin: true,
    menu_layout: "COMPENSATION_NORMALIZATION",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: EmployeePromotion,
    path: "/compensation/promotion",
    name: "Compensation",
    withHeader: true,
    isAdmin: true,
    menu_layout: "COMP_PROMOTION",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Reports,
    path: "/reports",
    name: "Reports",
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REPORTS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Reports,
    path: "/reports",
    name: "Reports",
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REPORTS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Reports,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REPORTS_OKR",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Reports,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REPORTS_REVIEW",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: Reports,
    doNotWrapWithDefaultContainer: true,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REPORTS_FEEDBACK",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ReviewQuickActions,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_DASHBOARD_QUICK_ACTIONS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ReviewAnalytics,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_DASHBOARD_ANALYTICS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ReviewPipeline,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_DASHBOARD_PIPELINE",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ReviewRatingAnalytics,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_DASHBOARD_RATING_COMPARISON",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ReviewBellCurve,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_DASHBOARD_BELL_CURVE",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveQuickActions,
    withHeader: true,
    isAdmin: true,
    menu_layout: "OBJECTIVE_DASHBOARD_QUICK_ACTIONS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: IncentiveCategory,
    withHeader: true,
    isAdmin: true,
    menu_layout: "INCENTIVE_CATEGORY",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: IncentiveCategorySlab,
    withHeader: true,
    isAdmin: true,
    menu_layout: "INCENTIVE_SLAB"
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveAnalytics,
    withHeader: true,
    isAdmin: true,
    menu_layout: "OBJECTIVE_DASHBOARD_ANALYTICS",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: PipNotifications,
    withHeader: true,
    isAdmin: true,
    menu_layout: "PIP_NOTIFICATION",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: CycleAutoTag,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CYCLE_AUTO_TAGGING",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: ObjectiveTag,
    withHeader: true,
    isAdmin: true,
    menu_layout: "CONFIG_OBJ_OBJECTIVE_TAG",
  },
  {
    isAuthProtected: true,
    exact: true,
    component: StructureApprovalLogs,
    withHeader: true,
    isAdmin: true,
    menu_layout: "REVIEW_ORG_STRUCTURE_APPROVAL",
  },
];

const routesMapping = convertArrayToObjectWithKey(
  updatedAuthRoutes,
  "menu_layout"
);

export { routesMapping, internalFixedAuthRoutes, PublicRoute };
